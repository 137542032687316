import { Component, OnInit } from '@angular/core';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-flash-point',
  templateUrl: './flash-point.component.html',
  styleUrls: ['./flash-point.component.less']
})
export class FlashPointComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    if(this.expertClassificationMode){
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.expertService.sendPhysicalData({ type: "Flash_point", form: data.Flash_point, treeData: data })
        }
      })
    }
  }

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  showRequest(data) {
    const request = this.requestService.createFlashPoint(data);
    this.post(request);
  }
}
