import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class HasPendingTaskGuard implements CanActivate {
  private static expertFacets = ['expert_classification', 'validate_expert_classification', 'override_classification'];
  constructor(private router: Router, private taskService: TaskService, public toastr: ToastrService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.taskService.getUserTask().pipe(map((res: any) => {
      if (res.items.length === 0) {
        return true;
      }

      const firstTask = res.items[0];
      if (HasPendingTaskGuard.expertFacets.includes(firstTask.facet)) {
        return true;
      } else {
        this.toastr.success('Please complete this task before proceeding');
        this.router.navigate([`private/task/${firstTask.facet}/${firstTask.task_id}`]);
      }
    }));
  }
}
