import { Component, OnInit, Input, Output, EventEmitter, forwardRef, ViewChild, OnDestroy, OnChanges, AfterViewInit, AfterViewChecked } from '@angular/core';
import { DropdownConfig } from '../../interface';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HazardService } from 'src/app/shared/services/hazard/hazard.service';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownComponent),
      multi: true
    }
  ]
})
export class DropDownComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, AfterViewChecked {
  @ViewChild('ngSelectComponent', { static: false }) userSelect;
  @Input() dropdownConfig: DropdownConfig;
  @Input() formControlName: string;
  @Input() changeEvent: EventEmitter<boolean>;
  @Input() option: string;
  @Output() selectedOption = new EventEmitter();

  public formNameAndValue: any;
  public innerValue: any = {};
  public defaultPlaceholder = 'Select Value';
  private subscription: Subscription;

  constructor(public hazard: HazardService) { }

  ngOnInit() {
    if (this.changeEvent) {
      this.subscription = this.changeEvent.subscribe(data => {
        this.userSelect.handleClearClick();
      });
    }
  }

  ngOnChanges() {
    if (this.option === this.hazard.notRestrictedText || this.option === this.hazard.couldNotFindText) {
      this.userSelect.setDisabledState(true);
    } else if (this.option === '') {
      this.userSelect.setDisabledState(false);
      this.dropdownConfig.placeHolder = this.defaultPlaceholder;
    }
  }

  ngAfterViewChecked(): void {
    this.userSelect.blur();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Event fired when input value is changed. later propagated up to the form control using the custom value accessor interface
  onChange(e: any) {
    if (e) {
      this.innerValue = e.key;
      this.formNameAndValue = [this.formControlName, e.key];
      this.propagateChange(e.key);
      this.selectedOption.emit(this.formNameAndValue);
    }
  }

  ////////////////////////////////////
  // ControlValueAccessor interface //
  ////////////////////////////////////

  // Allows angular to update the value of the input
  writeValue(value: any) {
    this.innerValue = value;
  }

  // Placeholder method to be called when input value is updated
  // Angular overwrites this method via the registerOnChange method
  propagateChange = (_: any) => { };

  // Allows Angular to register a function to call when the input changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // Placeholder method to be called when input field is touched
  // Angular overwrites this function with the registerOnTouched method
  onTouched = () => { };

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
