import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private httpClient: HttpClient) { }

  list(page) {
    return this.httpClient.get(`${environment.apiUrl}groups?page=${page}`);
  }

  post(userId: any, groupId: any) {
    return this.httpClient.post(`${environment.apiUrl}user_groups/`, { user_id: userId, group_id: groupId});
  }

  delete(id: number) {
    return this.httpClient.delete(`${environment.apiUrl}user_groups/${id}`);
  }
}
