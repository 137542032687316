import { Component, OnInit, Input } from "@angular/core";
import { GroupService } from "src/app/shared/services/group/group.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserService } from "src/app/shared/services/user/user.service";
import { GroupedObservable } from 'rxjs';
import { ExpressionStatement } from '@angular/compiler';

@Component({
  selector: "app-list-group",
  templateUrl: "./list-group.component.html",
  styleUrls: ["./list-group.component.less"]
})
export class ListGroupComponent implements OnInit {
  @Input() themeVar: string;
  form: FormGroup;
  groups: any = [];
  total = 0;
  currentPage = 1;
  users = [];

  constructor(
    private groupService: GroupService,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.list().subscribe((res: any) => {
      this.users = res.items;
    });
    this.currentPage = !this.route.snapshot.queryParams["page"]
      ? 1
      : parseInt(this.route.snapshot.queryParams["page"]);
    this.list();

    this.form = this.formBuilder.group({
      user_form: [""]
    });
  }

  add(group) {
    let user_id = this.f.user_form.value.id;
    this.groupService.post(user_id, group.id).subscribe(
      res => {
        this.toastr.success('user added');
        this.list();
      },
      err => {
        this.toastr.error(err.message);
      }
    )
    
    this.form.controls.user_form.setValue('');
  }
  public removeUser(i) {
    this.groupService.delete(i).subscribe(
      res => {
        this.toastr.success('user deleted')
        this.list();
      },
      err => {
        this.toastr.error(err.message);
      }
    )
  
  }

  list() {
    this.groupService.list(this.currentPage).subscribe((res: any) => {
      this.groups = res.items;
      this.total = res.total;
    });
  }

  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.list();
  }

  filterUsers(groupId: number) {
    const group = this.groups.find((g: any) => {
      return groupId === g.id;
    });
    return this.users.filter((user) => {
      const exists = group.user_groups.find((ug: any) => {
        return user.id === ug.user_id;
      });
      return exists === null || exists === undefined;
    });
  }

  get f() {
    return this.form.controls;
  }
}
