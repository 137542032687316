import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskService } from '../../../shared/services/task/task.service';
import { AngularTokenService } from 'angular-token';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  @Input() themeVar: string;
  loginForm: FormGroup;
  submitted = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private taskService: TaskService,
    private tokenService: AngularTokenService, public toastr: ToastrService) { }

  ngOnInit() {
    this.taskService.list(1).subscribe(res => {
      this.toastr.success('You are already signed in');
      this.router.navigate(['/private/task/list']);
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.tokenService.signIn({
      login: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    }).subscribe(
      res => {
        localStorage.setItem('user', JSON.stringify(res.body.data));
        this.tokenService.getAuthHeadersFromResponse(res);
        this.router.navigate(['/private/task/list']);
      },
      error => {
        this.toastr.error('Email or password is incorrect');
      }
    );

  }
}