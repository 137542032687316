import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.less']
})
export class NoContentComponent implements OnInit {
  @Input() themeVar: string;
  html: any;
  constructor() {}

  ngOnInit() {
  }
}
