import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormControl,
  Validators,
  FormGroup
} from '@angular/forms';
import { DropdownConfig } from 'src/app/shared/interface';
import { DropdownLabels } from 'src/app/shared/enum';
import { HazardService } from 'src/app/shared/services/hazard/hazard.service';

@Component({
  selector: 'app-hazard-drop-down',
  templateUrl: './hazard-drop-down.component.html',
  styleUrls: ['./hazard-drop-down.component.less']
})
export class HazardDropDownComponent implements Validators {
  @Input() frmGrp: FormGroup;
  @Input() formControlNames: any;
  @Input() dropDownConfigs: {
    class: DropdownConfig;
    division: DropdownConfig;
    compatibility: DropdownConfig;
    packingGroup: DropdownConfig;
    subClass: DropdownConfig;
    subDivision: DropdownConfig;
  };
  @Input() formFor: string;

  public disableDropdown: string;
  public changeEvents = {
    class: new EventEmitter(),
    division: new EventEmitter(),
    compat: new EventEmitter(),
    subClass: new EventEmitter(),
    subHazard: new EventEmitter()
  };

  constructor(public hazard: HazardService) {}

  receiveHazardDropdownOption(option) {
    this.changeEvents.class.emit(true);
    this.changeEvents.division.emit(true);
    this.changeEvents.compat.emit(true);
    this.disableDropdown = '';
    if (this.frmGrp.controls[this.formControlNames.hazardDivision]) {
      this.frmGrp.controls[this.formControlNames.hazardDivision].setValue('');
    }
    if (this.frmGrp.controls[this.formControlNames.hazardCompatibility]) {
      this.frmGrp.controls[this.formControlNames.hazardCompatibility].setValue('');
    }
    if (this.frmGrp.controls[this.formControlNames.packingGroup]) {
      this.frmGrp.controls[this.formControlNames.packingGroup].reset('');
    }
    const subHazardValue =  this.dropDownConfigs.subClass.placeHolder;
    if (subHazardValue === this.hazard.notRestrictedText || subHazardValue === this.hazard.couldNotFindText) {
      this.frmGrp.controls[this.formControlNames.subHazardClass].reset('');
    }
    switch (option[1]) {
      case '1':
        this.addControls([
          this.formControlNames.hazardDivision,
          this.formControlNames.hazardCompatibility,
          this.formControlNames.packingGroup
        ]);
        this.addDropdownConfig(this.hazard.explosivesDivision);
        this.dropDownConfigs.compatibility = {
          label: this.formFor + ' ' + DropdownLabels.labelCompatibility,
          dropdownOption: this.hazard.compatibility,
          placeHolder: this.hazard.placeHolder
        };
        break;

      case '2':
        this.addControls([this.formControlNames.hazardDivision]);
        this.addDropdownConfig(this.hazard.gassesDivision);
        this.removeControls([this.formControlNames.hazardCompatibility, this.formControlNames.packingGroup]);
        break;

      case '4':
        this.addControls([this.formControlNames.hazardDivision, this.formControlNames.packingGroup]);
        this.removeControls([this.formControlNames.hazardCompatibility]);
        this.addDropdownConfig(this.hazard.flammableSolidsDivision);
        break;

      case '5':
        this.addControls([this.formControlNames.hazardDivision, this.formControlNames.packingGroup]);
        this.removeControls([this.formControlNames.hazardCompatibility]);
        this.addDropdownConfig(this.hazard.oxidizingDivision);
        break;

      case '6':
        this.addControls([this.formControlNames.hazardDivision]);
        this.addDropdownConfig(this.hazard.toxicInfectiousDivision);
        this.removeControls([this.formControlNames.hazardCompatibility, this.formControlNames.packingGroup]);
        break;

      case '7':
        this.removeControls([
          this.formControlNames.hazardDivision,
          this.formControlNames.hazardCompatibility,
          this.formControlNames.packingGroup
        ]);
        break;
      case this.hazard.couldNotFindText:
        this.removeControls([
          this.formControlNames.hazardDivision,
          this.formControlNames.hazardCompatibility,
          this.formControlNames.packingGroup
        ]);
        this.frmGrp.controls[this.formControlNames.subHazardClass].setValue(this.hazard.couldNotFindText);
        this.disableDropdown = this.hazard.couldNotFindText;
        this.dropDownConfigs.subClass.placeHolder = this.hazard.couldNotFindText;
        this.changeEvents.subClass.emit(true);
        this.removeControls([this.formControlNames.subHazardDivision]);
        break;

      case '3':
      case '8':
      case '9':
        this.addControls([this.formControlNames.packingGroup]);
        this.removeControls([this.formControlNames.hazardDivision, this.formControlNames.hazardCompatibility]);
        break;
      case this.hazard.notRestrictedText:
        this.removeControls([
          this.formControlNames.hazardDivision,
          this.formControlNames.hazardCompatibility,
          this.formControlNames.packingGroup
        ]);
        this.removeControls([this.formControlNames.subHazardDivision]);
        this.frmGrp.controls[this.formControlNames.subHazardClass].setValue(this.hazard.notRestrictedText);
        this.disableDropdown = this.hazard.notRestrictedText;
        this.dropDownConfigs.subClass.placeHolder = this.hazard.notRestrictedText;
        this.changeEvents.subClass.emit(true);
        break;
    }
  }

  removeControls(controls: string[]) {
    controls.forEach(control => {
      this.frmGrp.removeControl(control);
    });
  }

  addControls(controls) {
    controls.forEach(control => {
      this.frmGrp.addControl(control, new FormControl('', Validators.required));
    });
  }

  receiveDivisionOption(option) {
    if (this.frmGrp.controls[this.formControlNames.packingGroup]) {
      this.frmGrp.controls[this.formControlNames.packingGroup].reset('');
    }
    this.changeEvents.division.emit(true);
    this.changeEvents.compat.emit(true);
    if (option[1] === this.hazard.toxicInfectiousDivision[1].key) {
      this.removeControls([this.formControlNames.packingGroup]);
    } else if (option[1] === this.hazard.toxicInfectiousDivision[0].key) {
      this.addControls([this.formControlNames.packingGroup]);
    }
  }

  receiveCompatibilityOption() {
    if (this.frmGrp.controls[this.formControlNames.packingGroup]) {
      this.frmGrp.controls[this.formControlNames.packingGroup].reset('');
    }
    this.changeEvents.compat.emit(true);
  }

  receiveSubHazardOption(option) {
    this.changeEvents.subHazard.emit(true);
    if (this.frmGrp.controls[this.formControlNames.subHazardDivision]) {
      this.frmGrp.controls[this.formControlNames.subHazardDivision].reset('');
    }
    switch (option[1]) {
      case '2':
        this.addControls([this.formControlNames.subHazardDivision]);
        this.addSubhazardDropdownValues([this.hazard.gassesDivision[0]]);
        break;

      case '4':
        this.addControls([this.formControlNames.subHazardDivision]);
        this.addSubhazardDropdownValues(this.hazard.flammableSolidsDivision);
        break;

      case '5':
        this.addControls([this.formControlNames.subHazardDivision]);
        this.addSubhazardDropdownValues([this.hazard.oxidizingDivision[0]]);
        break;

      case '6':
        this.addControls([this.formControlNames.subHazardDivision]);
        this.addSubhazardDropdownValues([this.hazard.toxicInfectiousDivision[0]]);
        break;

      case '7':
      case '1':
      case '3':
      case '8':
      case this.hazard.couldNotFindText:
      case this.hazard.notRestrictedText:
          this.removeControls([this.formControlNames.subHazardDivision]);
          break;
    }
  }

  private addDropdownConfig(options) {
    this.dropDownConfigs.division = {
      label: this.formFor + ' ' + DropdownLabels.labelDivision,
      dropdownOption: options,
      placeHolder: this.hazard.placeHolder
    };
  }

  private addSubhazardDropdownValues(options) {
    this.dropDownConfigs.subDivision = {
      label: this.formFor + ' ' + DropdownLabels.labelSubDivision,
      dropdownOption: options,
      placeHolder: this.hazard.placeHolder
    };
  }
}
