import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ReportService } from '../../../../shared/services/report/report.service';
import { timezones } from '../../../../constants/timezone';
import * as moment from 'moment-timezone';
import { formattedError } from '@angular/compiler';

interface ParentItemData {
  date: string;
  result: number;
}

interface AverageTimeData {
  title: string;
  result: string[];
}

interface InnerTableDate {
  date: string;
  data: ChildrenItemData[];
}

interface ChildrenItemData {
  key: number;
  task: string;
  result: number;
  time: string;
}

interface UserData {
  id: number;
  email: string;
  full_name: string;
  uid: string;
  supervisor: boolean;
  oberver_uuid: string;
  allow_password_change: boolean;
}
@Component({
  selector: 'app-agent-report',
  templateUrl: './agent-report.component.html',
  styleUrls: ['./agent-report.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AgentReportComponent implements OnInit {
  public listOfParentData: ParentItemData[] = [];
  public listOfChildrenData: ChildrenItemData[] = [];
  public innerTableDataList: InnerTableDate[] = [];
  public dateRange = [];
  private weekStartDate: string;
  private weekEndDate: string;
  public user: UserData;
  public facetNameList: string[] = [];
  public averageTableData: any[] = [];
  public averageTimeData: AverageTimeData[] = [];
  public selectedTimezone = null;
  public timezoneArray = timezones;
  private acceptedAgentReportList = ['week_all_agent_report', 'month_all_agent_report'];

  private averageTableDictionary = {
    week_agent_report: 'Last 7 days',
    week_all_agent_report: 'All agent (Last 7 days)',
    month_agent_report: 'Last 30 days',
    month_all_agent_report: 'All agent (Last 30 days)'
  };

  constructor(private api: ReportService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.selectedTimezone = 'Asia/Manila';
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveAgentResult(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAverageResultSummary(this.selectedTimezone);
    this.retrieveAvgCompletionSummary(this.selectedTimezone);
  }

  public timezoneChange(timezone) {
    this.innerTableDataList = [];
    this.selectedTimezone = timezone;
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveAgentResult(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAverageResultSummary(this.selectedTimezone);
    this.retrieveAvgCompletionSummary(this.selectedTimezone);
  }

  public setDefaultTimeZone() {
    return this.selectedTimezone == null ? this.selectedTimezone = 'Asia/Manila' : this.selectedTimezone;
  }

  public onRangeSelect(result: Date): void {
    const range1 = (moment(result[0]).format()).split('T');
    const range2 = (moment(result[1]).format()).split('T');
    const startDateRange = moment.tz(range1[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDateRange = moment.tz(range2[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    this.retrieveAgentResult(startDateRange, endDateRange, this.selectedTimezone);
  }

  public drillDownApiCall(date: string, state: boolean) {
    const formattedDate = date.split('T');
    const startDate = moment.tz(formattedDate[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDate = moment.tz(formattedDate[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    if (state) {
      this.retrieveFacetResults(date, startDate, endDate, this.selectedTimezone);
    }
  }

  private getDateForWeek() {
    this.weekStartDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(7, 'days')
      .startOf('day')
      .toISOString();
    this.weekEndDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(1, 'days')
      .endOf('day')
      .toISOString();
  }

  private assignInitialDateRange() {
    const weekStartCalendar = this.splitTime(moment.tz(this.weekStartDate, this.setDefaultTimeZone()).format());
    const weekEndCalendar = this.splitTime(moment.tz(this.weekEndDate, this.setDefaultTimeZone()).format());
    const timeZone = moment.tz.guess();
    this.dateRange = [
      moment(this.splitTime(weekStartCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS'),
      moment(this.splitTime(weekEndCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS')
    ];
  }

  private splitTime(date: string) {
    if (date) {
      return date.split('T')[0];
    }
    return date;
  }

  public changeDateFormat(dateTime: string): string {
    const date = dateTime.split('T')[0];
    const dates = date.split('-');
    const formattedDate = [dates[1], dates[2], dates[0].substr(0, 2)].join("/");
    return formattedDate;
  }

  public retrieveAgentResult(startDate: string, endDate: string, zone: string) {
    this.api.retrieveAgentData(startDate, endDate, zone).subscribe(
      res => {
        if (res) {
          this.listOfParentData = [];
          Object.values(res).forEach(keys => {
            this.listOfParentData.push({
              date: keys['date'],
              result: keys['count']
            });
          });
        }
      },
      error => console.log(error)
    );
  }

  public retrieveFacetResults(startDate: string, convertedStartDate: string, endDate: string, timeZone: string) {
    const faceted = 'true';
    this.api.getFacetResult(convertedStartDate, endDate, faceted, timeZone).subscribe(
      res => {
        if (res) {
          const resultsArray = [];
          Object.values(res).forEach(keys => {
            resultsArray.push({
              key: 0,
              task: keys['facet'],
              result: keys['count'],
              time: this.changeToTimeFormat(parseFloat(keys['average_completion_time']))
            });
          });
          this.listOfChildrenData = resultsArray;
          let counter = false;
          counter = this.innerTableDataList.some(key => {
            if (key.date === startDate) {
              return true;
            }
          });
          if (!counter) {
            this.innerTableDataList.push({ date: startDate, data: this.listOfChildrenData });
          }
        }
      },
      error => console.log(error)
    );
  }

  public getInnerTableDate(date: string) {
    const isoDate = moment.tz(date, this.setDefaultTimeZone()).toISOString();
    const data = this.innerTableDataList.filter(key => {
      if (key.date === isoDate) {
        return true;
      }
    });
    return data.length > 0 ? data[0].data : [];
  }

  private generateFacetArray(facetData) {
    facetData.month_agent_report.forEach(element => {
      if (!this.facetNameList.includes(element['facet'])) {
        this.facetNameList.push(element['facet']);
      }
    });
  }

  private retrieveAverageResultSummary(timeZone: string) {
    this.api.retrieveAverageResultData(timeZone).subscribe(
      res => {
        if (res) {
          this.averageTableData = [];
          Object.keys(res).forEach(key => {
            if (this.averageTableDictionary.hasOwnProperty(key)) {
              let value = '';
              if (res[key].length) {
                let total = res[key][0].count;
                if (this.acceptedAgentReportList.includes(key)) {
                  total = 0;
                  res[key].forEach(keys => {
                    total += keys.count;
                  });
                }
                value = this.calculateAverageCount(total, key).toFixed(3);
              } else {
                value = '-';
              }

              const result = {
                title: this.averageTableDictionary[key],
                value
              };
              this.averageTableData.push(result);
            }
          });
        }
      },
      error => console.log(error)
    );
  }

  private calculateAverageCount(count, key) {
    if (key.startsWith('week_')) {
      return count / 7;
    } else {
      return count / 30;
    }
  }

  public retrieveAvgCompletionSummary(timeZone: string) {
    this.api.retrieveAvgCompletionData(timeZone).subscribe(res => {
      if (res) {
        this.averageTimeData = [];
        this.generateFacetArray(res);
        Object.keys(res).forEach(key => {
          if (this.averageTableDictionary.hasOwnProperty(key)) {
            const tempAverageData: AverageTimeData = {
              title: '',
              result: []
            };
            tempAverageData.title = this.averageTableDictionary[key];
            this.facetNameList.forEach(facet => {
              let facetCounter = 0;
              let totalAverageTime = 0;
              res[key].forEach(element => {
                if (element.facet === facet) {
                  facetCounter++;
                  totalAverageTime += element.average_completion_time;
                }
              });
              if (facetCounter === 0) {
                tempAverageData.result.push('-');
              } else {
                const completionTime = totalAverageTime / facetCounter;
                const timeval = this.changeToTimeFormat(completionTime);
                tempAverageData.result.push(timeval + '');
              }
            });
            this.averageTimeData.push(tempAverageData);
          }
        });
      }
    });
  }

  private changeToTimeFormat(completionTime: number) {
    if (completionTime) {
      return new Date(completionTime * 1000).toISOString().substr(11, 8);
    }
  }
}
