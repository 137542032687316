import { Component, OnInit, ɵConsole } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, Form, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';

@Component({
  selector: 'app-sds-find-info',
  templateUrl: './sds-find-info.component.html',
  styleUrls: ['./sds-find-info.component.less']
})
export class SdsFindInfoComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  showSdsInputs = false;
  showAddUpc = false;
  upcValues = [];
  validUpc = false;
  multiplicationValues = [];
  responseUpc = [];
  currentUpc: any;
  invalidInput = false;
  notValidMessage = false;
  counter = [];
  toastID = null;
  productRequired = false;
  upcRequired = false;
  revisionDateRequired = false;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.multiplicationValues = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    this.counter = Array.from({ length: 1 }, (v, k) => k + 1);
    const group = {};
    group[`pdf_type`] = new FormControl('', Validators.required);
    group[`revision_date`] = new FormControl('', Validators.required);
    this.counter.map(controlNumber => {
      group[`upc_${controlNumber}`] = new FormControl('', Validators.required);
      group[`product_name_${controlNumber}`] = new FormControl('', Validators.required);
      group[`manufacturer_${controlNumber}`] = new FormControl('');
      group[`sku_itemNumber_productNumber_${controlNumber}`] = new FormControl('');
      group[`product_info_url_${controlNumber}`] = new FormControl('');
    });

    this.form = new FormGroup(group);
    this.hideAll();
  }

  changePdfType() {
    this.hideAll();
    if (this.f.pdf_type.value !== 'pdf') {
      this.showAddUpc = true;
      this.showSdsInputs = true;
      this.f.revision_date.setValidators(Validators.required);
      this.f.revision_date.updateValueAndValidity();
      this.counter = [1];
      this.counter.map(controlNumer => {
        this.form.controls[`upc_${controlNumer}`].setValue('');
        this.form.controls[`product_name_${controlNumer}`].setValue('');
        this.form.controls[`upc_${controlNumer}`].setValidators([Validators.required, this.UpcValidator(controlNumer)]);
        this.form.controls[`upc_${controlNumer}`].updateValueAndValidity();
        this.form.controls[`product_name_${controlNumer}`].setValidators(Validators.required);
        this.form.controls[`product_name_${controlNumer}`].updateValueAndValidity();
        this.form.controls[`manufacturer_${controlNumer}`].setValidators(null);
        this.form.controls[`manufacturer_${controlNumer}`].setValue('');
        this.form.controls[`manufacturer_${controlNumer}`].updateValueAndValidity();

        this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].setValidators(null);
        this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].setValue('');
        this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].updateValueAndValidity();

        this.form.controls[`product_info_url_${controlNumer}`].setValidators(null);
        this.form.controls[`product_info_url_${controlNumer}`].setValue('');
        this.form.controls[`product_info_url_${controlNumer}`].updateValueAndValidity();
      });
    }
  }

  hideAll() {
    this.showSdsInputs = false;
    this.showAddUpc = false;
    this.f.revision_date.setValue('');
    this.f.revision_date.setValidators(null);
    this.f.revision_date.updateValueAndValidity();

    this.counter.map(controlNumer => {
      this.form.controls[`upc_${controlNumer}`].setValidators(null);
      this.form.controls[`upc_${controlNumer}`].setValue('');
      this.form.controls[`upc_${controlNumer}`].updateValueAndValidity();

      this.form.controls[`product_name_${controlNumer}`].setValidators(null);
      this.form.controls[`product_name_${controlNumer}`].setValue('');
      this.form.controls[`product_name_${controlNumer}`].updateValueAndValidity();

      this.form.controls[`manufacturer_${controlNumer}`].setValidators(null);
      this.form.controls[`manufacturer_${controlNumer}`].setValue('');
      this.form.controls[`manufacturer_${controlNumer}`].updateValueAndValidity();

      this.form.controls[`product_info_url_${controlNumer}`].setValidators(null);
      this.form.controls[`product_info_url_${controlNumer}`].setValue('');
      this.form.controls[`product_info_url_${controlNumer}`].updateValueAndValidity();

      this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].setValidators(null);
      this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].setValue('');
      this.form.controls[`sku_itemNumber_productNumber_${controlNumer}`].updateValueAndValidity();
    });
  }

  url(i) {
    if (this.form.controls[`product_info_url_${i}`].value.length > 0) {
      this.form.controls[`product_info_url_${i}`].setValidators(
        Validators.pattern(
          '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
        )
      );
      this.form.controls[`product_info_url_${i}`].updateValueAndValidity();
    } else {
      this.form.controls[`product_info_url_${i}`].setValidators(null);
      this.form.controls[`product_info_url_${i}`].updateValueAndValidity();
    }
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.f.pdf_type.value !== 'pdf') {
      this.checkOverAllValidation();
    }
    if (this.form.valid) {
      if (this.f.pdf_type.value === 'pdf') {
        const request: any = {};
        request[`pdf_type`] = this.f.pdf_type.value;
        this.post(request);
      } else {
        this.checkDigit();
        if (this.validUpc && !this.notValidMessage) {
          const request: any = {};
          request[`pdf_type`] = this.f.pdf_type.value;
          request[`sds_revision_date`] = this.f.revision_date.value;
          this.counter.map((controlNumber, index) => {
            request[`upc_${index + 1}`] = this.responseUpc[index].upc;
            request[`product_name_${index + 1}`] = this.f[`product_name_${controlNumber}`].value;
            request[`manufacturer_${index + 1}`] = this.f[`manufacturer_${controlNumber}`].value;
            request[`product_info_url_${index + 1}`] = this.f[`product_info_url_${controlNumber}`].value;
            request[`sku_itemNumber_productNumber_${index + 1}`] = this.f[
              `sku_itemNumber_productNumber_${controlNumber}`
            ].value;

            if (this.f[`manufacturer_${controlNumber}`].value === '') {
              delete request[`manufacturer_${index + 1}`];
            }
            if (this.f[`product_info_url_${controlNumber}`].value === '') {
              delete request[`product_info_url_${index + 1}`];
            }
            if (this.f[`sku_itemNumber_productNumber_${controlNumber}`].value === '') {
              delete request[`sku_itemNumber_productNumber_${index + 1}`];
            }
          });

          this.post(request);
        }
      }
    }
  }

  checkOverAllValidation() {
    this.counter.map(controlNumber => {
      this.checkDigit();
      if (this.form.controls[`upc_${controlNumber}`].invalid) {
        const upcLength = this.form.controls[`upc_${controlNumber}`].value.length;
        if (!(upcLength === 8 || (upcLength >= 12 && upcLength <= 14))) {
          this.toastr.error('Please insert a valid UPC ( eg: 8, 12, 13 or 14 numbers )');
          this.upcRequired = true;
        }
      }
      if (this.form.controls[`product_name_${controlNumber}`].invalid) {
        this.toastr.error('Please insert a product name');
        this.productRequired = true;
      }
      if (this.form.controls[`revision_date`].invalid) {
        this.toastr.error('Please insert a revision date');
        this.revisionDateRequired = true;
      }
      if (this.form.controls[`product_info_url_${controlNumber}`].invalid) {
        this.toastr.error('Please insert a valid url');
      }
    });
  }

  UpcValidator(i): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!isNaN(this.form.controls[`upc_${i}`].value) && this.form.controls[`upc_${i}`].value.length > 0) {
        this.upcValues = this.form.controls[`upc_${i}`].value.toString().split('');
        if (
          this.upcValues.length < 8 ||
          (this.upcValues.length > 8 && this.upcValues.length < 12) ||
          this.upcValues.length > 14
        ) {
          this.invalidInput = false;
          return { upc: true };
        }
        this.invalidInput = true;
        return null;
      } else if (this.form.controls[`upc_${i}`].value.length > 0) {
        this.toastr.error('Please insert numeric values');
        this.invalidInput = false;
        return { upc: true };
      }
    };
  }

  checkDigit() {
    const temporalResponseUpc = [];
    this.counter.map(controlNumber => {
      const upcArray = Array.from(String(this.form.controls[`upc_${controlNumber}`].value), Number);
      upcArray.pop();
      const multiplicationDigits = this.multiplicationValues.slice(-upcArray.length);
      let resultMultiplication = 0;
      upcArray.map((digit, i) => (resultMultiplication += digit * multiplicationDigits[i]));
      const result = Math.ceil(resultMultiplication / 10) * 10 - resultMultiplication;
      if (this.form.controls[`upc_${controlNumber}`].value !== [...upcArray, Math.abs(result)].join('')) {
        this.form.controls[`upc_${controlNumber}`].setErrors({ incorrect: true });
        this.toastr.error('This UPC number is not valid');
        this.invalidInput = false;
        this.notValidMessage = true;
        this.upcRequired = true;
      } else {
        temporalResponseUpc.push(Object.assign({ upc: [...upcArray, Math.abs(result)].join('') }));
      }
    });
    if (this.form.valid) {
      this.responseUpc = temporalResponseUpc;
      this.notValidMessage = false;
      this.validUpc = true;
    }
  }

  addControls() {
    this.checkOverAllValidation();
    if (this.form.valid) {
      const i = this.counter[this.counter.length - 1] + 1;
      this.counter.push(i);

      this.form.addControl(`upc_${i}`, new FormControl(''));
      this.form.addControl(`product_name_${i}`, new FormControl(null));
      this.form.addControl(`manufacturer_${i}`, new FormControl(''));
      this.form.addControl(`product_info_url_${i}`, new FormControl(''));
      this.form.addControl(`sku_itemNumber_productNumber_${i}`, new FormControl(''));

      this.form.controls[`upc_${i}`].setValidators([Validators.required, this.UpcValidator(i)]);
      this.form.controls[`upc_${i}`].updateValueAndValidity();

      this.form.controls[`product_name_${i}`].setValidators(Validators.required);
      this.form.controls[`product_name_${i}`].updateValueAndValidity();

      this.form.controls[`manufacturer_${i}`].setValidators(null);
      this.form.controls[`manufacturer_${i}`].updateValueAndValidity();
      this.invalidInput = false;
    }
  }

  remove(i) {
    this.form.removeControl(`upc_${i}`);
    this.form.removeControl(`product_name_${i}`);
    this.form.removeControl(`manufacturer_${i}`);
    this.form.removeControl(`product_info_url_${i}`);
    this.form.removeControl(`sku_itemNumber_productNumber_${i}`);

    this.counter = this.counter.filter(item => item !== i);
  }

  errors(name, i) {
    return this.form.controls[`${name}${i}`].errors;
  }

  checkUpcValidation(i) {
    this.checkDigit();
    const upcFormValue = this.f[`upc_${i}`].value.length;
    if (!(upcFormValue === 8 || upcFormValue === 12 || upcFormValue === 13)) {
      this.toastID = this.toastr.error('Please insert a valid UPC ( eg: 8, 12, 13 or 14 numbers )').toastId;
    } else {
      this.toastr.clear(this.toastID);
    }
  }
  get f() {
    return this.form.controls;
  }
}
