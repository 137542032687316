import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class ReportComponent implements OnInit {
  @Input() themeVar: string;
  constructor() {}

  public supervisor = false;

  ngOnInit() {
    this.assignSupervisorState();
  }

  private assignSupervisorState() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.supervisor = user.supervisor;
  }
}
