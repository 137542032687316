import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root"
})
export class TaskService {
  constructor(private httpClient: HttpClient) {}

  list(page) {
    return this.httpClient.get(`${environment.apiUrl}tasks?page=${page}`);
  }

  get(id: number) {
    return this.httpClient.get(`${environment.apiUrl}tasks/${id}`);
  }

  post(id: number, data: any) {
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/results`,
      data
    );
  }

  delete(taskId: number, data: any) {
    return this.httpClient.request(
      'DELETE',
      `${environment.apiUrl}tasks/${taskId}/cancel`,
      { body: data }
    );
  }

  userTask(id: number) {
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/user_tasks`,
      null
    );
  }

  getUserTask() {
    return this.httpClient.get(`${environment.apiUrl}user_tasks`);
  }
}
