import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/task/forms/base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-physical-point',
  templateUrl: './physical-point.component.html',
  styleUrls: ['./physical-point.component.less']
})
export class PhysicalPointComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  showNumber = false;
  showRange = false;
  symbols = [];
  symbols_degree = [];
  symbols_min = [];
  symbols_max = [];
  units = [];
  subComponentData = [];

  @Input() title: string;
  @Output() handleRequest = new EventEmitter();

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.symbols = this.requestService.symbols;
    this.symbols_degree = this.requestService.symbols_degrees;
    this.symbols_min = this.requestService.symbols_min;
    this.symbols_max = this.requestService.symbols_max;
    this.units = this.requestService.units;
    this.form = this.formBuilder.group({
      format: ['', Validators.required],
      symbol: ['', Validators.required],
      value: [null, [Validators.pattern(/^[.\d.-]+$/)]],
      value_min: [null, Validators.pattern(/^[.\d.-]+$/)],
      symbol_min: ['', Validators.required],
      value_max: [null, Validators.pattern(/^[.\d.-]+$/)],
      symbol_max: ['', Validators.required],
      unit: ['']
    });

    if (this.expertClassificationMode) {
      this.expertService.disableReadOnlyForm(this.form, true);
    }

    this.expertService.getPhysicalData$.subscribe(data => {
      if (data) {
        let { type, form, treeData } = data;
        this.subComponentData.push({ treeData, type });
        this.loadInfo(form);
      }
    });
  }

  changeFormat(resetData: boolean = true) {
    this.hideAll();
    if (resetData) {
      this.resetValue();
    }
    switch (this.f.format.value) {
      case 'single_number':
        this.showNumber = true;
        this.f.value.setValidators([Validators.pattern(/^[.\d.-]+$/), Validators.required]);
        this.f.symbol.setValidators(Validators.required);
        this.f.unit.setValidators(Validators.required);
        break;
      case 'range_given':
        this.showRange = true;
        this.f.value_min.setValidators([Validators.pattern(/^[.\d.-]+$/), Validators.required]);
        this.f.symbol_min.setValidators(Validators.required);
        this.f.value_max.setValidators([Validators.pattern(/^[.\d.-]+$/), Validators.required]);
        this.f.symbol_max.setValidators(Validators.required);
        this.f.unit.setValidators(Validators.required);
        break;
    }
    this.callUpdateValueAndValidity();
  }

  hideAll() {
    this.showNumber = false;
    this.showRange = false;
    this.f.value.setValidators(null);
    this.f.symbol.setValidators(null);
    this.f.value_min.setValidators(null);
    this.f.symbol_min.setValidators(null);
    this.f.value_max.setValidators(null);
    this.f.symbol_max.setValidators(null);
    this.f.unit.setValidators(null);
    this.callUpdateValueAndValidity();
  }

  loadInfo(data) {
    this.f.format.setValue(data.format);
    this.changeFormat(false);
    switch (this.f.format.value) {
      case 'single_number':
        this.showNumber = true;
        this.f.value.setValue(data.value);
        this.f.symbol.setValue(this.symbols.find(e => e.value === data.symbol).value);
        this.f.unit.setValue(this.units.find(e => e.value == data.unit).value);
        break;

      case 'range_given':
        this.showRange = true;
        this.f.value_min.setValue(data.value_min);
        this.f.value_max.setValue(data.value_max);
        this.f.symbol_min.setValue(this.symbols_min.find(e => e.value === data.symbol_min).value);
        this.f.symbol_max.setValue(this.symbols_max.find(e => e.value === data.symbol_max).value);
        this.f.unit.setValue(this.units.find(e => e.value == data.unit).value);
        break;
    }
  }

  submit() {
    if (!this.confirmSubmit()) { return; }

    this.submitted = true;
    if (this.form.valid) {
      this.handleRequest.emit(this.form);
    }
  }

  back() {
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  get f() {
    return this.form.controls;
  }

  /**
   * Resets form values of symbol, value and unit.
   */
  private resetValue() {
    this.f.value.setValue(null);
    this.f.symbol.setValue(null);
    this.f.value_min.setValue(null);
    this.f.symbol_min.setValue(null);
    this.f.value_max.setValue(null);
    this.f.symbol_max.setValue(null);
    this.f.unit.setValue(null);
  }

  /**
   * Calls updateValueAndValidity for each form field.
   */
  private callUpdateValueAndValidity() {
    this.f.value.updateValueAndValidity();
    this.f.value_max.updateValueAndValidity();
    this.f.value_min.updateValueAndValidity();
    this.f.symbol.updateValueAndValidity();
    this.f.symbol_max.updateValueAndValidity();
    this.f.symbol_min.updateValueAndValidity();
    this.f.unit.updateValueAndValidity();
  }
}
