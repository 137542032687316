import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TaskService } from "src/app/shared/services/task/task.service";
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { BaseComponent } from "../base/base.component";
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import {Location, LocationStrategy} from '@angular/common';

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.less"]
})
export class FormComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  originalData = [];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService,
    private location: Location,
    private locationStrategy : LocationStrategy
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      physical_form: ["", Validators.required]
    });
    if(this.expertClassificationMode){
      this.expertService.disableReadOnlyForm(this.form, true);
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.originalData.push(data);
          this.loadInfo(data.form);
        }
      });
    }
  }

  loadInfo(data){
    if (data) {
      this.f.physical_form.setValue(data.toLowerCase());
    } else {
      this.f.physical_form.setValue('');
    }
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.form.valid) {
      const request = { physical_form: this.f.physical_form.value };
      this.post(request);
    }
  }

  back(){
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  get f() {
    return this.form.controls;
  }
}
