import { Component, OnInit, ElementRef, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-waste',
  templateUrl: './waste.component.html',
  styleUrls: ['./waste.component.less']
})

export class WasteComponent implements OnInit {
  @ViewChildren('waste') div:ElementRef;

  form: FormGroup;
  codes: {};

  native_controls = ['ca', 'ct', 'md', 'mi', 'mn', 'mo', 'nh', 'nj', 'ny', 'vt', 'ma', 'me', 'or', 'pa', 'ri'];

  codeSubscriptions: { [state: string]: Subscription };
  selectedCodeSubscriptions: { [state: string]: Subscription };

  constructor(
    private formBuilder: FormBuilder,
    private expertService: ExpertService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      ca: [null, Validators.required],
      ct: [null, Validators.required],
      md: [null, Validators.required],
      mi: [null, Validators.required],
      mn: [null, Validators.required],
      mo: [null, Validators.required],
      nh: [null, Validators.required],
      nj: [null, Validators.required],
      ny: [null, Validators.required],
      vt: ['', Validators.required],
      ma: ['', Validators.required],
      me: ['', Validators.required],
      or: ['', Validators.required],
      pa: ['', Validators.required],
      ri: ['', Validators.required]
    });

    this.codes = {};
    this.codeSubscriptions = {};
    this.selectedCodeSubscriptions = {};

    this.native_controls.forEach(state => {
      this.codeSubscriptions[state] = this.expertService.getCodes('waste', state).subscribe((value) => {
        this.codes[state] = value || [];
      });

      this.selectedCodeSubscriptions[state] = this.expertService.getSelectedCodes('waste', state).subscribe((value) => {
        this.expertService.disableReadOnlyForm(this.form);
        if (!value || !Object.keys(value).length) { value = null; }
        this.f[state].setValue(value);
      });
    });
  }

  disableScroll(){
    let waste_div = this.div['first'].nativeElement;
    var x=waste_div.scrollLeft;
    var y=waste_div.scrollTop;
    waste_div.onscroll=function(){waste_div.scrollTo(x, y);};
  }

  enableScroll(){
    let waste_div = this.div['first'].nativeElement;
    waste_div.onscroll=function(){};
  }

  onChange(state: string) {
    this.expertService.setCode(this.f[state].value, 'waste', state);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.native_controls.forEach(state => {
      this.codeSubscriptions[state].unsubscribe();
      this.selectedCodeSubscriptions[state].unsubscribe();
    });
  }
}
