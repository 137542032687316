import { None, NotApplicable, NotRegulated, Forbidden } from 'src/app/store/models/product.model'

export const UnFilters = {
  invalid_null: {
    hazard_class_division: NotApplicable.id,
    compatibility_group: NotApplicable.id,
    packing_group: NotApplicable.id,
    marine_pollutant: NotApplicable.id,
    limited_quantity: NotApplicable.id,
    excepted_quantity: NotApplicable.id,
    reportable_quantity_lbs: NotApplicable.id,
    technical_name: [NotApplicable.id],
    subrisk: [None.id],
    transportation_exception: [None.id]
  },
  not_regulated: {
    compatibility_group: NotApplicable.id,
    packing_group: NotApplicable.id,
    marine_pollutant: "False",
    limited_quantity: NotApplicable.id,
    excepted_quantity: NotApplicable.id,
    reportable_quantity_lbs: NotApplicable.id,

    transportation_exception: [None.id],
    subrisk: [None.id],

    hazard_class_division: NotRegulated.id,
    select_technical_name: NotRegulated.id,
    technical_name: [NotRegulated.id],
    select_proper_shipping_name: NotRegulated.id,
    proper_shipping_name: NotRegulated.id
  },
  forbidden: {
    proper_shipping_name: Forbidden.name,
    hazard_class_division: NotApplicable.id,
    compatibility_group: NotApplicable.id,
    packing_group: NotApplicable.id,
    marine_pollutant: NotApplicable.id,
    limited_quantity: NotApplicable.id,
    excepted_quantity: NotApplicable.id,
    reportable_quantity_lbs: NotApplicable.id,
    select_technical_name: NotApplicable.id,
    technical_name: [NotApplicable.id],
    subrisk: [None.id],
    transportation_exception: [None.id]
  },
  UN0004: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0005: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0006: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0007: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0009: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0010: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0012: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0014: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0015: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0016: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0018: {
    hazard_class_division: 1.2,
    subrisk: ["8", "6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0019: {
    hazard_class_division: 1.3,
    subrisk: ["8", "6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0020: {
    hazard_class_division: 1.2,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "K"
  },
  UN0021: {
    hazard_class_division: 1.3,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "K"
  },
  UN0027: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0028: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0029: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0030: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0033: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0034: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0035: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0037: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0038: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0039: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0042: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0043: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0044: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0048: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0049: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0050: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0054: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0055: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0056: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0059: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0060: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0065: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0066: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0070: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0072: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0073: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0074: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0075: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0076: {
    hazard_class_division: 1.1,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0077: {
    hazard_class_division: 1.3,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0078: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0079: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0081: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0082: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0083: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0084: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0092: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0093: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0094: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0099: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0101: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0102: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0103: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0104: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0105: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0106: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0107: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0110: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0113: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0114: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0118: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0121: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0124: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0129: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0130: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0131: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0132: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0133: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0135: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0136: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0137: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0138: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0143: {
    hazard_class_division: 1.1,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0144: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0146: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0147: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0150: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0151: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0153: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0154: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0155: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0159: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0160: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0161: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0167: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0168: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0169: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0171: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0173: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0174: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0180: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0181: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0182: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0183: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0186: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0190: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN0191: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0192: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0193: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0194: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0195: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0196: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0197: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0204: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0207: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0208: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0209: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0212: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0213: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0214: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0215: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0216: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0217: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0218: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0219: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0220: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0221: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0222: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0224: {
    hazard_class_division: 1.1,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0225: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0226: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0234: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0235: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0236: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0237: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0238: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0240: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0241: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0242: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0243: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "H"
  },
  UN0244: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "H"
  },
  UN0245: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "H"
  },
  UN0246: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "H"
  },
  UN0247: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0248: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0249: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0250: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0254: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0255: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0257: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0266: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0267: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0268: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0271: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0272: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0275: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0276: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0277: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0278: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0279: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0280: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0281: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0282: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0283: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0284: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0285: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0286: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0287: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0288: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0289: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0290: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0291: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0292: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0293: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0294: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0295: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0296: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0297: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0299: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0300: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0301: {
    hazard_class_division: 1.4,
    subrisk: ["8", "6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0303: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0305: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0306: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0312: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0313: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0314: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0315: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0316: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0317: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0318: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0319: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0320: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0321: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0322: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0323: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0324: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0325: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0326: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0327: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0328: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0329: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0330: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0331: {
    hazard_class_division: 1.5,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0332: {
    hazard_class_division: 1.5,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0333: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0334: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0335: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0336: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0337: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0338: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0339: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0340: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0341: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0342: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0343: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0344: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0345: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0346: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0347: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0348: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0349: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0350: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0351: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0352: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0353: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0354: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0355: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0356: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0357: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0358: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0359: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0360: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0361: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0362: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0363: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0364: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0365: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0366: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0367: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0368: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0369: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0370: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0371: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0372: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0373: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0374: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0375: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0376: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0377: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0378: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0379: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0380: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "L"
  },
  UN0381: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0382: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0383: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0384: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0385: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0386: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0387: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0388: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0389: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0390: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0391: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0392: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0393: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0394: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0395: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0396: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0397: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0398: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0399: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0400: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0401: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0402: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0403: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0404: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0405: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0406: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0407: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0408: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0409: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0410: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0411: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0412: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0413: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0414: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0415: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0417: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0418: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0419: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0420: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0421: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0424: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0425: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0426: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0427: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0428: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0429: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0430: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0431: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0432: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0433: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0434: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0435: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0436: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0437: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0438: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0439: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0440: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0441: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0442: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0443: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0444: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0445: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0446: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0447: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0448: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0449: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0450: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "J"
  },
  UN0451: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0452: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0453: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0454: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0455: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0456: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0457: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0458: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0459: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0460: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0461: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "B"
  },
  UN0462: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0463: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0464: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0465: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0466: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0467: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0468: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0469: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0470: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0471: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "E"
  },
  UN0472: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "F"
  },
  UN0473: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "A"
  },
  UN0474: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0475: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0476: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0477: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0478: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0479: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0480: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0481: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0482: {
    hazard_class_division: 1.5,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0483: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0484: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0485: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0486: {
    hazard_class_division: 1.6,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "N"
  },
  UN0487: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0488: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0489: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0490: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0491: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0492: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0493: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0494: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0495: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0496: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0497: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0498: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0499: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0500: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0501: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0502: {
    hazard_class_division: 1.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0503: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0504: {
    hazard_class_division: 1.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "D"
  },
  UN0505: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "G"
  },
  UN0506: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0507: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "S"
  },
  UN0508: {
    hazard_class_division: 1.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0509: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN0510: {
    hazard_class_division: 1.4,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: "C"
  },
  UN1001: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1002: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1003: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1005: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1006: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1008: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1009: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1010: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1011: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1012: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1013: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1016: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1017: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1018: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1020: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1021: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1022: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1023: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1026: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1027: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1028: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1029: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1030: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1032: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1033: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1035: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1036: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1037: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1038: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1039: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1040: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1041: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1043: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1044: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1045: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1046: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1048: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1049: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1050: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1051: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1052: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1053: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1055: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1056: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1057: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1058: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1060: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1061: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1062: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1063: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1064: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1065: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1066: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1067: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1069: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1070: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1071: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1072: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1073: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1075: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1076: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1077: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1078: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1079: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1080: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1081: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1082: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1083: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1085: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1086: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1087: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1088: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1089: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1090: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1091: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1092: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1093: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1098: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1099: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1100: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1104: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1105: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1106: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1107: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1108: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1109: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1110: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1111: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1112: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1113: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1114: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1120: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1123: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1125: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1126: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1127: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1128: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1129: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1130: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1131: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1133: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1134: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1135: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1136: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1139: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1143: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1144: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1145: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1146: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1147: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1148: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1149: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1150: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1152: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1153: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1154: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1155: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1156: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1157: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1158: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1159: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1160: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1161: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1162: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1163: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1164: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1165: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1166: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1167: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1169: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1170: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1171: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1172: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1173: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1175: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1176: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1177: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1178: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1179: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1180: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1181: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1182: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1183: {
    hazard_class_division: 4.3,
    subrisk: ["8", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1184: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1185: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1188: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1189: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1190: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1191: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1192: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1193: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1194: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1195: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1196: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1197: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1198: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1199: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1201: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1202: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1203: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1204: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1206: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1207: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1208: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1210: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1212: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1213: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1214: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1216: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1218: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1219: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1220: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1221: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1222: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1223: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1224: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1228: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1229: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1230: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1231: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1233: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1234: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1235: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1237: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1238: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1239: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1242: {
    hazard_class_division: 4.3,
    subrisk: ["8", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1243: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1244: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1245: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1246: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1247: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1248: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1249: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1250: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1251: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1259: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1261: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1262: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1263: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1264: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1265: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1266: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1267: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1268: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1270: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1272: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1274: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1275: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1276: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1277: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1278: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1279: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1280: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1281: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1282: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1286: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1287: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1288: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1289: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1292: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1293: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1294: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1295: {
    hazard_class_division: 4.3,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1296: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1297: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1298: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1299: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1300: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1301: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1302: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1303: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1304: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1305: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1306: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1307: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1308: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1309: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1310: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1312: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1313: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1314: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1318: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1320: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1321: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1322: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1323: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1324: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1325: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1326: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1328: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1330: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1331: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1332: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1333: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1334: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1336: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1337: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1338: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1339: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1340: {
    hazard_class_division: 4.3,
    subrisk: ["4.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1341: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1343: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1344: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1345: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1346: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1347: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1348: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1349: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1350: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1352: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1353: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1354: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1355: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1356: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1357: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1358: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1360: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1361: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1362: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1363: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1364: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1365: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1369: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1372: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1373: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1374: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1376: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1378: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1379: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1380: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1381: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1382: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1383: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1384: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1385: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1386: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1387: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1389: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1390: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1391: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1392: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1393: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1394: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1395: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1396: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1397: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1398: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1400: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1401: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1402: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1403: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1404: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1405: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1407: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1408: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1409: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1410: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1411: {
    hazard_class_division: 4.3,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1413: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1414: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1415: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1417: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1418: {
    hazard_class_division: 4.3,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1419: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1420: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1421: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1422: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1423: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1426: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1427: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1428: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1431: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1432: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1433: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1435: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1436: {
    hazard_class_division: 4.3,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1437: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1438: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1439: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1442: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1444: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1445: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1446: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1447: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1448: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1449: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1450: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1451: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1452: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1453: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1454: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1455: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1456: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1457: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1458: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1459: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1461: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1462: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1463: {
    hazard_class_division: 5.1,
    subrisk: ["6.1", "8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1465: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1466: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1467: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1469: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1470: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1471: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1472: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1473: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1474: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1475: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1476: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1477: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1479: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1481: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1482: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1483: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1484: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1485: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1486: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1487: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1488: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1489: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1490: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1491: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1492: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1493: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1494: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1495: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1496: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1498: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1499: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1500: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1502: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1503: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1504: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1505: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1506: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1507: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1508: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1509: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1510: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1511: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1512: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1513: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1514: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1515: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1516: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1517: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1541: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1544: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1545: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1546: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1547: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1548: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1549: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1550: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1551: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1553: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1554: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1555: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1556: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1557: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1558: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1559: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1560: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1561: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1562: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1564: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1565: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1566: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1567: {
    hazard_class_division: 6.1,
    subrisk: ["4.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1569: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1570: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1571: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1572: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1573: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1574: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1575: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1577: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1578: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1579: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1580: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1581: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1582: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1583: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1585: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1586: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1587: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1588: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1589: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1590: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1591: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1593: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1594: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1595: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1596: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1597: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1598: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1599: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1600: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1601: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1602: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1603: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1604: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1605: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1606: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1607: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1608: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1611: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1612: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1613: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1614: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1616: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1617: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1618: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1620: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1621: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1622: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1623: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1624: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1625: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1626: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1627: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1629: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1630: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1631: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1634: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1636: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1637: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1638: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1639: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1640: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1641: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1642: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1643: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1644: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1645: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1646: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1647: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1648: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1649: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1650: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1651: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1652: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1653: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1654: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1655: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1656: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1657: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1658: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1659: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1660: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1661: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1662: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1663: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1664: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1665: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1669: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1670: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1671: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1672: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1673: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1674: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1677: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1678: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1679: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1680: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1683: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1684: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1685: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1686: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1687: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1688: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1689: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1690: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1691: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1692: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1693: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1694: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1695: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1697: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1698: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1699: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1700: {
    hazard_class_division: 6.1,
    subrisk: ["4.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1701: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1702: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1704: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1707: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1708: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1709: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1710: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1711: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1712: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1713: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1714: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1715: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1716: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1717: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1718: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1719: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1722: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1723: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1724: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1725: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1726: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1727: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1728: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1729: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1730: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1731: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1732: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1733: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1736: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1737: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1738: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1739: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1740: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1741: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1742: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1743: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1744: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1745: {
    hazard_class_division: 5.1,
    subrisk: ["6.1", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1746: {
    hazard_class_division: 5.1,
    subrisk: ["6.1", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1747: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1748: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1749: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1750: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1751: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1752: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1753: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1754: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1755: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1756: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1757: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1758: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1759: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1760: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1761: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1762: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1763: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1764: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1765: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1766: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1767: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1768: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1769: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1770: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1771: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1773: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1774: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1775: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1776: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1777: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1778: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1779: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1780: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1781: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1782: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1783: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1784: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1786: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1787: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1788: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1789: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1790: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1791: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1792: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1793: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1794: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1796: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1798: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1799: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1800: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1801: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1802: {
    hazard_class_division: 8,
    subrisk: ["5.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1803: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1804: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1805: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1806: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1807: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1808: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1809: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1810: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1811: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1812: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1813: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1814: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1815: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1816: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1817: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1818: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1819: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1823: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1824: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1825: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1826: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1827: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1828: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1829: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1830: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1831: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1832: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1833: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1834: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1835: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1836: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1837: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1838: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1839: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1840: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1841: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1843: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1845: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1846: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1847: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1848: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1849: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1851: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1854: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1855: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1856: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1857: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1858: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1859: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1860: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1862: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1863: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1865: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1866: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1868: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1869: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1870: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1871: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1872: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1873: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1884: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1885: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1886: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1887: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1888: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1889: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1891: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1892: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1894: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1895: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1897: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1898: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1902: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1903: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1905: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1906: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1907: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1908: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1910: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1911: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1912: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1913: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1914: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1915: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1916: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1917: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1918: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1919: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1920: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1921: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1922: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1923: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1928: {
    hazard_class_division: 4.3,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1929: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1931: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1932: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1935: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1938: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1939: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1940: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN1941: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1942: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1944: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1945: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1950: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1951: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1952: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1953: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1954: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1955: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1956: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1957: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1958: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1959: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1961: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1962: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1963: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1964: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1965: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1966: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1967: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1968: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1969: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1970: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1971: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1972: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1973: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1974: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1975: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1976: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1977: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1978: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1982: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1983: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1984: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN1986: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1987: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1988: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1989: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1990: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN1991: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1992: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1993: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN1994: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN1999: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2000: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2001: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2002: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2004: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2006: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2008: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2009: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2010: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2011: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2012: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2013: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2014: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2015: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2016: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2017: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2018: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2019: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2020: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2021: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2022: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2023: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2024: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2025: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2026: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2027: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2028: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2029: {
    hazard_class_division: 8,
    subrisk: ["3", "6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2030: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2031: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2032: {
    hazard_class_division: 8,
    subrisk: ["5.1", "6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2033: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2034: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2035: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2036: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2037: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2038: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2044: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2045: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2046: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2047: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2048: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2049: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2050: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2051: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2052: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2053: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2054: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2055: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2056: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2057: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2058: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2059: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2067: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2071: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2073: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2074: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2075: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2076: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2077: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2078: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2079: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2186: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2187: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2188: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2189: {
    hazard_class_division: 2.3,
    subrisk: ["2.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2190: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2191: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2192: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2193: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2194: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2195: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2196: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2197: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2198: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2199: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2200: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2201: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2202: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2203: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2204: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2205: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2206: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2208: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2209: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2210: {
    hazard_class_division: 4.2,
    subrisk: ["4.3"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2211: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2212: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2213: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2214: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2215: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2216: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2217: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2218: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2219: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2222: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2224: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2225: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2226: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2227: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2232: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2233: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2234: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2235: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2236: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2237: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2238: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2239: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2240: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2241: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2242: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2243: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2244: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2245: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2246: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2247: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2248: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2249: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2250: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2251: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2252: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2253: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2254: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2256: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2257: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2258: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2259: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2260: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2261: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2262: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2263: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2264: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2265: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2266: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2267: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2269: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2270: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2271: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2272: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2273: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2274: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2275: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2276: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2277: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2278: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2279: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2280: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2281: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2282: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2283: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2284: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2285: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2286: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2287: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2288: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2289: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2290: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2291: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2293: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2294: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2295: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2296: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2297: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2298: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2299: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2300: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2301: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2302: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2303: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2304: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2305: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2306: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2307: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2308: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2309: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2310: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2311: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2312: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2313: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2315: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2316: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2317: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2318: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2319: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2320: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2321: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2322: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2323: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2324: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2325: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2326: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2327: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2328: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2329: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2330: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2331: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2332: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2333: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2334: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2335: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2336: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2337: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2338: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2339: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2340: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2341: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2342: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2343: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2344: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2345: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2346: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2347: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2348: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2350: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2351: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2352: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2353: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2354: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2356: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2357: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2358: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2359: {
    hazard_class_division: 3,
    subrisk: ["6.1", "8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2360: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2361: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2362: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2363: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2364: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2366: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2367: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2368: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2370: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2371: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2372: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2373: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2374: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2375: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2376: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2377: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2378: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2379: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2380: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2381: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2382: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2383: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2384: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2385: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2386: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2387: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2388: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2389: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2390: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2391: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2392: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2393: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2394: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2395: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2396: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2397: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2398: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2399: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2400: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2401: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2402: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2403: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2404: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2405: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2406: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2407: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2409: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2410: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2411: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2412: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2413: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2414: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2416: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2417: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2418: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2419: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2420: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2421: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2422: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2424: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2426: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2427: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2428: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2429: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2430: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2431: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2432: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2433: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2434: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2435: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2436: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2437: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2438: {
    hazard_class_division: 6.1,
    subrisk: ["8", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2439: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2440: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2441: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2442: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2443: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2444: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2446: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2447: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2448: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2451: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2452: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2453: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2454: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2456: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2457: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2458: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2459: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2460: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2461: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2463: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2464: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2465: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2466: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2468: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2469: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2470: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2471: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2473: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2474: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2475: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2477: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2478: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2480: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2481: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2482: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2483: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2484: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2485: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2486: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2487: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2488: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2490: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2491: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2493: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2495: {
    hazard_class_division: 5.1,
    subrisk: ["6.1", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2496: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2498: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2501: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2502: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2503: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2504: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2505: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2506: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2507: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2508: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2509: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2511: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2512: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2513: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2514: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2515: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2516: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2517: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2518: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2520: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2521: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2522: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2524: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2525: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2526: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2527: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2528: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2529: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2531: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2533: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2534: {
    hazard_class_division: 2.3,
    subrisk: ["2.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2535: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2536: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2538: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2541: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2542: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2545: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2546: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2547: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2548: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2552: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2554: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2555: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2556: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2557: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2558: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2560: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2561: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2564: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2565: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2567: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2570: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2571: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2572: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2573: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2574: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2576: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2577: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2578: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2579: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2580: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2581: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2582: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2583: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2584: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2585: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2586: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2587: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2588: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2589: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2590: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2591: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2599: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2601: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2602: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2603: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2604: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2605: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2606: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2607: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2608: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2609: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2610: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2611: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2612: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2614: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2615: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2616: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2617: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2618: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2619: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2620: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2621: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2622: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2623: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2624: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2626: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2627: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2628: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2629: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2630: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2642: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2643: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2644: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2645: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2646: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2647: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2648: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2649: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2650: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2651: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2653: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2655: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2656: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2657: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2659: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2660: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2661: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2664: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2667: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2668: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2669: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2670: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2671: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2672: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2673: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2674: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2676: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2677: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2678: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2679: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2680: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2681: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2682: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2683: {
    hazard_class_division: 8,
    subrisk: ["6.1", "3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2684: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2685: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2686: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2687: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2688: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2689: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2690: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2691: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2692: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2693: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2698: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2699: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2705: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2707: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2709: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2710: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2713: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2714: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2715: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2716: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2717: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2719: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2720: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2721: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2722: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2723: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2724: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2725: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2726: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2727: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2728: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2729: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2730: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2732: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2733: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2734: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2735: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2738: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2739: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2740: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2741: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2742: {
    hazard_class_division: 6.1,
    subrisk: ["8", "3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2743: {
    hazard_class_division: 6.1,
    subrisk: ["8", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2744: {
    hazard_class_division: 6.1,
    subrisk: ["8", "3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2745: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2746: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2747: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2748: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2749: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2750: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2751: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2752: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2753: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2754: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2757: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2758: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2759: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2760: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2761: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2762: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2763: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2764: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2771: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2772: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2775: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2776: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2777: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2778: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2779: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2780: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2781: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2782: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2783: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2784: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2785: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2786: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2787: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2788: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2789: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2790: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2793: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2794: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2795: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2796: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2797: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2798: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2799: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2800: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2801: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2802: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2803: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2805: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2806: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2809: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2810: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2811: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2812: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2813: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2814: {
    hazard_class_division: 6.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2815: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2817: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2818: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2819: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2820: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2821: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2822: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2823: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2826: {
    hazard_class_division: 8,
    subrisk: ["6.1", "3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2829: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2830: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2831: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2834: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2835: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2837: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2838: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2839: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2840: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2841: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2842: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2844: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2845: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2846: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2849: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2850: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2851: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2852: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2853: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2854: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2855: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2856: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2857: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2858: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2859: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2861: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2862: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2863: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2864: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2865: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2869: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2870: {
    hazard_class_division: 4.2,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2871: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2872: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2873: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2874: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2875: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2876: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2878: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2879: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2880: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2881: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2900: {
    hazard_class_division: 6.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2901: {
    hazard_class_division: 2.3,
    subrisk: ["8", "5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2902: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2903: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2904: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2905: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2907: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2908: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2909: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2910: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2911: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2912: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2913: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2915: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2916: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2917: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2919: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2920: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2921: {
    hazard_class_division: 8,
    subrisk: ["4.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2922: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2923: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2924: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2925: {
    hazard_class_division: 4.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2926: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2927: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2928: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2929: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2930: {
    hazard_class_division: 6.1,
    subrisk: ["4.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2931: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2933: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2934: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2935: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2936: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2937: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2940: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2941: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2942: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2943: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2945: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2946: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2947: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2948: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2949: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2950: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2956: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2965: {
    hazard_class_division: 4.3,
    subrisk: ["8", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2966: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2967: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2968: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2969: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2977: {
    hazard_class_division: 7,
    subrisk: ["6.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2978: {
    hazard_class_division: 7,
    subrisk: ["6.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2983: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2984: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN2985: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2986: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2987: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN2988: {
    hazard_class_division: 4.3,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN2989: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2990: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN2991: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2992: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2993: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2994: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2995: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2996: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2997: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN2998: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3002: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3005: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3006: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3009: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3010: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3011: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3012: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3013: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3014: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3015: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3016: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3017: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3018: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3019: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3020: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3021: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3022: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3023: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3024: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3025: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3026: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3027: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3028: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3048: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3054: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3055: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3056: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3057: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3064: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3065: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3066: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3070: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3071: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3072: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3073: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3077: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id,
    ground: {transportation_exception: ["171.4"]},
    air: {transportation_exception: ["A197 (375) HC9 exemption"]},
    sea: {transportation_exception: ["2.10.2.7 HC9 exemption"]}
  },
  UN3078: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3079: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3080: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3082: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id,
    ground: {transportation_exception: ["171.4"]},
    air: {transportation_exception: ["A197 (375) HC9 exemption"]},
    sea: {transportation_exception: ["2.10.2.7 HC9 exemption"]}
  },
  UN3083: {
    hazard_class_division: 2.3,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3084: {
    hazard_class_division: 8,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3085: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3086: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3087: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3088: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3089: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3090: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3091: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3092: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3093: {
    hazard_class_division: 8,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3094: {
    hazard_class_division: 8,
    subrisk: ["4.3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3095: {
    hazard_class_division: 8,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3096: {
    hazard_class_division: 8,
    subrisk: ["4.3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3097: {
    hazard_class_division: 4.1,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3098: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3099: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3100: {
    hazard_class_division: 5.1,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3101: {
    hazard_class_division: 5.2,
    subrisk: ["1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3102: {
    hazard_class_division: 5.2,
    subrisk: ["1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3103: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3104: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3105: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3106: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3107: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3108: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3109: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3110: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3111: {
    hazard_class_division: 5.2,
    subrisk: ["1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3112: {
    hazard_class_division: 5.2,
    subrisk: ["1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3113: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3114: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3115: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3116: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3117: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3118: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3119: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3120: {
    hazard_class_division: 5.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3121: {
    hazard_class_division: 5.1,
    subrisk: ["4.3"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3122: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3123: {
    hazard_class_division: 6.1,
    subrisk: ["4.3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3124: {
    hazard_class_division: 6.1,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3125: {
    hazard_class_division: 6.1,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3126: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3127: {
    hazard_class_division: 4.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3128: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3129: {
    hazard_class_division: 4.3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3130: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3131: {
    hazard_class_division: 4.3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3132: {
    hazard_class_division: 4.3,
    subrisk: ["4.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3133: {
    hazard_class_division: 4.3,
    subrisk: ["5.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3134: {
    hazard_class_division: 4.3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3135: {
    hazard_class_division: 4.3,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3136: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3137: {
    hazard_class_division: 5.1,
    subrisk: ["4.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3138: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3139: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3140: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3141: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3142: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3143: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3144: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3145: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3146: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3147: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3148: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3149: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3150: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3151: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3152: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3153: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3154: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3155: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3156: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3157: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3158: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3159: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3160: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3161: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3162: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3163: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3164: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3165: {
    hazard_class_division: 3,
    subrisk: ["6.1", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3166: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3167: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3168: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3169: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3170: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3171: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3172: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3174: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3175: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3176: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3178: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3179: {
    hazard_class_division: 4.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3180: {
    hazard_class_division: 4.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3181: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3182: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3183: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3184: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3185: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3186: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3187: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3188: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3189: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3190: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3191: {
    hazard_class_division: 4.2,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3192: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3194: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3200: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3205: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3206: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3208: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3209: {
    hazard_class_division: 4.3,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3210: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3211: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3212: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3213: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3214: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3215: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3216: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3218: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3219: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3220: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3221: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3222: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3223: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3224: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3225: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3226: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3227: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3228: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3229: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3230: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3231: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3232: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3233: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3234: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3235: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3236: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3237: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3238: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3239: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3240: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3241: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3242: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3243: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3244: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3246: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3247: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3248: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3249: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3250: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3251: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3252: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3253: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3254: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3255: {
    hazard_class_division: 4.2,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3256: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3257: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3258: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3259: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3260: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3261: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3262: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3263: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3264: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3265: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3266: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3267: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3268: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3269: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3270: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3271: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3272: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3273: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3274: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3275: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3276: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3277: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3278: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3279: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3280: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3281: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3282: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3283: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3284: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3285: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3286: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3287: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3288: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3289: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3290: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3291: {
    hazard_class_division: 6.2,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3292: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3293: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3294: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3295: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3296: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3297: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3298: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3299: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3300: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3301: {
    hazard_class_division: 8,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3302: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3303: {
    hazard_class_division: 2.3,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3304: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3305: {
    hazard_class_division: 2.3,
    subrisk: ["2.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3306: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3307: {
    hazard_class_division: 2.3,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3308: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3309: {
    hazard_class_division: 2.3,
    subrisk: ["2.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3310: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3311: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3312: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3313: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3314: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3316: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3317: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3318: {
    hazard_class_division: null,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3319: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3320: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3321: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3322: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3327: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3328: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3329: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3331: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3332: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3333: {
    hazard_class_division: 7,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3334: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3335: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3336: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3337: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3338: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3339: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3340: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3341: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3342: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3343: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3344: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3345: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3346: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3347: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3348: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3349: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3350: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3351: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3352: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3354: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3355: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3356: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3357: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3358: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3360: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3361: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3362: {
    hazard_class_division: 6.1,
    subrisk: ["8", "3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3363: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3364: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3365: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3366: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3367: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3368: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3369: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3370: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3371: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3373: {
    hazard_class_division: 6.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3375: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3376: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3377: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3378: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3379: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3380: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3381: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3382: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3383: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3384: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3385: {
    hazard_class_division: 6.1,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3386: {
    hazard_class_division: 6.1,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3387: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3388: {
    hazard_class_division: 6.1,
    subrisk: ["5.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3389: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3390: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3391: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3392: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3393: {
    hazard_class_division: 4.2,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3394: {
    hazard_class_division: 4.2,
    subrisk: ["4.3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3395: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3396: {
    hazard_class_division: 4.3,
    subrisk: ["4.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3397: {
    hazard_class_division: 4.3,
    subrisk: ["4.2"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3398: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3399: {
    hazard_class_division: 4.3,
    subrisk: ["3"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3400: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3401: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3402: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3403: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3404: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3405: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3406: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3407: {
    hazard_class_division: 5.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3408: {
    hazard_class_division: 5.1,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3409: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3410: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3411: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3412: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3413: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3414: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3415: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3416: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3417: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3418: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3419: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3420: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3421: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3422: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3423: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3424: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3425: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3426: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3427: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3428: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3429: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3430: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3431: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3432: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3434: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3436: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3437: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3438: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3439: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3440: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3441: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3442: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3443: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3444: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3445: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3446: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3447: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3448: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3449: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3450: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3451: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3452: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3453: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3454: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3455: {
    hazard_class_division: 6.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3456: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3457: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3458: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3459: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3460: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3462: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3463: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3464: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3465: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3466: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3467: {
    hazard_class_division: 6.1,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3468: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3469: {
    hazard_class_division: 3,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3470: {
    hazard_class_division: 8,
    subrisk: ["3"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3471: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3472: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3473: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3474: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3475: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3476: {
    hazard_class_division: 4.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3477: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3478: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3479: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3480: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3481: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3482: {
    hazard_class_division: 4.3,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3483: {
    hazard_class_division: 6.1,
    subrisk: ["3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3484: {
    hazard_class_division: 8,
    subrisk: ["3", "6.1"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3485: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3486: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3487: {
    hazard_class_division: 5.1,
    subrisk: ["8"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3488: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3489: {
    hazard_class_division: 6.1,
    subrisk: ["3", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3490: {
    hazard_class_division: 6.1,
    subrisk: ["4.3", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3491: {
    hazard_class_division: 6.1,
    subrisk: ["4.3", "3"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3494: {
    hazard_class_division: 3,
    subrisk: ["6.1"],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3495: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3496: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3497: {
    hazard_class_division: 4.2,
    subrisk: [None.id],
    packing_group: null,
    compatibility_group: NotApplicable.id
  },
  UN3498: {
    hazard_class_division: 8,
    subrisk: [None.id],
    packing_group: "II",
    compatibility_group: NotApplicable.id
  },
  UN3499: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3500: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3501: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3502: {
    hazard_class_division: 2.2,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3503: {
    hazard_class_division: 2.2,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3504: {
    hazard_class_division: 2.1,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3505: {
    hazard_class_division: 2.1,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3506: {
    hazard_class_division: 8,
    subrisk: ["6.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3507: {
    hazard_class_division: 6.1,
    subrisk: ["7", "8"],
    packing_group: "I",
    compatibility_group: NotApplicable.id
  },
  UN3508: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3510: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3511: {
    hazard_class_division: 2.2,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3512: {
    hazard_class_division: 2.3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3513: {
    hazard_class_division: 2.2,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3514: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3515: {
    hazard_class_division: 2.3,
    subrisk: ["5.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3516: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3517: {
    hazard_class_division: 2.3,
    subrisk: ["2.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3518: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3519: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3520: {
    hazard_class_division: 2.3,
    subrisk: ["5.1", "8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3521: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3522: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3523: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3524: {
    hazard_class_division: 2.3,
    subrisk: ["8"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3525: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3526: {
    hazard_class_division: 2.3,
    subrisk: ["2.1"],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3527: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3528: {
    hazard_class_division: 3,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3529: {
    hazard_class_division: 2.1,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3530: {
    hazard_class_division: 9,
    subrisk: [None.id],
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id
  },
  UN3531: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3532: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3533: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  },
  UN3534: {
    hazard_class_division: 4.1,
    subrisk: [None.id],
    packing_group: "III",
    compatibility_group: NotApplicable.id
  }
}
