import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  symbols = [
    { value: 'no_symbol', text: 'No Symbol (or =)' },
    { value: '<', text: '< (or “less than”)' },
    { value: '<=', text: '<=' },
    { value: '>', text: '> (or “greater than”)' },
    { value: '>=', text: '>=' }
  ];

  symbols_degrees = [
    { value: 'no_symbol', text: 'No Symbol (or =)' },
    { value: '>', text: '> (or “greater than”)' }
  ];

  symbols_min = [
    { value: '>', text: '> (or “greater than”)' },
    { value: '>=', text: '>=' }
  ];

  symbols_max = [
    { value: '<', text: '< (or “less than”)' },
    { value: '<=', text: '<=' }
  ];

  units = [
    { value: 'fahrenheit', text: 'Fahrenheit (F)' },
    { value: 'celsius', text: 'Celsius (C)' },
    { value: 'not_given', text: 'Not given' }
  ];

  otherArray = [{ name: "Other", keyword: "OTHER" }];
  intended_use_categories = [
    {
      name: "Antifreeze/Coolant",
      keyword: "ANTIFREEZE",
      subcategories: this.otherArray
    },
    {
      name: "Batteries",
      keyword: "BATTERY",
      subcategories: [
        { name: "Alkaline", keyword: "ALKALINE" },
        { name: "Lead Acid", keyword: "LEAD_ACID" },
        { name: "Lithium Ion Polymer", keyword: "LITHIUM_ION" },
        { name: "Lithium Manganese Dioxide", keyword: "LITHIUM_MANGANESE_DIOXIDE" },
        { name: "Lithium Metal", keyword: "LITHIUM_METAL" },
        { name: "Nickel Cadmium", keyword: "NICKEL_CADMIUM" },
        { name: "Nickel Metal Hydride", keyword: "NICKEL_METAL_HYDRIDE" },
        { name: "Other", keyword: "OTHER" },
        { name: "Silver Oxide", keyword: "SILVER_OXIDE" },
        { name: "Zinc Air", keyword: "ZINC_AIR" },
        { name: "Zinc Carbon", keyword: "ZINC_CARBON" }
      ]
    },
    {
      name: "Building/Construction, Tools And Hardware",
      keyword: "CONSTRUCTION",
      subcategories: this.otherArray
    },
    {
      name: "Capacitors",
      keyword: "CAPACITOR",
      subcategories: this.otherArray
    },
    {
      name: "Cleaning Product",
      keyword: "CLEANER",
      subcategories: [
        { name: "Bleach", keyword: "BLEACH" },
        { name: "Disinfectant", keyword: "DISINFECTANT" },
        { name: "Other", keyword: "OTHER" },
        { name: "Soaps", keyword: "SOAP_AND_DETERGENT" }
      ]
    },
    {
      name: "Cleaning Wipes/Personal Care Wipes",
      keyword: "WIPES",
      subcategories: this.otherArray
    },
    {
      name: "Consumables",
      keyword: "CONSUMABLE",
      subcategories: [
        { name: "Beer", keyword: "BEER" },
        { name: "Cooking Oil", keyword: "COOKING_OIL" },
        { name: "Extracts/Seasonings/Flavour Enhancers (Shelf Stable)", keyword: "EXTRACT_AND_SEASONING" },
        { name: "Food", keyword: "FOOD" },
        { name: "Medicine", keyword: "MEDICINE" },
        { name: "Other", keyword: "OTHER" },
        { name: "Spirits", keyword: "SPIRITS" },
        { name: "Wine", keyword: "WINE" }
      ]
    },
    {
      name: "Detonators",
      keyword: "DETONATOR",
      subcategories: this.otherArray
    },
    {
      name: "Electronics",
      keyword: "ELECTRONICS",
      subcategories: this.otherArray
    },
    {
      name: "Engines",
      keyword: "ENGINE",
      subcategories: this.otherArray
    },
    {
      name: "Explosives",
      keyword: "EXPLOSIVE",
      subcategories: [
        { name: "Article", keyword: "ARTICLE" },
        { name: "Powder", keyword: "POWDER" },
        { name: "Other", keyword: "OTHER" }
      ]
    },
    {
      name: "Fertilizer",
      keyword: "FERTILIZER",
      subcategories: this.otherArray
    },
    {
      name: "Fire Extinguishers",
      keyword: "FIRE_EXTINGUISHER",
      subcategories: this.otherArray
    },
    {
      name: "Firelighting Tools/Matches",
      keyword: "FIRE_STARTER",
      subcategories: [
        { name: "Charcoal", keyword: "CHARCOAL" },
        { name: "Lighters", keyword: "LIGHTERS" },
        { name: "Lighter Fluid", keyword: "LIGHTER_FLUID" },
        { name: "Matches", keyword: "MATCHES" },
        { name: "Other", keyword: "OTHER" },
        { name: "Solid Fire Starter", keyword: "SOLID_FIRE_STARTER" }
      ]
    },
    {
      name: "Fireworks/Flares/Signals",
      keyword: "FIREWORKS_AND_FLARES",
      subcategories: [
        { name: "Fireworks", keyword: "FIREWORKS" },
        { name: "Flares", keyword: "FLARES" },
        { name: "Other", keyword: "OTHER" },
        { name: "Signals", keyword: "SIGNALS" }
      ]
    },
    {
      name: "Glue/Adhesives/Resins",
      keyword: "ADHESIVE",
      subcategories: this.otherArray
    },
    {
      name: "Inflatables",
      keyword: "INFLATABLE",
      subcategories: this.otherArray
    },
    {
      name: "Ink",
      keyword: "INK",
      subcategories: [
        { name: "Pen", keyword: "PEN" },
        { name: "Printer Ink/Toner", keyword: "PRINTER_INK_AND_TONER" },
        { name: "Other", keyword: "OTHER" }
      ]
    },
    {
      name: "Lightbulbs",
      keyword: "LIGHTBULB",
      subcategories: [
        { name: "Cfl", keyword: "CFL" },
        { name: "Fluorescent", keyword: "FLUORESCENT" },
        { name: "Halogen Lamp", keyword: "HALOGEN_LAMP" },
        { name: "Incandescent", keyword: "INCANDESCENT" },
        { name: "Infrared Lamp", keyword: "INFRARED_LAMP" },
        { name: "Led", keyword: "LED" },
        { name: "Metal Halide Lamp", keyword: "METAL_HALIDE_LAMP" },
        { name: "Other", keyword: "OTHER" },
        { name: "Sodium Vapor Lamp", keyword: "SODIUM_VAPOR_LAMP" }
      ]
    },
    {
      name: "Lubricant",
      keyword: "LUBRICANT",
      subcategories: this.otherArray
    },
    {
      name: "Motor Oil/Fuel/Fuel Additive",
      keyword: "OIL_AND_FUEL",
      subcategories: [
        { name: "Fuel", keyword: "FUEL" },
        { name: "Fuel Treatment/Additive", keyword: "FUEL_ADDITIVE" },
        { name: "Motor Oil", keyword: "MOTOR_OIL" },
        { name: "Other", keyword: "OTHER" }
      ]
    },
    {
      name: "Nicotine/Tobacco",
      keyword: "NICOTINE_AND_TOBACCO",
      subcategories: [
        { name: "Tobacco", keyword: "TOBACCO" },
        { name: "Nicotine Cessation", keyword: "NICOTINE_CESSATION" },
        { name: "Other", keyword: "OTHER" }
      ]
    },
    {
      name: "Odorizers",
      keyword: "ODORIZER",
      subcategories: this.otherArray
    },
    {
      name: "Other",
      keyword: "OTHER",
      subcategories: this.otherArray
    },
    {
      name: "Paint/Paint Related",
      keyword: "PAINT_RELATED",
      subcategories: this.otherArray
    },
    {
      name: "Personal Care Items/Cosmetics",
      keyword: "PERSONAL_CARE",
      subcategories: [
        { name: "First Aid ", keyword: "FIRST_AID" },
        { name: "Fragrance", keyword: "FRAGRANCE" },
        { name: "Hair Care", keyword: "HAIR_CARE" },
        { name: "Hair Dye/Coloring Products", keyword: "HAIR_DYE_AND_COLORING" },
        { name: "Nail Care", keyword: "NAIL_CARE" },
        { name: "Other", keyword: "OTHER" },
        { name: "Personal Cleanliness Products", keyword: "PERSONAL_CLEANLINESS" }
      ]
    },
    {
      name: "Poisons:Insecticides/Pesticides/Rodenticides",
      keyword: "POISON",
      subcategories: this.otherArray
    },
    {
      name: "Refrigerating Gas/Freezing Appliances",
      keyword: "REFRIGERANT",
      subcategories: [
        { name: "Refrigerating Appliances", keyword: "REFRIGERATING_APPLIANCE" },
        { name: "Refrigerating Gas", keyword: "REFRIGERATING_GAS" },
        { name: "Other", keyword: "OTHER" }
      ]
    },
    {
      name: "Rocket Related",
      keyword: "ROCKET_RELATED",
      subcategories: this.otherArray
    },
    {
      name: "Sporting Goods/Self Defense",
      keyword: "SELF_DEFENSE",
      subcategories: [
        { name: "Firearms Ammunition/Cartridges/Artillery", keyword: "FIREARMS_AND_AMMUNITION" },
        { name: "Other", keyword: "OTHER" },
        { name: "Pepper Spray", keyword: "PEPPER_SPRAY" }
      ]
    },
    {
      name: "Swimming Pool Products",
      keyword: "POOL_PRODUCT",
      subcategories: this.otherArray
    },
    {
      name: "Windshield Washer Fluid",
      keyword: "WINDSHIELD_WASHER_FLUID",
      subcategories: this.otherArray
    }
  ];

  constructor() { }

  public createFindProduct(form: FormGroup) {
    let request: any;

    if (form.controls.whatIsneeded.value === 'none' && form.controls.sds_url.value === '') {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        sds_revision_date: form.controls.revision_date.value
      };
    } else if (form.controls.whatIsneeded.value === 'product_label_url') {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        product_label_url: form.controls.product_label_url.value,
        sds_revision_date: form.controls.revision_date.value
      };
    } else {
      request = {
        what_is_needed: form.controls.whatIsneeded.value,
        sds_url: form.controls.sds_url.value,
        sds_revision_date: form.controls.revision_date.value
      };
    }
    return request;
  }

  public createFlashPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createBolingPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createMeltingPoint(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      symbol: form.controls.symbol.value,
      // symbol_min: form.controls.symbol_min.value,
      // symbol_max: form.controls.symbol_max.value,
      value: form.controls.value.value,
      // value_min: form.controls.value_min.value,
      // value_max: form.controls.value_max.value,
      unit: form.controls.unit.value
    };
    return request;
  }

  public createPh(form: FormGroup) {
    const request: any = {
      format_input: form.controls.format.value,
      ph_value: form.controls.ph_value.value,
      ph_min_value: form.controls.ph_min_value.value,
      ph_max_value: form.controls.ph_max_value.value,
      symbol_min: form.controls.symbol_min.value,
      symbol_max: form.controls.symbol_max.value,
      symbol: form.controls.symbol.value
    };
    return request;
  }

  public createIntendedUse(form: FormGroup, userExpert) {
    let request: any;
    if (userExpert) {
      request = {
        intended_use: form.controls.intended_use.value.name,
        intended_use_subCategory: form.controls.intended_use_subCategory.value.name
      };
    } else {
      if (form.controls.intended_use_subCategory.value && !userExpert) {
        request = {
          recommended_use: form.controls.recommended_use.value,
          intended_use: form.controls.intended_use.value.keyword,
          intended_use_subcategory: form.controls.intended_use_subCategory.value.keyword
        };
      } else {
        request = {
          recommended_use: form.controls.recommended_use.value,
          intended_use: form.controls.intended_use.value.keyword
        };
      }
    }
    return request;
  }

  public createIship(form: FormGroup) {
    const f = form.controls;

    const defaultNotFoundText = 'Could Not Find';

    const dotHazard = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_division')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_division.value
      : (f.dot_grp as FormGroup).controls.dot_hazard_class.value;
    const iataHazard = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_division')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_division.value
      : (f.iata_grp as FormGroup).controls.iata_hazard_class.value;
    const imdgHazard = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_division')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_division.value
      : (f.imdg_grp as FormGroup).controls.imdg_hazard_class.value;

    const dotSubHazard = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_subhazard_division')
      ? (f.dot_grp as FormGroup).controls.dot_subhazard_division.value
      : (f.dot_grp as FormGroup).controls.dot_subhazard_class.value;
    const iataSubHazard = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_subhazard_division')
      ? (f.iata_grp as FormGroup).controls.iata_subhazard_division.value
      : (f.iata_grp as FormGroup).controls.iata_subhazard_class.value;
    const imdgSubHazard = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_subhazard_division')
      ? (f.imdg_grp as FormGroup).controls.imdg_subhazard_division.value
      : (f.imdg_grp as FormGroup).controls.imdg_subhazard_class.value;

    const dotCompatibility = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_compatibility')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_compatibility.value
      : defaultNotFoundText;
    const iataCompatibility = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_compatibility')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_compatibility.value
      : defaultNotFoundText;
    const imdgCompatibility = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_compatibility')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_compatibility.value
      : defaultNotFoundText;

    const dotPackingGroup = this.checkUnValueExists((f.dot_grp as FormGroup).controls, 'dot_hazard_packing_group')
      ? (f.dot_grp as FormGroup).controls.dot_hazard_packing_group.value
      : defaultNotFoundText;
    const iataPackingGroup = this.checkUnValueExists((f.iata_grp as FormGroup).controls, 'iata_hazard_packing_group')
      ? (f.iata_grp as FormGroup).controls.iata_hazard_packing_group.value
      : defaultNotFoundText;
    const imdgPackingGroup = this.checkUnValueExists((f.imdg_grp as FormGroup).controls, 'imdg_hazard_packing_group')
      ? (f.imdg_grp as FormGroup).controls.imdg_hazard_packing_group.value
      : defaultNotFoundText;

    const request: any = {
      dot: {
        hazard: dotHazard,
        subhazard: dotSubHazard,
        compatibility: dotCompatibility,
        packing_group: this.checkPackingValue(dotHazard, dotPackingGroup),
        proper_shipping_name: form.value.un_dot_grp.dot_proper_shipping_name
          ? form.value.un_dot_grp.dot_proper_shipping_name
          : defaultNotFoundText,
        un: form.value.un_dot_grp.un_dot.id
      },
      iata: {
        hazard: iataHazard,
        subhazard: iataSubHazard,
        compatibility: iataCompatibility,
        packing_group: this.checkPackingValue(iataHazard, iataPackingGroup),
        proper_shipping_name: form.value.un_iata_grp.iata_proper_shipping_name
          ? form.value.un_iata_grp.iata_proper_shipping_name
          : defaultNotFoundText,
        un: form.value.un_iata_grp.un_iata.id
      },
      imdg: {
        hazard: imdgHazard,
        subhazard: imdgSubHazard,
        compatibility: imdgCompatibility,
        packing_group: this.checkPackingValue(imdgHazard, imdgPackingGroup),
        proper_shipping_name: form.value.un_imdg_grp.imdg_proper_shipping_name
          ? form.value.un_imdg_grp.imdg_proper_shipping_name
          : defaultNotFoundText,
        un: form.value.un_imdg_grp.un_imdg.id
      }
    };

    return request;
  }

  private checkUnValueExists(formControls, formControl): boolean {
    if (formControls[formControl]) {
      if (formControls[formControl].value && formControls[formControl].value !== 'Not Restricted') {
        return true;
      }
    }
    return false;
  }

  private checkPackingValue(hazardClassValue, packingGroupValue) {
    const notRestrictedText = 'Not Restricted';
    return (hazardClassValue === notRestrictedText ? notRestrictedText : packingGroupValue);
  }

  public createValidateSdsPairing(form: FormGroup) {
    const request = {
      is_pairing_valid: form.controls.is_pairing_valid.value,
      pdf_is: form.controls.pdf_is.value
    };
    if (form.controls.is_pairing_valid.value === 'no') {
      request['invalid_pairing_reason'] = form.controls.invalid_pairing_reason.value;
      if (form.controls.invalid_pairing_reason.value === 'other') {
        request['comment'] = form.controls.comment.value;
      }
    } else {
      request['quality'] = form.controls.quality.value;
    }
    if (form.controls.what_is_needed.value !== '') {
      request['what_is_needed'] = form.controls.what_is_needed.value;
    }
    if (form.controls.revision_date.value) {
      request['sds_revision_date'] = form.controls.revision_date.value;
    }
    return request;
  }

  public createUnNumber(form: FormGroup) {
    const request = {
      un_dot: form.value.un_dot_grp.un_dot,
      un_iata: form.value.un_iata_grp.un_iata,
      un_imdg: form.value.un_imdg_grp.un_imdg,
      dot_proper_shipping_name: form.value.un_dot_grp.dot_proper_shipping_name,
      iata_proper_shipping_name: form.value.un_iata_grp.iata_proper_shipping_name,
      imdg_proper_shipping_name: form.value.un_imdg_grp.imdg_proper_shipping_name
    };
    if (!request.dot_proper_shipping_name) {
      request.dot_proper_shipping_name = 'Could Not Find';
    }
    if (!request.iata_proper_shipping_name) {
      request.iata_proper_shipping_name = 'Could Not Find';
    }
    if (!request.imdg_proper_shipping_name) {
      request.imdg_proper_shipping_name = 'Could Not Find';
    }
    return request;
  }

  public createHazard(form: FormGroup) {
    const f = form.controls;
    let dotHazard;
    let iataHazard;
    let imdgHazard;
    let dotSubHazard;
    let iataSubHazard;
    let imdgSubHazard;
    let dotCompatibility;
    let iataCompatibility;
    let imdgCompatibility;
    let dotPackingGroup;
    let iataPackingGroup;
    let imdgPackingGroup;

    const defaultNotFoundText = 'Could Not Find';

    dotHazard = this.checkUnValueExists(f, 'dot_hazard_division')
      ? f.dot_hazard_division.value
      : f.dot_hazard_class.value;
    iataHazard = this.checkUnValueExists(f, 'iata_hazard_division')
      ? f.iata_hazard_division.value
      : f.iata_hazard_class.value;
    imdgHazard = this.checkUnValueExists(f, 'imdg_hazard_division')
      ? f.imdg_hazard_division.value
      : f.imdg_hazard_class.value;

    dotSubHazard = this.checkUnValueExists(f, 'dot_subhazard_division')
      ? f.dot_subhazard_division.value
      : f.dot_subhazard_class.value;
    iataSubHazard = this.checkUnValueExists(f, 'iata_subhazard_division')
      ? f.iata_subhazard_division.value
      : f.iata_subhazard_class.value;
    imdgSubHazard = this.checkUnValueExists(f, 'imdg_subhazard_division')
      ? f.imdg_subhazard_division.value
      : f.imdg_subhazard_class.value;

    dotCompatibility = this.checkUnValueExists(f, 'dot_hazard_compatibility')
      ? f.dot_hazard_compatibility.value
      : defaultNotFoundText;
    iataCompatibility = this.checkUnValueExists(f, 'iata_hazard_compatibility')
      ? f.iata_hazard_compatibility.value
      : defaultNotFoundText;
    imdgCompatibility = this.checkUnValueExists(f, 'imdg_hazard_compatibility')
      ? f.imdg_hazard_compatibility.value
      : defaultNotFoundText;

    dotPackingGroup = this.checkUnValueExists(f, 'dot_hazard_packing_group')
      ? f.dot_hazard_packing_group.value
      : defaultNotFoundText;
    iataPackingGroup = this.checkUnValueExists(f, 'iata_hazard_packing_group')
      ? f.iata_hazard_packing_group.value
      : defaultNotFoundText;
    imdgPackingGroup = this.checkUnValueExists(f, 'imdg_hazard_packing_group')
      ? f.imdg_hazard_packing_group.value
      : defaultNotFoundText;

    const request: any = {
      dot: {
        hazard: dotHazard,
        sub_hazard: dotSubHazard,
        compatibility: dotCompatibility,
        packing_group: dotPackingGroup
      },
      iata: {
        hazard: iataHazard,
        sub_hazard: iataSubHazard,
        compatibility: iataCompatibility,
        packing_group: iataPackingGroup
      },
      imdg: {
        hazard: imdgHazard,
        sub_hazard: imdgSubHazard,
        compatibility: imdgCompatibility,
        packing_group: imdgPackingGroup
      }
    };
    return request;
  }
}
