import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})

export class ExternalTaskService {
  constructor(private httpClient: HttpClient) { }

  list(page: number): any {
    return this.httpClient.get(`${environment.apiUrl}external_tasks?page=${page}`);
  }
}
