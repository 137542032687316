import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nfpa',
  templateUrl: './nfpa.component.html',
  styleUrls: ['./nfpa.component.less']
})

// TODO: extend BaseComponent so component works as a standalone PACE task
export class NfpaComponent implements OnInit {

  form: FormGroup;

  codes: {};
  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private expertService: ExpertService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      nfpa_health: ['', Validators.required],
      nfpa_fire: ['', Validators.required],
      nfpa_instability: ['', Validators.required],
      nfpa_special: ['', Validators.required]
    });

    this.codeSubscription = this.expertService.getCodes('other', 'nfpa').subscribe((value) => {
      this.codes = value || {};
    });

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('other', 'nfpa').subscribe((nfpaValues) => {
      this.expertService.disableReadOnlyForm(this.form);
      nfpaValues = nfpaValues || {};
      Reflect.ownKeys(nfpaValues).forEach(value => {
        this.f[value.toString()].setValue(nfpaValues[value] || null);
      });
    });
  }

  onChange(type: string) {
    this.expertService.setCode(this.f[type].value, 'other', 'nfpa', type);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
