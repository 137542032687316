import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportService } from '../../../../shared/services/report/report.service';
import { timezones } from '../../../../constants/timezone';
import * as moment from 'moment-timezone';

interface SupervisorItemData {
  sn: number;
  date: string;
  facet: string;
  userArray: UserArray[];
  average: number;
}

interface UserArray {
  name: string;
  result: string;
}

@Component({
  selector: 'app-supervisor-report-by-task',
  templateUrl: './supervisor-report-by-task.component.html',
  styleUrls: ['./supervisor-report-by-task.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SupervisorReportByTaskComponent implements OnInit {
  private userData: string[] = [];
  public listOfAgentData: SupervisorItemData[] = [];
  public userNameList: string[] = [];
  public userList: string[] = [];
  public facetNameList: string[] = [];
  public facetList: string[] = [];
  public facetArray = [];
  private dateList: string[] = [];
  private weekStartDate: string;
  private weekEndDate: string;
  private yearStartDate: string;
  private yearEndDate: string;
  public range: string[] = [];
  public dateRange = [];
  public averageTableData = [];
  public averageYearData = [];
  public selectedTimezone = null;
  public timezoneArray = timezones;
  public selectedFacet = null;
  public selectedAverageFacet = null;

  private averageTableDictionary = {
    week_all_agent_report: 'Average Week-toDate',
    month_all_agent_report: 'Average Month-toDate'
  };

  constructor(private api: ReportService) { }

  ngOnInit() {
    this.selectedTimezone = 'Asia/Manila';
    this.getDateForWeek();
    this.getDateForYear();
    this.assignInitialDateRange();
    this.retrieveByYear(this.yearStartDate, this.yearEndDate, this.selectedTimezone);
    this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
  }

  public timezoneChange(timezone) {
    this.selectedTimezone = timezone;
    this.getDateForWeek();
    this.getDateForYear();
    this.assignInitialDateRange();
    this.retrieveByYear(this.yearStartDate, this.yearEndDate, this.selectedTimezone);
    this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
  }

  public setDefaultTimeZone() {
    return this.selectedTimezone == null ? this.selectedTimezone = 'Asia/Manila' : this.selectedTimezone
  }

  private getDateForWeek() {
    this.weekStartDate = this.getStartDate(7);
    this.weekEndDate = this.getEndDate(1);
  }

  private getDateForYear() {
    this.yearStartDate = this.getStartDate(366);
    this.yearEndDate = this.getEndDate(1);
  }

  private getStartDate(interval: number) {
    return moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(interval, 'days')
      .startOf('day')
      .toISOString();
  }

  private getEndDate(interval: number) {
    return moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(interval, 'days')
      .endOf('day')
      .toISOString();
  }

  public onRangeSelect(result: Date): void {
    const range1 = (moment(result[0]).format()).split('T');
    const range2 = (moment(result[1]).format()).split('T');
    const startDateRange = moment.tz(range1[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDateRange = moment.tz(range2[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    this.retrieveResults(startDateRange, endDateRange, this.selectedTimezone);
    this.facetFilterReset();
  }

  public facetFilterReset() {
    this.facetArray = [];
    this.selectedFacet = null;
  }

  public facetFilterChange(facet) {
    this.selectedFacet = facet;
    this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
  }

  public facetFilterAverageChange(facet) {
    this.selectedAverageFacet = facet;
    this.retrieveByYear(this.yearStartDate, this.yearEndDate, this.selectedTimezone);
  }
  public changeDateFormat(dateTime: string) {
    const date = dateTime.split('T')[0];
    const dates = date.split('-');
    const formattedDate = [dates[1], dates[2], dates[0].substr(0, 2)].join("/");
    return formattedDate;
  }

  private assignInitialDateRange() {
    const weekStartCalendar = this.splitTime(moment.tz(this.weekStartDate, this.setDefaultTimeZone()).format());
    const weekEndCalendar = this.splitTime(moment.tz(this.weekEndDate, this.setDefaultTimeZone()).format());
    const timeZone = moment.tz.guess();
    this.dateRange = [
      moment(this.splitTime(weekStartCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS'),
      moment(this.splitTime(weekEndCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS')
    ];
  }

  private splitTime(date: string) {
    if (date) {
      return date.split('T')[0];
    }
    return date;
  }

  private generateUserArray(result) {
    const userArray = [];
    Object.values(result).forEach(element => {
      if (!userArray.includes(element['full_name'])) {
        userArray.push(element['full_name']);
      }
    });
    return userArray;
  }

  private generateDateArray() {
    const dateArray = [];
    Object.values(this.userData).forEach(element => {
      if (!dateArray.includes(element['date'])) {
        dateArray.push(element['date']);
      }
    });
    return dateArray;
  }

  private generateTotalFacetList(result) {
    const facetArray = [];
    result.forEach(element => {
      if (!facetArray.includes(element['facet'])) {
        facetArray.push(element['facet']);
      }
    });
    return facetArray;
  }

  private generateFacetArray(date: string) {
    const tempArray = [];
    this.userData.forEach(element => {
      if (this.selectedFacet === null || typeof this.selectedFacet === 'undefined') {
        if (element['date'] === date && element['facet']) {
          if (!tempArray.includes(element['facet'])) {
            tempArray.push(element['facet']);
          }
        }
      } else {
        const facet = this.selectedFacet;
        if (element['date'] === date && element['facet'] === facet) {
          if (!tempArray.includes(facet)) {
            tempArray.push(facet);
          }
        }
      }
    });
    return tempArray;
  }

  public getRowSpan(date: string) {
    return this.generateFacetArray(date).length;
  }

  public getAverageRowSpan() {
    return this.facetNameList.length === 0 ? 1 : this.facetNameList.length;
  }

  public getUserCount(name: string, userIndex: UserArray[]) {
    const value = userIndex.filter(key => {
      if (key.name === name) {
        return key.result;
      }
    });
    return value[0] ? value[0].result : '-';
  }

  private calculateAverageResultDate(average) {
    const averageResult = average / this.userNameList.length;
    return averageResult;
  }

  private calculateAverageResult(average) {
    const averageResult = average / this.userList.length;
    return averageResult;
  }
  public retrieveByYear(startDate: string, endDate: string, timeZone: string) {
    this.api.getByDateAll(startDate, endDate, timeZone, true).subscribe(res => {
      if (res) {
        this.facetNameList = this.generateTotalFacetList(res);
        this.facetList = this.facetNameList;
        this.userList = this.generateUserArray(res);
        let serialNumber = 0;
        const resultsArray = [];
        if (this.facetNameList.length) {
          this.facetNameList.forEach(facet => {
            const averageData = [];
            let average = 0;
            if (this.selectedAverageFacet === facet || this.selectedAverageFacet == null) {
              if (this.selectedAverageFacet) {
                this.facetNameList = [facet];
              }
              res.forEach(keys => {
                if (keys.facet === facet) {
                  averageData.push({
                    name: keys.full_name,
                    result: keys.count
                  });
                  average += keys.count;
                }
              });
              resultsArray.push({
                sn: ++serialNumber,
                facet,
                value: averageData,
                average: this.calculateAverageResult(average).toFixed(3)
              });
            }
          });
        } else {
          resultsArray.push({
            sn: ++serialNumber,
            facet: '-',
            value: ['-'],
            average: '-'
          });
        }
        this.averageYearData = resultsArray;
        this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
        this.retrieveAverageResult(this.selectedTimezone);
      }
    });
  }

  public retrieveResults(startDate: string, endDate: string, timeZone: string) {
    this.api.getByDateAll(startDate, endDate, timeZone, true).subscribe(
      res => {
        if (res) {
          this.userData = res;
          this.userNameList = this.generateUserArray(res);
          this.dateList = this.generateDateArray();
          const resultsArray = [];
          this.dateList.forEach(date => {
            const facetArray = this.generateFacetArray(date);
            let serialNumber = 0;
            facetArray.forEach(facet => {
              const userList = [];
              let average = 0;
              res.forEach(key => {
                if (key.date === date && key.facet === facet) {
                  userList.push({
                    name: key.full_name,
                    result: key.count
                  });
                  average += key.count;
                }
              });
              resultsArray.push({
                sn: ++serialNumber,
                date,
                facet,
                userArray: userList,
                average: this.calculateAverageResultDate(average).toFixed(3)
              });

              if (!this.facetArray.includes(facet)) {
                this.facetArray.push(facet);
              }
            });
          });
          this.listOfAgentData = resultsArray;
        }
      },
      error => console.log(error)
    );
  }

  public retrieveAverageResult(timeZone: string) {
    this.api.retrieveAvgCompletionData(timeZone).subscribe(
      res => {
        if (res) {
          const resultsArray = [];
          Object.keys(res).forEach(key => {
            if (this.averageTableDictionary.hasOwnProperty(key)) {
              let serialNumber = 0;
              if (this.facetNameList.length > 0) {
                this.facetNameList.forEach(facet => {
                  const averageData = [];
                  let average = 0;
                  if (this.selectedAverageFacet == facet || this.selectedAverageFacet == null) {
                    res[key].forEach(keys => {
                      if (keys.facet === facet) {
                        averageData.push({
                          name: keys.full_name,
                          result: keys.count
                        });
                        average += keys.count;
                      }
                    });
                    resultsArray.push({
                      sn: ++serialNumber,
                      title: this.averageTableDictionary[key],
                      facet,
                      value: averageData,
                      average: this.calculateAverageResult(average).toFixed(3)
                    });
                  }
                });
              } else {
                resultsArray.push({
                  sn: ++serialNumber,
                  title: this.averageTableDictionary[key],
                  facet: '-',
                  value: ['-'],
                  average: '-'
                });
              }
            }
          });
          this.averageTableData = resultsArray;
        }
      },
      error => console.log(error)
    );
  }
}
