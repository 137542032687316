import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PsnService {

  constructor() { }

  getPsnFromUnCode(unCodeObject: object) {
    return PsnService.getPsnFromUnCode(unCodeObject);
  }

  // NOTE: expert-state service needs access to a static method
  static getPsnFromUnCode(unCodeObject: object) {
    if (!unCodeObject || !unCodeObject["name"]) return;

    const unDescription = unCodeObject["name"];
    if (!unDescription.includes(":")) {
      return unDescription;
    }

    // Use everything after the colon in code description as the PSN
    const psnStart = unDescription.indexOf(":") + 1;
    const psnEnd = unDescription.length;
    return unDescription.slice(psnStart, psnEnd).trim();
  }
}
