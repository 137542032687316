import { NgModule } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [],
  imports: [
    NzTableModule,
    NzDatePickerModule,
    NzPageHeaderModule,
    NzCardModule,
    NzGridModule
  ],
  exports: [
    NzTableModule,
    NzDatePickerModule,
    NzPageHeaderModule,
    NzCardModule,
    NzButtonModule,
    NzTabsModule,
    NzGridModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }
  ]
})
export class ZorroModule { }
