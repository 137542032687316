import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { LoadCodes, LoadProduct, SetCode, ChangeProduct, ReplicateTransportationCore, SetAllWasteToNone } from 'src/app/store/expert.actions';
import { map, first } from 'rxjs/operators';
import { ExpertState } from 'src/app/store/expert.state';
import { Product, CodeObject, None, Indeterminable, NotRegulated, NotApplicable } from 'src/app/store/models/product.model';
import { Select } from '@ngxs/store';
import { CodesService } from 'src/app/shared/services/codes/codes.service'

@Injectable({
  providedIn: 'root'
})
export class ExpertService {
  private expertDataSource = new BehaviorSubject<any>(null);
  private modifiedData = new BehaviorSubject<any>(null);
  private treeData = new BehaviorSubject<any>(null);
  private treeItem = new BehaviorSubject<any>(null);
  private discard = new BehaviorSubject<any>(false);
  private selectedItem = new BehaviorSubject<any>(false);
  private physicalData = new BehaviorSubject<any>(null);

  // Observable data streams
  getExpertData$ = this.expertDataSource.asObservable();
  getModifiedData$ = this.modifiedData.asObservable();
  getTreeData$ = this.treeData.asObservable();
  getTreeItem$ = this.treeItem.asObservable();
  getDiscard$ = this.discard.asObservable();
  disableInteraction$ = this.selectedItem.asObservable();
  getPhysicalData$ = this.physicalData.asObservable();

  @Select(ExpertState.getSelectedProduct) selectedProductObservable$: Observable<Product>;
  private selectedProduct: Product;

  private _stateForm: any;
  private _selectedTreeElement = '';
  private _mainState: any;
  private _selectedComponent = '';
  lastId = 0;

  get getStateForm() { return this._stateForm }
  set setStateForm(value) { this._stateForm = value }

  get getSelectedTreeItem() { return this._selectedTreeElement }
  set setSelectedTreeItem(value) { this._selectedTreeElement = value }

  get getMainState() { return this._mainState }
  set setMainState(value) { this._mainState = value }

  get getSelectedComponent() { return this._selectedComponent }
  set setSelectedComponent(value) { this._selectedComponent = value }

  private classificationEditorTasks = ["validate_expert_classification", "override_classification"];

  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private codesService: CodesService
  ) {
    this.selectedProductObservable$.subscribe((product: Product) => {
      this.selectedProduct = product;
    });
  }

  sendData(data: any) {
    this._stateForm =  { request: data, expert_data: { task_id: this.lastId } };
    this.expertDataSource.next(data);
  }

  sendTreeData(data: any) {
    this.treeData.next(data);
  }

  sendSelectedTreeItem(data: any) {
    this.expertDataSource.next(data);
  }

  getData(): Observable<any> {
    return this.expertDataSource.asObservable();
  }

  sendPhysicalData(data: any) {
    this.physicalData.next(data);
  }

  exitComponent() {
    this._selectedComponent = '';
    this.selectedItem.next(false);
    this.discard.next(!this.discard);
  }

  disableTree(state) {
    this.selectedItem.next(state);
  }

  getTask(id: number) {
    return this.httpClient.get(`${environment.apiUrl}tasks/${id}`).pipe(first());
  }

  parseDataFromApi(res: object) {
    const result = res["results"][0];
    const data = (result && result["data"])
    if (typeof data === "string") {
      return JSON.parse(data);
    }
    return data;
  }

  fetchData(id: number) {
    if (this.lastId !== id) {
      this.lastId = id;
      this.getTask(id).subscribe(
        res => {
          const dataFromApi = this.parseDataFromApi(res);

          // NOTE: Eventually this should be the only thing happening in this function (once genome facets use the store)
          this.loadProductAndCodes(id, res, dataFromApi);

          this._mainState = dataFromApi;
          this.sendData(dataFromApi);
          this.sendTreeData(dataFromApi);
        },
        error =>  console.error('error message', error)
      )
    }
  }

  save(id: number, data: any, product: Product) {
    data["expert_data"]["type"] = "save";
    this.mergeNonStoreData(data.request, product);
    this.lastId = 0;
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/results`,
      data
    ).pipe(first());
  }

  submit(id: number, data: any, product: Product) {
    data["expert_data"]["type"] = "submit";
    // NOTE: If genome facets are added to the state then we don't need to merge any data, just send the entire
    // product in the store as the result (same with save)
    this.mergeNonStoreData(data.request, product);
    return this.httpClient.post(
      `${environment.apiUrl}tasks/${id}/results`,
      data.request
    ).pipe(first());
  }

  returnTaskToQueue(taskId: number) {
    return this.httpClient.request(
      'DELETE',
      `${environment.apiUrl}tasks/${taskId}/unassign`
    ).pipe(first());
  }

  // Delete properties from the result that we don't want to send to the backend
  deleteUnwantedResultProperties(product) {
    const removeProperties = ["select_proper_shipping_name", "select_technical_name"];

    // NOTE: Have to create a new object since state isn't mutable (from selector)
    let newProduct = {
      ...product,
      transportation: {
        core: {...product.transportation.core},
        ground: {...product.transportation.ground},
        air: {...product.transportation.air},
        sea: {...product.transportation.sea}
      }
    };

    delete newProduct["duplicate_code_objects"];
    for (const transportMode in newProduct.transportation) {
      removeProperties.forEach(property => {
        if (newProduct.transportation[transportMode][property] !== undefined) {
          delete newProduct.transportation[transportMode][property];
        }
      });
    }

    let newChildren = [];
    (newProduct.children || []).forEach(child => {
      newChildren.push(this.deleteUnwantedResultProperties(child));
    });
    newProduct.children = newChildren;

    return newProduct
  }

  // Temporary hack to merge data that doesn't use the store yet (pace facets)
  mergeNonStoreData(data, product) {
    data.waste = product.waste;
    data.transportation = product.transportation;
    data.other = product.other;
    data.duplicate_code_objects = product.duplicate_code_objects;  // Only for saving / loading. Gets removed when submitting.

    if (data.children) {
      for (const i in data.children) {
        const dataChild = data.children[i];
        const productChild = product.children[i];
        this.mergeNonStoreData(dataChild, productChild);
      }
    }
  }

  // Action helpers

  // NOTE: Product must be loaded after the codes are loaded so that normalization has access to the loaded codes
  loadProductAndCodes(id: number, res: object, dataFromApi: Product) {
    this.codesService.getWasteCodes(wasteCodes => {
      this.codesService.getTransportationCodes(transportationCodes => {
        this.codesService.getOtherCodes(otherCodes => {
          this.store.dispatch(new LoadCodes(wasteCodes, transportationCodes, otherCodes));
          this.loadProduct(id, res, dataFromApi);
        });
      });
    });
  }

  loadProduct(id: number, res: object, dataFromApi: Product) {
    dataFromApi.is_classification_editor_task = this.classificationEditorTasks.includes(res["facet"]);
    this.store.dispatch(new LoadProduct(id, dataFromApi));
  }

  changeProduct(id: string) {
    this.store.dispatch(new ChangeProduct(id));
  }

  setCode(value: CodeObject | CodeObject[], ...path: any[]) {
    if (Array.isArray(value)) {
      value = this.filterNoneOrIndeterminable(value);
      value = this.squishArray(value);
    }
    this.store.dispatch(new SetCode(value, path));
  }

  replicateTransportationCore() {
    const confirmMessage = 'Are you sure? This will overwrite ground, air, and sea.';
    if (confirm(confirmMessage)) {
      this.store.dispatch(new ReplicateTransportationCore());
    }
  }

  setAllWasteToNone() {
    const confirmMessage = 'Are you sure? This will overwrite every field with "None".';
    if (confirm(confirmMessage)) {
      this.store.dispatch(new SetAllWasteToNone());
    }
  }

  // Selector helpers

  getCodes(...path: any[]) {
    return this.store
      .select(ExpertState.getCodes)
      .pipe(map(codeFn => codeFn(path)));
  }

  getSelectedCodes(...path: any[]) {
    return this.store
      .select(ExpertState.getSelectedCodes)
      .pipe(map(codeFn => codeFn(path)));
  }

  // Utility

  isAttributeModified(path: Array<any>) {
    let product = this.selectedProduct;
    return product && product.is_classification_editor_task && product.original_data_diffs[path.join(".")] !== undefined;
  }

  isReadOnly(product: Product = null) {
    product = product || this.selectedProduct;
    return product && product.skip_expert_classification;
  }

  disableReadOnlyForm(form, alwaysReadOnly = false) {
    if (this.isReadOnly() || alwaysReadOnly) {
      if (form.enabled) {
        form.disable();
      }
    } else if (form.disabled) {
      form.enable();
    }
  }

  // Don't allow none/indeterminable to mix with other values in multiselects
  private filterNoneOrIndeterminable(codes: CodeObject[]) {
    const filteredCodes = [None, Indeterminable, NotRegulated, NotApplicable];
    const last = codes[codes.length - 1];
    for (const code of codes) {
      for (const filteredCode of filteredCodes) {
        if (code.id === filteredCode.id) { return [last]; }
      }
    }
    return codes as CodeObject[];
  }

  // Remove duplicates and empty indices
  private squishArray(codes: CodeObject[]) {
    let uniqueIds = {};
    codes.forEach(code => {
      uniqueIds[code.id] = code;
    });
    return Object.values(uniqueIds) as CodeObject[];
  }
}
