import { Component, OnInit, Input } from '@angular/core';
import { ExpertService } from "./../../../../shared/services/expert/expert.service";
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-expert-classification-container',
  templateUrl: './expert-classification-container.component.html',
  styleUrls: ['./expert-classification-container.component.less'],
})

export class ExpertClassificationContainerComponent implements OnInit {
  data:any;
  apiCalled = false;
  @Input() themeVar: string;
  modifiedData = [];
  subscription: Subscription;
  id = 0;

  constructor(public route: ActivatedRoute, public expertService: ExpertService, public router: Router,) {
    this.data = ''
    this.id = this.route.snapshot.params.id;
    this.expertService.fetchData(this.id);
  }

  ngOnInit() {
    this.data = ''
    this.subscription = this.expertService.getModifiedData$.subscribe(data => {
      if (data) {
        this.changeDataHandler(data);
      }
    });
  }

  changeDataHandler(modifiedData: any) {
    this.data = this.expertService.getMainState;
    if (modifiedData) {
      var child = this.findChildById(this.data, modifiedData.targetId);
      if (child) {
        child[modifiedData.type] = modifiedData.payload;
        this.sendDataToAll(child);
      }
      this.expertService.setStateForm = { request: this.data, expert_data: {task_id: this.id} };
    }
  }

  findChildById(data: any, id: string) {
    if (data.id == id) {
      return data
    } else if (data.children) {
      for (const i in data.children) {
        const child = this.findChildById(data.children[i], id);
        if (child) { return child; }
      }
    }
  }

  sendDataToAll(data) {
    this.expertService.setSelectedTreeItem = data.id;
    this.expertService.sendData(data);
    this.expertService.sendTreeData(this.data);
  }
}
