import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-container',
  templateUrl: './product-container.component.html',
  styleUrls: ['./product-container.component.less']
})

export class ProductContainerComponent implements OnInit {
  @Input() themeVar: string;
  constructor() {}
  ngOnInit() {
  }
}
