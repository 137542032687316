import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request-interceptor';
import { ZorroModule } from './zorro.module';
import { CancelComponent } from './components/cancel/cancel.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RevisionDateComponent } from './components/revision-date/revision-date.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [CancelComponent, RevisionDateComponent, DropDownComponent],
  imports: [
    CommonModule,
    ZorroModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    ZorroModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CancelComponent,
    RevisionDateComponent,
    DropDownComponent,
    NgSelectModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ]
})
export class SharedModule { }
