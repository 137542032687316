import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-wa',
  templateUrl: './wa.component.html',
  styleUrls: ['./wa.component.less']
})

export class WaComponent extends BaseComponent implements OnInit {
  @Output() handleOpen: EventEmitter<any> = new EventEmitter();
  @Output() handleClose: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  codes: {};

  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    private expertService: ExpertService,
    public activeRoute: ActivatedRoute,
    public requestService: RequestService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      wt: ['', Validators.required],
      wp: ['', Validators.required],
      ws: ['', Validators.required],
      wpcb: ['', Validators.required]
    });

    this.codeSubscription = this.expertService.getCodes('waste', 'wa').subscribe((value) => {
      this.codes = value || {};
    });

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('waste', 'wa').subscribe((waValues) => {
      this.expertService.disableReadOnlyForm(this.form);
      waValues = waValues || {};
      Reflect.ownKeys(waValues).forEach(value => {
        this.f[value.toString()].setValue(waValues[value] || null);
      });
    });
  }

  onChange(type: string) {
    this.expertService.setCode(this.f[type].value, 'waste', 'wa', type);
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.form.valid) {
      const request = {
        WT: this.f.wt.value,
        WP: this.f.wp.value,
        WS: this.f.ws.value,
        WPCB: this.f.wpcb.value
      };
      this.post(request);
    }
  }

  onClose() {
    this.handleClose.emit(null);
  }

  onOpen() {
    this.handleOpen.emit(null);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
