import { Component, OnInit, Input} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/shared/services/user/user.service";
import { AngularTokenService } from 'angular-token';
import { PasswordValidation } from './password-validation';

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.less"]
})
export class ChangePasswordComponent implements OnInit {
  @Input() themeVar: string;
  changePasswordForm: FormGroup;
  submitted = false;
  headers = [];
  notMatch = false;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public toastr: ToastrService,
    private userService: UserService,
    private router: Router,
    private tokenService: AngularTokenService
  ) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;
    this.headers[`access-token`] = queryParams["token"];
    this.headers[`client`] = queryParams["client"];
    this.headers[`uid`] = queryParams["uid"];

    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(6)]]
    },
    { validator: PasswordValidation.MatchPassword});
  
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      const data = {
        password: this.f.password.value,
        password_confirmation: this.f.passwordConfirmation.value
      };
      this.userService.changePassword(data, this.headers).subscribe(
        (res: any) => {
          if (res.success) {
            this.toastr.success(res.message);
            this.tokenService.signOut().subscribe(
              res => {
                localStorage.removeItem("user");
                this.router.navigate(["/login"]);
              },
              error => {
                this.toastr.error("Failed trying to logout");
              }
            );
          } else {
            this.toastr.error(res.message);
          }
        },
        error => {
          this.toastr.error("Some error occurred");
        }
      );
    }
  }
}
