import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.less']
})
export class TabBarComponent implements OnInit {
  tabs=[
    { name: 'transportation', label: 'Transportation' },
    { name: 'waste', label: 'Waste' },
    { name: 'other', label: 'Other' }
  ]
  selectedTabName='transportation';
  constructor(private router: Router,
              private route: ActivatedRoute,
              public expertService: ExpertService) { }

  ngOnInit() {
    let elem = this.route.snapshot["_routerState"].url.split("/");
    this.selectedTabName = elem[elem.length - 1];
  }

  selectTab(tab_name){
    if(this.selectedTabName == tab_name)
      return;

    this.changeSelectedTab(tab_name);

    if(tab_name != '')
      this.displayTab(tab_name);
  }

  displayTab(tab_name){
    let target_component = this.tabs[0].name;
    for (const tabInfo of this.tabs) {
      if (tabInfo.name == tab_name) {
        target_component = tabInfo.name;
        break;
      }
    }
    this.router.navigate(['./' + target_component], { relativeTo: this.route });
  }

  changeSelectedTab(tab_name){
    this.selectedTabName = tab_name
  }
}
