import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-il',
  templateUrl: './il.component.html',
  styleUrls: ['./il.component.less']
})
export class IlComponent implements OnInit {
  @Output() handleOpen: EventEmitter<any> = new EventEmitter();
  @Output() handleClose: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  codes: {};


  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    private expertService: ExpertService,
    public activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      waste: ['', Validators.required],
      class: ['', Validators.required]
    });

    this.codeSubscription = this.expertService.getCodes('waste', 'il').subscribe((value) => {
      this.codes = value || {};
    })

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('waste', 'il').subscribe((ilValues) => {
      this.expertService.disableReadOnlyForm(this.form);
      ilValues = ilValues || {};
      Reflect.ownKeys(ilValues).forEach(value => {
        this.f[value.toString()].setValue(ilValues[value] || null);
      })
    })
  }

  onChange(type: string) {
    this.expertService.setCode(this.f[type].value, 'waste', 'il', type);
  }

  onClose(){
    this.handleClose.emit(null);
  }

  onOpen(){
    this.handleOpen.emit(null);
  }

  get f(){
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
