import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { ExpertState } from 'src/app/store/expert.state';
import { ExpertStateModel } from 'src/app/store/models/expert.model';
import { Product } from 'src/app/store/models/product.model';
import { ValidatorsService } from 'src/app/shared/services/validators/validators.service';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '../../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';

@Component({
  selector: 'app-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.less']
})
export class RightPanelComponent extends BaseComponent implements OnInit, OnDestroy {
  isDisabled = false;
  window = null;
  subscription: Subscription;
  data = {};

  @Select(ExpertState) expertStateObservable$: Observable<ExpertStateModel>;
  expertStateSubscription: Subscription;

  @Select(ExpertState.getSelectedProduct) selectedProductObservable$: Observable<Product>;
  selectedProductSubscription: Subscription;

  taskId: number;
  rootProduct: Product;
  originalClassifier: string;

  constructor(
    private expertService: ExpertService,
    public router: Router,
    public toastr: ToastrService,
    private validators: ValidatorsService,
    public route: ActivatedRoute,
    public taskService: TaskService,
    public sanitizer: DomSanitizer,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute

  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state);
  }

  ngOnInit() {
    this.expertStateSubscription = this.expertStateObservable$.subscribe(expertState => {
      this.rootProduct = expertState.product;
      this.taskId = expertState.task_id;
    });

    this.selectedProductSubscription = this.selectedProductObservable$.subscribe(selectedProduct => {
      this.originalClassifier = selectedProduct.original_classifier || "Portal";
    });
  }

  handleStateAction(action) {
    this.data = this.expertService.getStateForm;
    if (action === "save") {
      this.save(this.rootProduct);
    } else if (action === "submit") {
      this.saveAndSubmit(this.rootProduct);
    } else if (action === "returnTaskToQueue") {
      this.returnTaskToQueue();
    }
  }

  cancel(data) {
    this.delete(data, this.taskId);
  }

  save(product: Product) {
    this.expertService.save(this.taskId, this.data, product).subscribe(
      res => {
        this.toastr.success('Result saved');
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  // NOTE: An error will occur on the backend if the product isn't saved at least once before submitting
  saveAndSubmit(product: Product) {
    const submitProduct = this.expertService.deleteUnwantedResultProperties(product);
    if (this.validators.validate(submitProduct) && confirm("Are you sure you want to submit?")) {
      this.expertService.save(this.taskId, this.data, product).subscribe(
        res => {
          this.submit(product);
        },
        err => {
          this.toastr.error(err.message);
        }
      );
    }
  }

  private submit(product: Product) {
    this.expertService.submit(this.taskId, this.data, product).subscribe(
      res => {
        this.toastr.success('Result submited');
        this.router.navigate(['/private/task/list']);
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  returnTaskToQueue() {
    if (!confirm(`Return task #${this.taskId} to the queue and exit?`)) return;

    this.expertService.returnTaskToQueue(this.taskId).subscribe(
      res => {
        this.toastr.success(`Successfully returned task #${this.taskId} to the queue`);
        this.router.navigate(['/private/task/list']);
      },
      err => {
        this.toastr.error(err.message);
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.expertStateSubscription.unsubscribe();
    this.selectedProductSubscription.unsubscribe();
  }
}
