import { Component, OnInit, OnDestroy, ViewChildren, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { None, Indeterminable, NotApplicable, NotRegulated, EnterValue } from 'src/app/store/models/product.model'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.less']
})

export class TransportationComponent implements OnInit, OnDestroy {
  @ViewChildren('transportation') div:ElementRef;
  form: FormGroup;
  codes: {};

  transportModes = ["core", "ground", "air", "sea"];

  textEntryControls = ["description", "proper_shipping_name", "technical_name_0", "technical_name_1", "technical_name_2",
  "technical_name_3", "technical_name_4", "technical_name"]

  selectControls = {};

  controlNames = ["description", "un_number", "select_proper_shipping_name",
  "proper_shipping_name", "select_technical_name", "technical_name", "technical_name_0", "technical_name_1",
  "technical_name_2", "technical_name_3", "technical_name_4", "hazard_class_division", "compatibility_group", "subrisk",
  "packing_group", "marine_pollutant", "limited_quantity", "excepted_quantity",
  "reportable_quantity_lbs", "transportation_exception"];

  nonCoreControls = ["limited_quantity", "excepted_quantity",
  "reportable_quantity_lbs", "transportation_exception"];

  technicalNameControls = ["technical_name_0", "technical_name_1", "technical_name_2", "technical_name_3", "technical_name_4"];

  multiSelectControls = ["subrisk", "transportation_exception"];

  customChangeActions = ["hazard_class_division"];
  packingGroupDefaultArray = ["2.", "6.2", "7"];

  codeSubscription: Subscription;
  selectedCodeSubscriptions: {};

  get f() {
    return this.form.controls;
  }

  constructor(
    public router: Router,
    private expertService: ExpertService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute
  ) { }

  disableScroll(){
    let transportation_div = this.div['first'].nativeElement
    var x=transportation_div.scrollLeft;
    var y=transportation_div.scrollTop;
    transportation_div.onscroll=function(){transportation_div.scrollTo(x, y);};
  }

  enableScroll(){
    let transportation_div = this.div['first'].nativeElement
    transportation_div.onscroll=function(){};
  }

  ngOnInit() {
    this.selectControls = {
      'proper_shipping_name': 'select_proper_shipping_name',
      'technical_name': 'select_technical_name'
    };

    this.form = this.formBuilder.group({
      core: this.buildForm('core'),
      ground: this.buildForm('ground'),
      air: this.buildForm('air'),
      sea: this.buildForm('sea')
    });

    this.codes = {shared: {}, ground: {}, air: {}, sea: {}};
    this.selectedCodeSubscriptions = {core: {}, ground: {}, air: {}, sea: {}};

    this.codeSubscription = this.expertService.getCodes('transportation').subscribe((codes) => {
      this.codes = codes || this.codes;
    });

    // TODO: don't create unnecessary subscriptions for controls that don't have codes in the backend
    this.controlNames.forEach(control => {
      // Ignore 'technical_name_' controls since we just want to listen to the array that the store assembles
      if (!this.technicalNameControls.includes(control)) {
        const isMarinePollutant = control === "marine_pollutant";
        const isNonCoreControl = this.nonCoreControls.includes(control);
        this.transportModes.forEach(transportMode => {
          const isCore = transportMode === "core";
          if ((isCore && isMarinePollutant) || ((!isCore || !isNonCoreControl) && !isMarinePollutant)) {
            this.buildSubscriptions(transportMode, control);
          }
        });
      }
    });
  }


  buildSubscriptions(transportMode, control) {
    this.selectedCodeSubscriptions[transportMode][control] = this.expertService.getSelectedCodes("transportation", transportMode, control).subscribe((value) => {
      this.expertService.disableReadOnlyForm(this.form);
      // Use raw string/number value for text entry
      if (value && this.textEntryControls.includes(control)) {
        if (Array.isArray(value)) {
          let newValue = []
          value.forEach(code => {
            newValue.push(code.id);
          });
          value = newValue;
        } else {
          value = value.id;
        }
      }

      const isBlank = typeof(value) !== "number" && value !== null && value !== undefined && !Object.keys(value).length;
      if (isBlank)
        value = null;

      if (this.multiSelectControls.includes(control) && !Array.isArray(value)) {
        value = [];
      }

      if (control === "technical_name") {
        this.technicalNameControls.forEach((technicalNameControl, i) => {
          this.f[transportMode]["controls"][technicalNameControl].setValue((value || [])[i]);
        });
      }

      this.f[transportMode]["controls"][control].setValue(value);
    });
  }

  selectItemPSN(formGroup, value) {
    const oldValue = this.f[formGroup]["controls"]["proper_shipping_name"].value;
    value = value ? value.id : value;
    if (value !== "enter_value" || this.convertSelectCode(oldValue)) {
      if (value === "enter_value") { value = ""; }
      this.changeValue(formGroup, "proper_shipping_name", value);
    }
  }

  selectItemTN(formGroup, value) {
    const oldValue = this.f[formGroup]["controls"]["technical_name_0"].value;
    const technicalNameControls = ["technical_name_0", "technical_name_1", "technical_name_2", "technical_name_3",
                                   "technical_name_4"];
    value = value ? value.id : value;
    if (value !== "enter_value" || this.convertSelectCode(oldValue)) {
      if (value === "enter_value") { value = ""; }
      technicalNameControls.forEach(technicalName => {
        this.changeValue(formGroup, technicalName, value);
      })
    }
  }

  buildForm(type) {
    const shared = {
      description: '',
      un_number: '',
      select_proper_shipping_name: '',
      proper_shipping_name: '',
      select_technical_name: '',
      technical_name: '',
      technical_name_0: '',
      technical_name_1: '',
      technical_name_2: '',
      technical_name_3: '',
      technical_name_4: '',
      hazard_class_division: '',
      compatibility_group: '',
      subrisk: '',
      packing_group: null
    };
    if (type === 'core') {
      return this.formBuilder.group({
        ...shared,
        marine_pollutant: ''
      });
    } else {
      return this.formBuilder.group({
        ...shared,
        limited_quantity: null,
        excepted_quantity: null,
        reportable_quantity_lbs: null,
        transportation_exception: null
      });
    }
  }

  generateTechnicalNameArray(transportMode, controlName, value) {
    let technicalNameArray = this.f[transportMode]['controls'].technical_name.value || [];
    let newTechnicalNameArray = [];
    this.technicalNameControls.forEach((technicalNameControl, i) => {
      if (technicalNameControl === controlName) {
        value = value.trim();
        if (value) {
          newTechnicalNameArray.push({id: value, name: value});
        }
      } else if (technicalNameArray[i]) {
        newTechnicalNameArray.push({id: technicalNameArray[i], name: technicalNameArray[i]});
      }
    });
    return newTechnicalNameArray;
  }

  changeValue(formGroupName, controlName, value) {
    const valueType = typeof(value);

    // Don't change 'technical_name_#' controls directly, instead modify the technical_name array
    if (/technical_name_\d/.test(controlName)) {
      value = this.generateTechnicalNameArray(formGroupName, controlName, value);
      controlName = "technical_name";

    // Convert text entry control values to code format
    } else if (valueType === "string" || valueType === "number") {
      if (valueType === "string") { value = value.trim(); }
      value = {id: value, name: value};
    }

    if (this.customChangeActions.includes(controlName)) {
      this.customChangeEvent(formGroupName, controlName, value);
    }

    // Cap subrisk at 2 values (allow none & indeterminable because they will reset the array)
    if (controlName === "subrisk" && value.length > 2 && ![None.id, Indeterminable.id].includes(value[2].id)) {
      value = [value[0], value[1]];
    }

    this.expertSetCode(value, formGroupName, controlName);
    this.setSelect(formGroupName, controlName, value);
  }

  customChangeEvent(formGroupName, controlName, value) {
    switch(controlName){
      case "hazard_class_division":
        if (!value.id.toString().startsWith(1.)) {
          this.setNotApplicable(formGroupName, "compatibility_group");
        }
        if (this.packingGroupDefaults(value)) {
          this.setNotApplicable(formGroupName, "packing_group");
        }
        break;
    }
  }

  expertSetCode(value, formGroupName, controlName) {
    this.expertService.setCode(
      value,
      "transportation",
      formGroupName,
      controlName
    );
  }

  packingGroupDefaults(value) {
    return this.packingGroupDefaultArray.some(function (pgValue) {
      return value.id.toString().startsWith(pgValue);
    });
  }

  setNotApplicable(formGroupName, formControlName) {
    this.expertSetCode(
      NotApplicable,
      formGroupName,
      formControlName
    );
  }

  setSelect(formGroupName, controlName, value) {
    if (typeof value === "string") { value = this.convertSelectCode(value); }
    switch (controlName) {
      case "select_proper_shipping_name":
        this.selectItemPSN(formGroupName, value);
        break;
      case "select_technical_name":
        this.selectItemTN(formGroupName, value);
        break;
    }
  }

  convertSelectCode(value) {
    const codes = [None, Indeterminable, NotApplicable, NotRegulated, EnterValue];
    let matchedCode = null;
    codes.forEach(code => {
      if (code.id === value) {
        matchedCode = code;
        return;
      }
    });
    return matchedCode;
  }

  isUnDisabled(transportMode) {
    const controlValue = this.f[transportMode]['controls'].un_number.value;
    return controlValue && controlValue.id === "not_regulated";
  }

  isSelectEnabled(transportMode, selectControlName) {
    const controlValue = this.f[transportMode]['controls'][selectControlName].value;
    return controlValue && controlValue.id === "enter_value";
  }

  ngOnDestroy() {
    this.codeSubscription.unsubscribe();
    for (const type in this.selectedCodeSubscriptions) {
      for (const control in this.selectedCodeSubscriptions[type]) {
        this.selectedCodeSubscriptions[type][control].unsubscribe();
      }
    }
  }
}
