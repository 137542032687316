import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularTokenService } from "angular-token";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.less"]
})
export class ResetPasswordComponent implements OnInit {
  @Input() themeVar: string;
  resetForm: FormGroup;
  submitted = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private tokenService: AngularTokenService,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.resetForm.valid) {
      this.tokenService.resetPassword({ login: this.f["email"].value }).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.message);
          } else {
            this.toastr.error(res.message);
          }
        },
        error => {
          this.toastr.error("Some error occurred");
        }
      );
    }
  }

  get f() {
    return this.resetForm.controls;
  }
}
