import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ReportService } from '../../../../shared/services/report/report.service';
import { timezones } from '../../../../constants/timezone';
import * as moment from 'moment-timezone';
interface SupervisorItemData {
  date: string;
  userArray: UserArray[];
  average: number;
}

interface UserArray {
  name: string;
  result: string;
}

@Component({
  selector: 'app-supervisor-report',
  templateUrl: './supervisor-report.component.html',
  styleUrls: ['./supervisor-report.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SupervisorReportComponent implements OnInit {
  private userData: string[] = [];
  public listOfAgentData: SupervisorItemData[] = [];
  public userNameList: string[] = [];
  private dateList: string[] = [];
  private weekStartDate: string;
  private weekEndDate: string;
  public range: string[] = [];
  public dateRange = [];
  public averageTableData = [];
  public selectedTimezone = null;
  public timezoneArray = timezones;

  private averageTableDictionary = {
    week_all_agent_report: 'All agent (Last 7 days)',
    month_all_agent_report: 'All agent (Last 30 days)'
  };

  constructor(private api: ReportService) { }

  ngOnInit() {
    this.selectedTimezone = 'Asia/Manila';
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAverageResult(this.selectedTimezone);
  }

  public timezoneChange(timezone) {
    this.selectedTimezone = timezone;
    this.getDateForWeek();
    this.assignInitialDateRange();
    this.retrieveResults(this.weekStartDate, this.weekEndDate, this.selectedTimezone);
    this.retrieveAverageResult(this.selectedTimezone);
  }

  public setDefaultTimeZone() {
    return this.selectedTimezone == null ? this.selectedTimezone = 'Asia/Manila' : this.selectedTimezone;
  }

  private getDateForWeek() {
    this.weekStartDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(7, 'days')
      .startOf('day')
      .toISOString();
    this.weekEndDate = moment
      .tz((moment.tz(this.setDefaultTimeZone()).format('YYYY-MM-DD')), this.setDefaultTimeZone())
      .subtract(1, 'days')
      .endOf('day')
      .toISOString();
  }

  private assignInitialDateRange() {
    const weekStartCalendar = this.splitTime(moment.tz(this.weekStartDate, this.setDefaultTimeZone()).format());
    const weekEndCalendar = this.splitTime(moment.tz(this.weekEndDate, this.setDefaultTimeZone()).format());
    const timeZone = moment.tz.guess();
    this.dateRange = [
      moment(this.splitTime(weekStartCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS'),
      moment(this.splitTime(weekEndCalendar)).tz(timeZone).format('YYYY-MM-DD HH:MM:SS')
    ];
  }

  private splitTime(date: string) {
    if (date) {
      return date.split('T')[0];
    }
    return date;
  }

  public onRangeSelect(result: Date): void {
    const range1 = (moment(result[0]).format()).split('T');
    const range2 = (moment(result[1]).format()).split('T');
    const startDateRange = moment.tz(range1[0], this.setDefaultTimeZone())
      .startOf('day')
      .toISOString();
    const endDateRange = moment.tz(range2[0], this.setDefaultTimeZone())
      .endOf('day')
      .toISOString();
    this.retrieveResults(startDateRange, endDateRange, this.selectedTimezone);
  }

  public retrieveResults(startDate: string, endDate: string, timeZone: string) {
    this.resetVariables();
    this.api.getByDateAll(startDate, endDate, timeZone).subscribe(
      res => {
        if (res) {
          this.userData = res;
          this.generateUserArray();
          this.generateDateArray();
          const resultsArray = [];
          this.dateList.forEach(date => {
            const userList = [];
            let average = 0;

            res.forEach(key => {
              if (key.date === date) {
                userList.push({
                  name: key.full_name,
                  result: key.count,
                });
                average += key.count,
                  date = key.date
              }
            });
            resultsArray.push({
              date,
              userArray: userList,
              average: this.calculateAverageResult(average).toFixed(3)
            });
          });
          this.listOfAgentData = resultsArray;
        }
      },
      error => console.log(error)
    );
  }

  private calculateAverageResult(average) {
    const averageResult = average / this.userNameList.length;
    return averageResult;
  }
  private resetVariables() {
    this.listOfAgentData = [];
    this.userNameList = [];
    this.dateList = [];
  }

  public changeDateFormat(dateTime: string) {
    const date = dateTime.split('T')[0];
    const dates = date.split('-');
    const formattedDate = [dates[1], dates[2], dates[0].substr(0, 2)].join("/");
    return formattedDate;
  }

  private generateUserArray() {
    Object.values(this.userData).forEach(element => {
      if (!this.userNameList.includes(element['full_name'])) {
        this.userNameList.push(element['full_name']);
      }
    });
  }

  private generateDateArray() {
    Object.values(this.userData).forEach(element => {
      if (!this.dateList.includes(element['date'])) {
        this.dateList.push(element['date']);
      }
    });
  }

  public getUserCount(name: string, userIndex: UserArray[]) {
    const value = userIndex.filter(key => {
      if (key.name === name) {
        return key.result;
      }
    });
    return value[0] ? value[0].result : '-';
  }

  public retrieveAverageResult(timeZone: string) {
    this.api.retrieveAverageResultData(timeZone).subscribe(
      res => {
        if (res) {
          this.averageTableData = [];
          Object.keys(res).forEach(key => {
            let total = 0;
            if (this.averageTableDictionary.hasOwnProperty(key)) {
              res[key].forEach(keys => {
                total += keys.count;
              });
              if (total !== 0) {
                this.averageTableData.push({
                  title: this.averageTableDictionary[key],
                  value: this.calculateAverageCount(total, key).toFixed(3)
                });
              } else {
                this.averageTableData.push({
                  title: this.averageTableDictionary[key],
                  value: '-'
                });
              }
            }
          });
        }
      },
      error => console.log(error)
    );
  }

  private calculateAverageCount(count, key) {
    if (key.startsWith('week_')) {
      return count / 7;
    } else {
      return count / 30;
    }
  }
}
