import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { first } from 'rxjs/operators';
import { None } from 'src/app/store/models/product.model'

@Injectable({
  providedIn: 'root'
})
export class CodesService {
  private ignoreCodeConversion = ["technical_name_whitelist"];
  private ignoreIncludeNone = ["e_waste"];

  private wasteCodesPath = "waste_codes";
  private transportationCodesPath = "codes?codes_for=transportation";
  private otherCodesPath = "codes?codes_for=other_codes";

  constructor(private httpClient: HttpClient) { }

  getWasteCodes(onReceive: (codes: object) => void) {
    this.getCodesFromAPI(this.wasteCodesPath, onReceive, true, true);
  }

  getTransportationCodes(onReceive: (codes: object) => void) {
    this.getCodesFromAPI(this.transportationCodesPath, onReceive, false, false);
  }

  getOtherCodes(onReceive: (codes: object) => void) {
    this.getCodesFromAPI(this.otherCodesPath, onReceive, false, false);
  }

  private getCodesFromAPI(path: string, onReceive: (codes: object) => void, concatID: boolean, includeNone: boolean) {
    this.httpClient.get(`${environment.apiUrl}${path}`).pipe(first()).subscribe(codes => {
      onReceive(this.convertBackendCodes(codes, concatID, includeNone));
    });
  }

  // Loop through incoming backend codes and convert them to the {id: name:} dropdown format
  private convertBackendCodes(codes: object, concatID: boolean, includeNone: boolean) {
    let newCodes = {};
    for (const key in codes) {
      const value = codes[key];

      if (this.ignoreCodeConversion.includes(key)) {
        newCodes[key] = value;
        continue;
      }

      includeNone = includeNone && !this.ignoreIncludeNone.includes(key);

      if (Array.isArray(value)) {
        newCodes[key] = this.convertCodeArray(value, concatID, includeNone);
      } else {
        for (const subKey in value) {
          newCodes[key] = newCodes[key] !== undefined ? newCodes[key] : {};
          const codeArray = value[subKey];
          newCodes[key][subKey] = this.convertCodeArray(codeArray, concatID, includeNone);
        }
      }
    }

    return newCodes;
  }

  private convertCodeArray(codeArray: Array<any>, concatID: boolean, includeNone: boolean) {
    let newArray = includeNone ? [None] : [];
    for (const code of codeArray) {
      let id = code;
      let name = code;
      if (!["string", "number"].includes(typeof(code))) {
        id = Object.keys(code)[0];
        name = id;
        const description = code[id];
        if (description !== undefined && description !== null)
          name = concatID ? `${id}: ${description}` : description;
      }
      newArray.push({id: id, name: name});
    }
    return newArray;
  }
}
