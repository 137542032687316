import { AbstractControl } from '@angular/forms';

export class PasswordValidation {
    static MatchPassword(AC: AbstractControl) {
     let password = AC.get('password').value; // to get value in input tag
     let passwordConfirmation = AC.get('passwordConfirmation').value; // to get value in input tag
    if (!AC.get('password').errors && !AC.get('passwordConfirmation').errors) {
        if (password !== passwordConfirmation) {
            AC.get('passwordConfirmation').setErrors({ MatchPassword: true });
        } else {
            AC.get('passwordConfirmation').setErrors(null);
        }
    }
 
    }
}
