export enum DropdownLabels {
  labelClass = 'Hazard Class',
  labelDivision = 'Hazard Division',
  labelSubClass = 'Subhazard Class',
  labelSubDivision = 'Subhazard Division',
  labelCompatibility = 'Hazard Compatibility',
  labelPackingGroup = 'Hazard Packing Group',
}

export enum DropdownLabelsDeprecated {
  dotLabelClass = 'DOT Hazard Class',
  iataLabelClass = 'IATA Hazard Class',
  imdgLabelClass = 'IMDG Hazard Class',
  dotLabelDivision = 'DOT Hazard Division',
  iataLabelDivision = 'IATA Hazard Division',
  imdgLabelDivision = 'IMDG Hazard Division',
  dotLabelSubClass = 'DOT Subhazard Class',
  iataLabelSubClass = 'IATA Subhazard Class',
  imdgLabelSubClass = 'IMDG Subhazard Class',
  dotLabelSubDivision = 'DOT Subhazard Division',
  iataLabelSubDivision = 'IATA Subhazard Division',
  imdgLabelSubDivision = 'IMDG Subhazard Division',
  dotLabelCompatibility = 'DOT Hazard Compatibility',
  iataLabelCompatibility = 'IATA Hazard Compatibility',
  imdgLabelCompatibility = 'IMDG Hazard Compatibility',
  dotLabelPackingGroup = 'DOT Hazard Packing Group',
  iataLabelPackingGroup = 'IATA Hazard Packing Group',
  imdgLabelPackingGroup = 'IMDG Hazard Packing Group'
}
