import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ExpertService } from '../../services/expert/expert.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.less']
})
export class CancelComponent implements OnInit {
  modalRef: BsModalRef;
  @Output() handleConfirm: EventEmitter<any> = new EventEmitter();
  @Input() themeVar: string;
  @Input() expertCancel: any;
  request = {};
  form: FormGroup;
  submitted = false;
  isDisabled = false;
  expertClassificationMode = false;
  subscription: Subscription;

  static validateComment(AC: AbstractControl) {
    const comment_required = ['ui_issue', 'other', 'space_ui_issue', 'space_other'];
    const reason = AC.get('reason').value; // to get value in input tag
    const comment = AC.get('comment').value; // to get value in input tag
    if (comment_required.includes(reason) && !comment) {
      AC.get('comment').setErrors({ validateComment: true });
      return;
    }
    AC.get('comment').setErrors(null);
    return;
  }

  constructor(
    public modalService: BsModalService,
    private formBuilder: FormBuilder,
    private expertService: ExpertService,
    private activeRoute: ActivatedRoute,
    private route: ActivatedRoute
  ) {

    // Need to determine if in expert mode since PACE/SPACE have different cancel reasons
    this.activeRoute.params.subscribe(routeParams => {
      this.expertClassificationMode = this.route.snapshot['_routerState'].url.match(/expert/);
    });

    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reason: ['', [Validators.required]],
      comment: ['', ]
    },
    { validator: CancelComponent.validateComment});
  }

  confirm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.modalRef.hide();
      this.handleConfirm.emit({
        reason: this.f.reason.value,
        comment: this.f.comment.value
      });
      this.f.reason.setValue('');
      this.f.comment.setValue('');
    }
  }

  decline(): void {
    this.f.reason.setValue('');
    this.f.comment.setValue('');
    this.submitted = false;
    this.modalRef.hide();
  }

  open(template) {
    this.modalRef = this.modalService.show(template);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
