import { Component, OnInit, Input } from "@angular/core";
import { TaskService } from "src/app/shared/services/task/task.service";
import { ExternalTaskService } from "src/app/shared/services/external_task/external_task.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-list-task",
  templateUrl: "./list-task.component.html",
  styleUrls: ["./list-task.component.less"]
})
export class ListTaskComponent implements OnInit {
  private static expertFacets = ['expert_classification', 'validate_expert_classification', 'override_classification'];
  tasks: any = [];
  expertTasks: any = [];
  externalTasks: any = [];
  total = 0;
  totalExternalTasks = 0;
  currentPage = 1;
  currentExternalTaskPage = 1;
  @Input() themeVar: string;
  console = console;
  user = '';

  constructor(
    private taskService: TaskService,
    private externalTaskService: ExternalTaskService,
    public router: Router,
    public toastr: ToastrService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currentPage = !this.route.snapshot.queryParams["page"]
      ? 1
      : parseInt(this.route.snapshot.queryParams["page"]);
    // check if user is expert then get expert pending tasks

    if (this.user["expert"]) {
      this.expertList();
      this.externalTaskList();
    }

    this.list(this.currentPage);
  }

  expertList(): void {
    this.taskService.getUserTask().subscribe((res:any) => {
      this.expertTasks = res.items;
    })
  }

  externalTaskList() {
    this.externalTaskService.list(this.currentExternalTaskPage).subscribe((res: any) => {
      this.externalTasks = res.tasks;
      this.totalExternalTasks = res.meta.total_count
    })
  }

  list(page: number): void {
    this.taskService.list(page).subscribe((res: any) => {
      this.tasks = res.items;
      this.total = res.total;
    });
  }

  pageChanged(event: any): void {
    this.list(event.page);
  }

  pageChangedExternalTask(event: any): void {
    this.currentExternalTaskPage = event.page;
    this.externalTaskList();
  }

  goToTask(facet: string, id: number): void {
    this.taskService.userTask(id).subscribe(
      res => {
        // NOTE: Need to force app refresh to kill persisted states.
        // This should be aleviated once we fix the $store
        if (ListTaskComponent.expertFacets.includes(facet)) {
          location.href =`/private/expert/${id}/transportation`;
        } else {
          location.href =`/private/task/${facet}/${id}`;
        }
      },
      err => {
        this.removeTaskFromList(id);
        this.toastr.error(`Error: could not load task #${id}`);
      }
    );
  }

  removeTaskFromList(id: number): void {
    this.tasks = this.tasks.filter(task => task.id != id);
  }

  goToExternalTask(link_to_portal: string): void {
    window.open(link_to_portal, '_blank');
  }

  dropshipCreatedAt(dropship_review_requests: any): any {
    var filteredArray = dropship_review_requests.filter(function(review) {
      return review.status == "in_review";
    });

    if (filteredArray.length != 0) {
      var myObject = filteredArray[0];
      return myObject.created_at;
    }
  }
}
