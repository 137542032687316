import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.less']
})

export class ProductInfoComponent implements OnInit {
  @Input() themeVar: string;
  basicInfoData = [];
  data: any = [];
  isDisabled = false;
  console = console;
  product_id: '';
  notAllowed = [
    'children', 'id', 'sds_url', 'un_38_3_url', 'outerPackaging', 'innerPackaging',
    'quantity', 'transportation', 'waste', 'skip_expert_classification', 'duplicate_code_objects',
    'original_data_diffs', 'original_data', 'original_classifier'
  ];
  info_routes = [
    {
      name: 'form',
      id_necessary: true
    },
    {
      name: 'boiling_point',
      id_necessary: true
    },
    {
      name: 'flash_point',
      id_necessary: true
    },
    {
      name: 'ph',
      id_necessary: true
    },
    {
      name: 'cas_numbers_and_concentration',
      id_necessary: true
    },
    {
      name: 'intended_use',
      id_necessary: true
    },
    {
      name: 'packaging_info',
      id_necessary: true
    }
  ];
  selected_info_name = '';
  casMasterNames = {};

  constructor(
    private router: Router,
    private expertService: ExpertService,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.selected_info_name = this.expertService.getSelectedComponent;
    this.expertService.getExpertData$.subscribe((data: any) => {
      if (data) {
        this.basicInfoData = [];
        this.data = data;
        this.getBasicInfoData();
        this.loadCasMasterNames(data.cas_numbers_and_concentration);
      }
    });
    this.expertService.disableInteraction$.subscribe(state => {
      if (state) {
        this.selected_info_name = this.expertService.getSelectedComponent;
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    });
  }

  getBasicInfoData() {
    Object.entries(this.data).forEach( ([key, value]) => {
      if (!this.notAllowed.includes(key)) {
        this.basicInfoData[key] = value;
      }
    });
  }

  loadCasMasterNames(info) {
    let casNumbers = [];

    for (const composition of info.ingredients)
      casNumbers = casNumbers.concat(this.getCasNumbers(composition));

    if (casNumbers.length) {
      this.httpClient.post(`${environment.apiUrl}cas_lookup`, { cas_numbers: casNumbers }).subscribe(
        res => this.casMasterNames = res,
        err => this.casMasterNames = {}
      );
    }
  }

  checkRoute(route){
    return this.info_routes.some(e => e.name == route);
  }

  selectInfo(info){
    if(this.expertService.getSelectedComponent === info)
      return;

    this.expertService.setSelectedComponent = info;
    this.disableNonSelectedElements(info)
    this.renderInfoForm(this.info_routes.find(i => i.name === info));
    this.selected_info_name = this.expertService.getSelectedComponent;
  }

  disableNonSelectedElements(info) {
    if (info !== 'packaging_info') {
      this.expertService.disableTree(true);
      this.isDisabled = true;
    }
  }

  renderInfoForm(component){
    this.router.navigate(['./' + component.name], { relativeTo: this.route });
  }

  formatInfoKey(key){
    switch(key.toLowerCase()){
      case 'ph':
        return 'pH';

      case 'cas_numbers_and_concentration':
        return 'CAS numbers and concentration';

      case 'upc':
        return 'UPC';

      case 'item_type':
        return 'Item Type';

      case 'flash_point':
        return 'Flashpoint';

      case 'batteryinfo':
        return 'Battery Information';

      default:
        let key_no_underscores = key.replace(/_/g,' ')
        return key_no_underscores[0].toUpperCase() + key_no_underscores.substr(1).toLowerCase();
    }
  }

  getInfoValue(info){
    switch(info.key.toLowerCase()){
      case 'ph':
        return this.getPhInfo(info.value);

      case 'intended_use':
        return this.getIntendedUseInfo(info.value);

      case 'boiling_point':
      case 'flash_point':
        return this.getPhysicalPointInfo(info.value);

      default:
      if (info.value && info.value.replace) {
        return info.value.replace(/_/g, ' ');
      } else if (typeof(info.value) === "number") {
        return info.value;
      } else {
        return " ";
      }
    }
  }

  getPhInfo(info){
    // unspecified_acid: {ph_min: 0, ph_max: 2.0}, unspecified_neutral: {ph_min: 7, ph_max: 7},
    // unspecified_base: {ph_min: 12.5, ph_max: 14}, unspecified_non_corrosive: {ph_min: 4, ph_max: 10},
    // not_found: {ph_min: nil, ph_max: nil}
    switch(info.format_input){
      case 'specific_value':
      if (info.symbol == 'no_symbol') {
        return info.symbol == '=' ? '' : this.checkForNull(info.ph_value);
      }
      else {
        return (info.symbol == '=' ? '' : info.symbol + ' ') + this.checkForNull(info.ph_value);
      }

      case 'specific_range':
        return this.checkForNull(info.ph_min_value) + ' - ' + this.checkForNull(info.ph_max_value);

      case 'unspecified_acid':
        return '0 - 2.0';

      case 'unspecified_neutral':
        return '7 - 7';

      case 'unspecified_base':
        return '12.5 - 14';

      case 'unspecified_non_corrosive':
        return '4 - 10';

      case 'not_found':
        return 'Not found'
    }
  }

  checkForNull(data) {
    if (!data) {
      return '';
    } else {
      return data;
    }
  }

  getIntendedUseInfo(info){
    if (info.intended_use) {
      if (info.intended_use_subCategory) {
        return `${info.intended_use} >  ${info.intended_use_subCategory}`
      } else {
        return `${info.intended_use}  > `
      }
    } else {
      return "" + ' > ' + ""
    }

  }

  getPhysicalPointInfo(info){
    switch(info.format){
      case 'single_number':
      if (info.symbol == 'no_symbol') {
        return this.translateToFahrenheit(info.value, info.unit) + ' ' + 'Fahrenheit';
        // return info.symbol + ' ' + info.value + ' ' + 'Fahrenheit';
      }
      else {
        return info.symbol + ' ' + this.translateToFahrenheit(info.value, info.unit) + ' ' + 'Fahrenheit';
        // return info.symbol + ' ' + info.value + ' ' + 'Fahrenheit';
      }

      case 'range_given':
        // console.log(info)
        // console.log(this.translateToFahrenheit(info.value_min, info.unit)  + 'Fahrenheit')
        return info.symbol_min + ' ' + this.translateToFahrenheit(info.value_min, info.unit) + ' - ' + info.symbol_max + ' ' + this.translateToFahrenheit(info.value_max, info.unit) + ' ' + 'Fahrenheit';
        // return info.symbol_min + ' ' + info.value_min + ' - ' + info.symbol_max + ' ' + info.value_max, info.unit + ' ' + 'Fahrenheit';

      case 'not_given':
        return 'Not_given'

      case 'not_found':
        return 'Not_found'
    }
  }

  translateToFahrenheit(value, unit){
    switch(unit.toLowerCase()){
      case 'celsius':
        return (value * 1.8) + 32

      case 'fahrenheit':
        return value

      case 'kelvin':
        return (value - 273.15) * 1.8 + 32
      default:
        return this.checkForNull(value);
    }
  }

  getCasValues(composition){
    if (composition.type === "name_only") {
      return composition.cas_name;
    } else {
      const casNumbers = this.getCasNumbers(composition);
      return this.getCasMasterNames(casNumbers).join(', ');
    }
  }

  getCasTooltip(composition) {
    if (composition.type == "cas_number")
      return this.getCasNumbers(composition).join(', ');
  }

  getCasNumbers(composition) {
    return composition.cas_number.map(n => n.cas);
  }

  getCasMasterNames(casNumbers) {
    return casNumbers.map(n => this.casMasterNames[n] || `unknown (${n})`);
  }

  getPercentValues(composition){
    switch(composition.percent_range){
      case 'single_number':
        if (composition.symbol == 'no_symbol') {
          return composition.percent;
        }
        else {
          return composition.symbol + ' ' + composition.percent;
        }
      case 'range_given':
        return composition.symbol_min + ' ' + composition.percent_min + ' ' + composition.symbol_max + ' ' + composition.percent_max

      case 'not_given':
      case 'not_found':
        return '';
    }
  }


  getBatteryKeys(info){
    if (!info) { return; }

    switch(info.toLowerCase()){
      case 'type':
        return 'Type'

      case 'wattrating':
        return 'Watt-Hr Rating'

      case 'cellcount':
        return 'Number of Cells'

      case 'lithiumcontent':
        return 'Li Content (grams)'

      case 'shippinginfo':
        return 'Shipping Info'

      case 'powersvehicle':
        return 'Powers Vehicle?'
    }
  }

  getBatteryValuesFormatted(info){
    if (!info) { return; }

    switch(info.toLowerCase()){
      case 'nickel_metal_hydride':
        return 'Ni-MH'

      default:
        let value_no_underscores = info.replace(/_/g,' ')
        return value_no_underscores[0].toUpperCase() + value_no_underscores.substr(1).toLowerCase()
    }
  }

  batteryInfoAllNull(info){
    let allNull = true;

    for (const property in info) {
      if (info[property] !== null) {
        allNull = false;
        return allNull;
      }
    }

    return allNull;
  }

  public keepOriginalOrder = (a, b) => a.key
}
