export const NotRegulated = {id: "not_regulated", name: "Not Regulated"};
export const NotApplicable = {id: "not_applicable", name: "Not Applicable"};
export const Indeterminable = {id: "indeterminable", name: "Indeterminable"};
export const None = {id: "none", name: "None"};
export const Forbidden = {id: "forbidden", name: "Forbidden"};
export const EnterValue = {id: "enter_value", name: "Enter Value"};

// NOTE: CodeObjects are just codes loaded from the backend config files (ie: waste_codes.yml & transportation.yml)
// converted to the ng-select dropdown format of {id: name:}, where id is the stored value that is communicated to the
// backend (ie: 'UN1950') and name is the displayed value in the dropdown (ie: 'UN1950: AEROSOLS, FLAMMABLE, N.O.S.')
export interface CodeObject {
  id: string;
  name: string | null
};

export interface Product {
  // mirror of product attributes containing duplicate code objects (based on id & name)
  duplicate_code_objects?: Product;

  original_result?: object;

  // for tracking changes in classification editor tasks
  original_data?: object;
  original_data_diffs: object;
  original_classifier?: string;
  is_classification_editor_task?: boolean;

  id: string
  sds_url?: string;
  upc: string;
  item_type?: string;
  product_name: string;
  quantity?: number;  // may be incorrect (not a number?)

  transportation: object;
  waste: object;
  other: object;

  children?: Product[];

  skip_expert_classification?: boolean;  // read-only flag

  // metadata
  portal_id?: number;
  supplier_name?: string;

  // TODO: add genome facets (centralize all data), ie: form, outer/inner packaging, etc...
};

// NOTE: DefaultProduct, DefaultWaste, & DefaultTransportation are used to hold both default values & determine the
// expert-state structure (nesting & attributes). The structure is used when normalizing the incoming result & when
// validating required / missing fields.

export function DefaultProduct() {
  return {
    id: "",
    upc: "",
    product_name: "",
    skip_expert_classification: false,
    waste: DefaultWaste(),
    transportation: DefaultTransportation(),
    other: DefaultOther(),
    original_data_diffs: {}
  };
}

// This is used as the default waste state and for validating codes when loading new products
export function DefaultWaste() {
  return {
    ca: "",
    ct: "",

    il: {
      waste: "",
      class: ""
    },

    ma: [],
    me: [],
    md: None.id,
    mi: "",
    mn: "",
    mo: None.id,
    nh: "",
    nj: None.id,
    ny: None.id,
    or: [],
    pa: "",
    ri: [],

    tx: {
        waste: "",
        class: ""
    },

    vt: [],

    wa: {
        wt: "",
        wp: [],
        ws: "",
        wpcb: ""
    },

    rcra: {
      d_characteristic: [],
      d_characteristic_toxics: [],
      p: "",
      u: "",
      rcra_exceptions: [None.id]
    },

    universal: None.id,

    e_waste: "False"
  };
}

export function DefaultTransportation() {
  const sharedValues = {
    description: "",
    un_number: "",
    technical_name: [],
    hazard_class_division: NotRegulated.id,
    subrisk: [None.id],
    proper_shipping_name: "",
    packing_group: NotApplicable.id,
    compatibility_group: NotApplicable.id,

    select_proper_shipping_name: EnterValue.id,
    select_technical_name: EnterValue.id
  };

  const nonCoreValues = {
    excepted_quantity: "No",
    reportable_quantity_lbs: NotApplicable.id,
    limited_quantity: "No",
    transportation_exception: [None.id]
  };

  return {
    core: {
      ...sharedValues,
      marine_pollutant: "False"
    },

    ground: {
      ...sharedValues,
      ...nonCoreValues
    },

    air: {
      ...sharedValues,
      ...nonCoreValues
    },

    sea: {
      ...sharedValues,
      ...nonCoreValues
    }
  };
}

export function DefaultOther() {
  return {
    nfpa: {
      // number controls 0-4
      nfpa_health: "",
      nfpa_fire: "",
      nfpa_instability: "",

      nfpa_special: [],
    },

    fifra_25b: "",
    epa_registration_number: "",
    npk_ratio: ""
  };
}
