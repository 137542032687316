import { Injectable } from '@angular/core';
import { UnFilters } from '../models/un_filter.model';
import { ClassificationEditorService } from './classification-editor.service';
import { PsnService } from 'src/app/shared/services/psn/psn.service'
import { Product, DefaultProduct, DefaultTransportation, CodeObject, None, Indeterminable, NotApplicable,
         NotRegulated, EnterValue, Forbidden } from '../models/product.model';
import _ from "lodash";

@Injectable({ providedIn: "root" })
export class ExpertStateService {
  private static defaultProduct = DefaultProduct();
  private static defaultTransportation = DefaultTransportation();

  private static transportModes = [
    "core",
    "ground",
    "air",
    "sea"
  ];

  private static restrictNotApplicableControls = [
    "limited_quantity",
    "excepted_quantity"
  ];

  private static fieldsToNormalize = [
    "transportation",
    "waste",
    "other"
  ];

  private static textEntryControls = [
    "description",
    "proper_shipping_name",
    "technical_name",
    "epa_registration_number",
    "npk_ratio"
  ];

  private static numberControls = [
    "hazard_class_division",
    "nfpa_health",
    "nfpa_fire",
    "nfpa_instability",
    "reportable_quantity_lbs"
  ];

  private static basicDescriptionFields = [
    "un_number",
    "proper_shipping_name",
    "technical_name",
    "hazard_class_division",
    "compatibility_group",
    "subrisk",
    "packing_group"
  ];

  static normalizeIncomingProduct(codes, product: Product) {
    const isClassificationEditorTask = product.is_classification_editor_task;
    const isFirstClassification = product.original_result === undefined;
    const isReadOnly = product.skip_expert_classification;

    if (isFirstClassification) {
      // Store the incoming result
      product.original_result = {}
      for (const key in product) {
        if (key !== "children")
          product.original_result[key] = _.cloneDeep(product[key])
      }

      // Set up empty duplicate code object product
      product.duplicate_code_objects = DefaultProduct();
    }

    for (const field of this.fieldsToNormalize)
      product[field] = this.normalizeFromCodes(codes, this.defaultProduct, product, [field]);

    // Initialize transportation values
    for (const transportMode of this.transportModes)
      this.updateTransportation(codes, product, transportMode);

    // Replace 'Please Select' with none or not applicable for read-only items & validation / override tasks (since
    // we can assume any nulls are equivilent to none / not applicable in those cases)
    if (isReadOnly || isClassificationEditorTask)
      this.fillInvalidNulls(product);

    for (const transportMode of this.transportModes)
      this.populateSelectsFromValues(product.transportation, transportMode);

    this.updateLimitedQuantityFormulatedDefault(product);

    if (isClassificationEditorTask && isFirstClassification) {
      // Original result data is stored (after initial default UI values are set) so validation / override tasks
      // can track changes made by the user
      product.original_data = {};
      product.original_data_diffs = {};
      ClassificationEditorService.getTrackedFields().forEach(key => {
        product.original_data[key] = _.cloneDeep(product[key]);
      });
    }

    for (const child of (product.children || [])) {
      child.is_classification_editor_task = product.is_classification_editor_task;
      this.normalizeIncomingProduct(codes, child);
    }
  }

  static updateTransportation(codes: object, product: Product, transportMode: string, path: Array<any> = null,
                              changedValue: CodeObject | CodeObject[] = null) {
    const transportation = product.transportation;
    const transportSection = transportation[transportMode];

    // NOTE: If changedField is null then the product is being normalized (rather than being called from the setCode
    // action w/ a specific field changed via the UI)
    const changedField = path ? path[path.length - 1] : null;

    // Don't overwrite incoming populated PSNs from backend (or old tasks)
    if ((!changedField && !transportSection["proper_shipping_name"]) || changedField === "un_number") {
      this.populateProperShippingName(codes, transportation, transportMode, changedValue);
    }

    if (!changedField || changedField === "un_number") {
      const un_number = transportSection["un_number"];
      this.deselectInvalidTransportationCodes(transportation, transportMode);

      // Ignore un_filter during normalization unless UN is not_regulated (since that's the only code ACE will throw
      // for non-reg sections, we need to fill the rest).
      if (changedField || un_number === NotRegulated.id) {
        this.resetNotApplicableTechnicalNames(codes, transportation, transportMode, un_number);
        this.populateUnFilter(transportation, transportMode, un_number);
        this.updateMarinePollutantFormulatedDefault(product, transportMode, un_number);
      }
    }

    if (!changedField || this.basicDescriptionFields.includes(changedField)) {
      this.generateBasicDescription(transportation, transportMode);
    }
  }

  // NOTE: marine_pollutant = "Not Applicable" when:
  // - for a child item where item_type != "formulated_product" and UN Number == "Not Regulated"
  // - for a parent item where all children != "formulated_product" and the parent's UN_Number == "Not Regulated"
  private static updateMarinePollutantFormulatedDefault(product: Product, transportMode: string, un_number: string) {
    if (transportMode !== "core") return;

    const isParent = product.children && product.children.length > 0;
    const isNonReg = un_number === NotRegulated.id;
    const isFormulated = product.item_type === "formulated_product";

    let setNotApplicable = false;

    if (isParent) {
      setNotApplicable = isNonReg;
      for (const child of product.children) {
        setNotApplicable = !this.updateMarinePollutantFormulatedDefault(child, transportMode, un_number) && setNotApplicable;
      }
    } else if (!isFormulated && isNonReg) {
      setNotApplicable = true;
    }

    if (setNotApplicable) {
      product.transportation[transportMode]["marine_pollutant"] = NotApplicable.id;
    }

    return isFormulated;
  }

  // NOTE: limited_quantity = "Yes" when:
  // - item_type = "formulated_product"
  // - the original limited_quantity == null
  // - the original basic_description != null
  private static updateLimitedQuantityFormulatedDefault(product: Product) {
    if (product.item_type !== "formulated_product") return;

    for (const transportMode of this.transportModes.filter(v => v !== "core")) {
      const originalLimitedQuantity = product.original_result["transportation"][transportMode]["limited_quantity"];
      const originalBasicDescription = product.original_result["transportation"][transportMode]["description"];
      if (originalBasicDescription && !originalLimitedQuantity)
        product.transportation[transportMode]["limited_quantity"] = "Yes";
    }
  }

  static replicateTransportationCore(transportation: object) {
    const coreUn = transportation["core"]["un_number"];
    const nonCoreTransportModes = this.transportModes.filter(v => v !== "core");
    nonCoreTransportModes.forEach(transportMode => {
      transportation[transportMode] = { ...this.defaultTransportation[transportMode] };
      this.populateUnFilter(transportation, transportMode, coreUn);
      for (const control in this.defaultTransportation["core"]) {
        if (transportation[transportMode][control] !== undefined) {
          transportation[transportMode][control] = transportation["core"][control];
        }
      }
    });
  }

  static setAllToNone(obj: object, ignoreIfPresent: boolean = false) {
    for (const key in obj) {
      const value = obj[key];
      const defaultValue = key === "e_waste" ? "False" : None.id;
      const isPresent = typeof(value) === "number" || (value && Object.keys(value).length > 0);
      if (value && value.constructor === Object) {
        this.setAllToNone(value, ignoreIfPresent);
      } else if (!(ignoreIfPresent && isPresent)) {
        obj[key] = Array.isArray(value) ? [defaultValue] : defaultValue;
      }
    }
  }

  static setNullToInvalidNullDefault(obj: object) {
    const invalidNullDefaults = UnFilters.invalid_null;
    for (const key in obj) {
      const value = obj[key];
      const defaultValue = invalidNullDefaults[key];
      const isPresent = typeof(value) === "number" || (value && Object.keys(value).length > 0);
      if (value && value.constructor === Object) {
        this.setNullToInvalidNullDefault(value);
      } else if (!isPresent && defaultValue !== undefined) {
        obj[key] = defaultValue;
      }
    }
  }

  static fillInvalidNulls(product: Product) {
    this.setAllToNone(product.waste, true);
    for (const transportMode in product.transportation)
      this.setNullToInvalidNullDefault(product.transportation[transportMode]);
  }

  static getTransportModeFromPath(path: Array<any>) {
    for (const i in this.transportModes) {
      const transportMode = this.transportModes[i];
      if (path.includes(transportMode)) { return transportMode; }
    }
  }

  // Extract IDs from {id: name:} formatted code objects
  static getIdFromCodeObject(codeObject: CodeObject | CodeObject[] | object) {
    if (this.isCodeObject(codeObject)) { return codeObject['id']; }

    if (Array.isArray(codeObject)) {
      let ids = [];
      codeObject.forEach(code => {
        ids.push(code.id);
      });
      return ids;
    } else if (codeObject && typeof(codeObject) === "object") {
      let idsObject = {};
      for (const key in codeObject) {
        idsObject[key] = this.getIdFromCodeObject(codeObject);
      }
      return idsObject;
    }

    return codeObject;
  }

  // If 2 or more code objects share an ID in a dropdown, then store the selected code object for selectors to return
  static storeDuplicateCodeObject(codes: object, product: Product, path: Array<any>, value: any) {
    if (!value || this.isTextEntry(path[path.length - 1])) { return; }

    codes = this.getCodes(codes, path);
    if (!Array.isArray(codes)) { return; }

    let duplicateValues = _.get(this.defaultProduct, path);

    if (Array.isArray(value)) {
      let duplicateObjectArray = [];
      for (const i in value) {
        if (this.isDuplicateCode(codes, value[i])) {
          duplicateObjectArray[i] = value[i];
        }
      }
      duplicateValues = duplicateObjectArray;
    } else if (this.isCodeObject(value) && this.isDuplicateCode(codes, value)) {
      duplicateValues = value;
    }

    _.set(product.duplicate_code_objects, path, duplicateValues);
  }

  // For matching codes by ID that aren't really codes, but more like descriptors (ie: 'Indeterminable')
  static getNonCodeFromId(value: string | number) {
    if (!value) { return; }

    const codes = [None, Indeterminable, NotRegulated, NotApplicable, Forbidden];
    return codes.find(code => code.id === value)
  }

  // Fetch the duplicate selected codes from the mirrored attributes on the 'duplicate_code_objects' (if a duplicate code
  // was selected) and merge with the current selected code(s)
  static getDuplicateCodeObject(product: Product, selectedCode: object, path: Array<any>) {
    const duplicateCodeObjects = product.duplicate_code_objects;
    if (!duplicateCodeObjects) { return selectedCode; }

    const duplicateCodeObject = _.get(duplicateCodeObjects, path);
    if (!duplicateCodeObject) { return selectedCode; }

    // Duplicate single selects
    if (this.isCodeObject(duplicateCodeObject) && duplicateCodeObject["id"] === selectedCode["id"]) {
      return duplicateCodeObject;

    // Duplicate multi selects
    } else if (Array.isArray(duplicateCodeObject)) {
      let newSelectedCodeArray = [];
      for (const i in selectedCode) {
        const duplicateCode = duplicateCodeObject[i];
        newSelectedCodeArray[i] = (duplicateCode && duplicateCode.id === selectedCode[i].id && duplicateCode) || selectedCode[i];
      }
      return newSelectedCodeArray

    // Duplicate nested objects (ie: rcra)
    } else if (typeof(duplicateCodeObject) === "object") {
      let newObject = {};
      for (const key in selectedCode) {
        newObject[key] = this.getDuplicateCodeObject(product, selectedCode[key], [...path, key]);
      }
      return newObject;
    }

    return selectedCode;
  }

  // Use the "Codes" hash loaded from the backend to retrieve {id: name:} code objects from their ids
  static getCodeObjectFromId(codes: object, path: any[], ids: any) {
    const relevantCodes = this.getCodes(codes, path);

    if (typeof(ids) === "string" || typeof(ids) === "number") {
      return this.matchCodeObjectFromArray(relevantCodes, ids)

    } else if (Array.isArray(ids)) {
      let newArray = [];
      ids.forEach(id => {
        const subCode = this.matchCodeObjectFromArray(relevantCodes, id)
        if (subCode) { newArray.push(subCode) };
      })
      return newArray;

    } else if (typeof(ids) === "object") {
      let newObject = {};
      for (const key in ids) {
        newObject[key] = this.getCodeObjectFromId(codes, [...path, key], ids[key]);
      }
      return newObject;
    }
  }

  // Loop through product & its children until an id matches
  // TODO: remove this function from the expert-classification-container component (it's called findChildById)
  static findProductById(product: any, id: string) {
    if (product.id === id) {
      return product;
    } else if (product.children) {
      for (const i in product.children) {
        const child = this.findProductById(product.children[i], id);
        if (child) { return child; }
      }
    }
  }

  static isTextEntry(control: string) {
    return this.textEntryControls.includes(control);
  }

  // Try to lookup transportation codes in 'shared'
  static getCodes(codes: object, path: string | number | (string | number)[]=[]) {
    if (!Array.isArray(path)) { path = [path]; }

    let sharedResult;
    if (path.includes("transportation")) {
      const transportMode = this.getTransportModeFromPath(path);
      if (transportMode) {
        let sharedPath = [...path];
        sharedPath[sharedPath.indexOf(transportMode)] = "shared";
        sharedResult = _.get(codes, sharedPath);
      }
    }

    return sharedResult ? sharedResult : _.get(codes, path);
  }

  // Will return true if a code is not the first occurence in a dropdown
  private static isDuplicateCode(codeArray: CodeObject[], codeObject: CodeObject) {
    let matches = 0;
    for (const i in codeArray) {
      if (codeArray[i].id === codeObject.id) {
        if (codeObject.name === codeArray[i].name) {
          if (matches >= 1) { return true; }
          return;
        }
        matches++;
      }
    }
  }

  // Pre-populate all technical_names with 'Not Applicable' unless UN allows it
  private static resetNotApplicableTechnicalNames(codes, transportation, transportMode, un_number) {
    un_number = this.isCodeObject(un_number) ? un_number.id : un_number;
    const whitelist = this.getCodes(codes, ["transportation", "technical_name_whitelist"]);
    if (!un_number || !whitelist) { return; }

    const oldTechnicalNameArray = transportation[transportMode]["technical_name"];
    const oldFirstTechnicalName = Array.isArray(oldTechnicalNameArray) ? oldTechnicalNameArray[0] : null;

    let newValue = [NotApplicable.id];
    let newSelectValue = NotApplicable.id;

    // Keep old technical names if old UN allowed them, otherwise reset to empty array for new names to be entered
    if (whitelist.includes(un_number)) {
      newValue = this.getNonCodeFromId(oldFirstTechnicalName) ? [] : oldTechnicalNameArray;
      newSelectValue = EnterValue.id;
    }

    transportation[transportMode]["technical_name"] = newValue;
    transportation[transportMode]["select_technical_name"] = newSelectValue;
  }

  private static deselectInvalidTransportationCodes(transportation: object, transportMode: string) {
    if (transportation[transportMode]["un_number"] === NotRegulated.id) { return; }

    const propertiesToUpdate = Object.keys(this.defaultTransportation[transportMode]).filter(key =>
      key !== "un_number"
    );

    propertiesToUpdate.forEach(property => {
      this.deselectInvalidCodeOnProperty(transportation, transportMode, property);
    })
  }

  private static deselectInvalidCodeOnProperty(transportation: object, transportMode: string, property: string) {
    const disabledCodes = this.restrictNotApplicableControls.includes(property) ? [ NotApplicable.id ] : [ NotRegulated.id ];
    const selectedCode = transportation[transportMode][property];

    if (Array.isArray(selectedCode)) {
      if (!selectedCode.find(v => disabledCodes.includes(v))) { return; }
    } else if (!disabledCodes.includes(selectedCode)) {
      return;
    }

    // Deselect by setting control to default value
    transportation[transportMode][property] = this.defaultTransportation[transportMode][property];
  }

  private static normalizeFromCodes(codes: object, defaultProduct: Product, incomingProduct: Product, path: any[]) {
    const defaultValue = _.get(defaultProduct, path);
    const incomingValue = _.get(incomingProduct, path)

    const typeofIncoming = typeof(incomingValue);
    const typeofDefault = typeof(defaultValue);

    // Use default on null / undefined / empty incoming values
    if (typeofIncoming !== "number" && (!incomingValue || (incomingValue && !Object.keys(incomingValue).length))) {
      return defaultValue;
    }

    const lastPath = path[path.length - 1];
    const isTextEntryControl = this.textEntryControls.includes(lastPath);
    const isNumberControl = this.numberControls.includes(lastPath);

    // If types do not match then set to default value or convert to array (if string or number)
    if (typeofDefault !== typeofIncoming && !(isNumberControl && typeofIncoming === "number")) {
      if (Array.isArray(defaultValue) && (["string", "number"].includes(typeofIncoming))) {
        return [incomingValue];
      }
      return defaultValue;
    }

    // Normalize single select codes
    if (typeofDefault === "string" || isNumberControl) {
      // Remove values that aren't defined as codes in the backend (except for text entry values)
      if (!isTextEntryControl && !this.matchCodeObjectFromArray(this.getCodes(codes, path), incomingValue)) {
        return defaultValue;
      }
      return incomingValue;
    }

    // Normalize multi select controls
    if (Array.isArray(defaultValue)) {
      let normalizedArray = [];
      incomingValue.forEach(value => {
        if (typeof(value) === "string" || typeof(value) === "number") {
          if (isTextEntryControl || this.matchCodeObjectFromArray(this.getCodes(codes, path), value)) {
            normalizedArray.push(value);
          }
        }
      });
      return normalizedArray;
    }

    // Normalize nested controls (ie: rcra or wa)
    if (typeofDefault === "object") {
      let normalizedObject = {};
      for (const key in defaultValue) {
        normalizedObject[key] = this.normalizeFromCodes(codes, defaultProduct, incomingProduct, [...path, key]);
      }
      return normalizedObject
    }
    return defaultValue;
  }

  // Since 'select_' controls don't exist on the backend we need to initialize them here from their text entry values
  private static populateSelectsFromValues(transportation: object, transportMode: string) {
    ["proper_shipping_name", "technical_name"].forEach(control => {
      let incomingValue = transportation[transportMode][control];

      // Set technical name 'select_' values off of the first item in the incoming array
      if (Array.isArray(incomingValue)) { incomingValue = incomingValue[0]; }

      const nonCode = this.getNonCodeFromId(incomingValue);
      transportation[transportMode][`select_${control}`] = nonCode ? nonCode.id : EnterValue.id;
    });
  }

  private static populateProperShippingName(codes, transportation, transportMode, unCodeObject = null) {
    const transportSection = transportation[transportMode];

    if (!unCodeObject) {
      const unNumber = transportSection["un_number"];
      if (unNumber) {
        unCodeObject = this.getCodeObjectFromId(codes, ["transportation", transportMode, "un_number"], unNumber);
      }
    }

    const psn = PsnService.getPsnFromUnCode(unCodeObject);
    if (psn) {
      transportSection["select_proper_shipping_name"] = "enter_value";
      transportSection["proper_shipping_name"] = psn;
    }
  }

  private static populateUnFilter(transportation, transportMode, un_number, subFilter = null) {
    if (this.isCodeObject(un_number)) { un_number = un_number.id; }
    if (!un_number || !UnFilters[un_number]) { return; }

    const unFilter = subFilter ? subFilter : UnFilters[un_number];

    for (const key in unFilter) {
      if (this.transportModes.includes(key)) {
        if (key === transportMode) { this.populateUnFilter(transportation, transportMode, un_number, unFilter[key]); }
      } else if (this.defaultTransportation[transportMode][key] !== undefined) {
        transportation[transportMode][key] = unFilter[key];
      }
    }
  }

  private static generateBasicDescription(transportation, transportMode) {
    const fields = this.basicDescriptionFields;

    let newDescription = "";
    const transportSection = transportation[transportMode];
    const unNumber = transportSection["un_number"];
    const nonCodeUn = this.getNonCodeFromId(unNumber);

    // If UN number is Not Regulated / Indeterminable, we just use that as the description (and blank UN gets ignored)
    if (!unNumber || nonCodeUn) {
      newDescription = nonCodeUn ? nonCodeUn.name : null;
    } else {
      let descriptionFieldValues = {};
      for (const i in fields) {
        const field = fields[i];
        descriptionFieldValues[field] = transportSection[field];
      }
      newDescription = this.formatBasicDescription(descriptionFieldValues);
    }

    if (newDescription) {
      transportSection["description"] = newDescription;
    }
  }

  private static formatBasicDescription(descriptionFieldValues: Object) {
    let hasHazardClass = false;

    let formatted = [];
    for (const field in descriptionFieldValues) {
      let value = descriptionFieldValues[field];

      // Skip compatibility group if there is no hazard class
      if (typeof(value) === "number") {
        value = value.toString();
      } else if (!value || !Object.keys(value).length || this.getNonCodeFromId(value)
                 || (field === "compatibility_group" && !hasHazardClass)) {
        continue;
      }

      // Format technical_name / subrisk with parenthesis
      if (field === "technical_name" || field === "subrisk") {
        value = this.formatBasicDescription(value);
        if (value) { value = `(${value})`; }
      }

      if (value) {
        if (field === "hazard_class_division") { hasHazardClass = true; }
        if (field === "compatibility_group") {
          formatted[formatted.length - 1] += value;
        } else {
          formatted.push(value);
        }
      }
    }

    return formatted.join(", ");
  }

  private static matchCodeObjectFromArray(codes: CodeObject[], id: string | number) {
    for (const i in codes) {
      const codeObject = codes[i];
      if (codeObject.id === id) { return codeObject; }
    }
  }

  private static isCodeObject(v: any) {
    return v && typeof(v) === "object" && Object.keys(v).length === 2 && v.id !== undefined && v.name !== undefined;
  }
}
