import { Product, CodeObject } from './models/product.model'

export class LoadCodes {
    static readonly type = '[Expert] LoadCodes';
    constructor(public waste: object, public transportation: object, public other: object) { }
}

export class LoadProduct {
    static readonly type = '[Expert] LoadProduct';
    constructor(public task_id: number, public product: Product) { }
}

export class SetCode {
    static readonly type = '[Expert] SetCode';
    constructor(public value: CodeObject | CodeObject[], public path: (string | number)[]) { }
}

export class ChangeProduct {
    static readonly type = '[Expert] ChangeProduct';
    constructor(public id: string) { }
}

// Waste

export class SetAllWasteToNone {
    static readonly type = '[Expert] SetAllWasteToNone';
}

// Transportation

export class ReplicateTransportationCore {
    static readonly type = '[Expert] ReplicateTransportationCore';
}
