import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { DropdownConfig } from 'src/app/shared/interface';
import { HazardService } from 'src/app/shared/services/hazard/hazard.service';
import { DropdownLabelsDeprecated } from '../../../shared/enum';

@Component({
  selector: 'app-hazard',
  templateUrl: './hazard.component.html',
  styleUrls: ['./hazard.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HazardComponent extends BaseComponent implements OnInit {
  public form: FormGroup;

  // Dropdown config for Classes of all three dot, iata and imdg
  public dotDropdownConfigClass: DropdownConfig;
  public iataDropdownConfigClass: DropdownConfig;
  public imdgDropdownConfigClass: DropdownConfig;

  // Dropdown config for divisions of all three dot, iata and imdg
  public dotDropdownConfigDivision: DropdownConfig;
  public iataDropdownConfigDivision: DropdownConfig;
  public imdgDropdownConfigDivision: DropdownConfig;

  // Dropdown config for Compatibility of all three dot, iata and imdg
  public dotDropDownConfigCompatibility: DropdownConfig;
  public iataDropDownConfigCompatibility: DropdownConfig;
  public imdgDropDownConfigCompatibility: DropdownConfig;

  // Dropdown config for PackingGroup of all three dot, iata and imdg
  public dotDropDownConfigPackingGroup: DropdownConfig;
  public iataDropDownConfigPackingGroup: DropdownConfig;
  public imdgDropDownConfigPackingGroup: DropdownConfig;

  // Dropdown config for sub Classes of all three dot, iata and imdg
  public dotDropdownConfigSubClass: DropdownConfig;
  public iataDropdownConfigSubClass: DropdownConfig;
  public imdgDropdownConfigSubClass: DropdownConfig;

  // Dropdown config for sub divisions of all three dot, iata and imdg
  public dotDropdownConfigSubDivision: DropdownConfig;
  public iataDropdownConfigSubDivision: DropdownConfig;
  public imdgDropdownConfigSubDivision: DropdownConfig;

  // Form names
  public formNames = {
    dot_hazard_class: 'dot_hazard_class',
    dot_hazard_division: 'dot_hazard_division',
    dot_hazard_compatibility: 'dot_hazard_compatibility',
    dot_hazard_packing_group: 'dot_hazard_packing_group',
    dot_subhazard_class: 'dot_subhazard_class',
    dot_subhazard_division: 'dot_subhazard_division',
    iata_hazard_class: 'iata_hazard_class',
    iata_hazard_division: 'iata_hazard_division',
    iata_hazard_compatibility: 'iata_hazard_compatibility',
    iata_hazard_packing_group: 'iata_hazard_packing_group',
    iata_subhazard_class: 'iata_subhazard_class',
    iata_subhazard_division: 'iata_subhazard_division',
    imdg_hazard_class: 'imdg_hazard_class',
    imdg_hazard_division: 'imdg_hazard_division',
    imdg_hazard_compatibility: 'imdg_hazard_compatibility',
    imdg_hazard_packing_group: 'imdg_hazard_packing_group',
    imdg_subhazard_class: 'imdg_subhazard_class',
    imdg_subhazard_division: 'imdg_subhazard_division'
  };

  public dotChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public iataChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public imdgChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public dotDivisionChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public iataDivisionChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public imdgDivisionChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public dotCompatChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public iataCompatChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public imdgCompatChangeEvent: EventEmitter<boolean> = new EventEmitter();

  public dotSubHazardChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public iataSubHazardChangeEvent: EventEmitter<boolean> = new EventEmitter();
  public imdgSubHazardChangeEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    public activeRoute: ActivatedRoute,
    public taskService: TaskService,
    public requestService: RequestService,
    public hazard: HazardService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      dot_hazard_class: ['', Validators.required],
      dot_subhazard_class: ['', Validators.required],
      iata_hazard_class: ['', Validators.required],
      iata_subhazard_class: ['', Validators.required],
      imdg_hazard_class: ['', Validators.required],
      imdg_subhazard_class: ['', Validators.required],
    });

    // Classes value storing
    this.dotDropdownConfigClass = {
      label: DropdownLabelsDeprecated.dotLabelClass,
      dropdownOption: this.hazard.classes,
      placeHolder: this.hazard.placeHolder
    };
    this.iataDropdownConfigClass = {
      label: DropdownLabelsDeprecated.iataLabelClass,
      dropdownOption: this.hazard.classes,
      placeHolder: this.hazard.placeHolder
    };
    this.imdgDropdownConfigClass = {
      label: DropdownLabelsDeprecated.imdgLabelClass,
      dropdownOption: this.hazard.classes,
      placeHolder: this.hazard.placeHolder
    };

    this.dotDropdownConfigSubClass = {
      label: DropdownLabelsDeprecated.dotLabelSubClass,
      dropdownOption: this.hazard.subClasses,
      placeHolder: this.hazard.placeHolder
    };
    this.iataDropdownConfigSubClass = {
      label: DropdownLabelsDeprecated.iataLabelSubClass,
      dropdownOption: this.hazard.subClasses,
      placeHolder: this.hazard.placeHolder
    };
    this.imdgDropdownConfigSubClass = {
      label: DropdownLabelsDeprecated.imdgLabelSubClass,
      dropdownOption: this.hazard.subClasses,
      placeHolder: this.hazard.placeHolder
    };
    this.dotDropDownConfigPackingGroup = {
      label: DropdownLabelsDeprecated.dotLabelPackingGroup,
      dropdownOption: this.hazard.packingGroup,
      placeHolder: this.hazard.placeHolder
    };
    this.iataDropDownConfigPackingGroup = {
      label: DropdownLabelsDeprecated.iataLabelPackingGroup,
      dropdownOption: this.hazard.packingGroup,
      placeHolder: this.hazard.placeHolder
    };
    this.imdgDropDownConfigPackingGroup = {
      label: DropdownLabelsDeprecated.imdgLabelPackingGroup,
      dropdownOption: this.hazard.packingGroup,
      placeHolder: this.hazard.placeHolder
    };
  }

  receiveHazardDropdownOption(option) {
    if (option[0] === this.formNames.dot_hazard_class) {
      const dotDropdown = 'dot';
      this.dotChangeEvent.emit(true);
      this.dotDivisionChangeEvent.emit(true);
      this.dotCompatChangeEvent.emit(true);
      if (this.form.controls[this.formNames.dot_hazard_division]) {
        this.form.controls[this.formNames.dot_hazard_division].setValue('');
      }
      if (this.form.controls[this.formNames.dot_hazard_compatibility]) {
        this.form.controls[this.formNames.dot_hazard_compatibility].setValue('');
      }
      if (this.form.controls[this.formNames.dot_hazard_packing_group]) {
        this.form.controls[this.formNames.dot_hazard_packing_group].reset('');
      }

      switch (option[1]) {
        case '1':
          this.form.addControl('dot_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('dot_hazard_compatibility', new FormControl('', Validators.required));
          this.form.addControl('dot_hazard_packing_group', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.explosivesDivision, dotDropdown);
          this.dotDropDownConfigCompatibility = {
            label: DropdownLabelsDeprecated.dotLabelCompatibility,
            dropdownOption: this.hazard.compatibility,
            placeHolder: this.hazard.placeHolder
          };
          break;

        case '2':
          this.form.addControl('dot_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.gassesDivision, dotDropdown);
          this.form.removeControl('dot_hazard_compatibility');
          this.form.removeControl('dot_hazard_packing_group');
          break;

        case '4':
          this.form.addControl('dot_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('dot_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('dot_hazard_compatibility');
          this.addDropdownConfig(this.hazard.flammableSolidsDivision, dotDropdown);
          break;

        case '5':
          this.form.addControl('dot_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('dot_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('dot_hazard_compatibility');
          this.addDropdownConfig(this.hazard.oxidizingDivision, dotDropdown);
          break;

        case '6':
          this.form.addControl('dot_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.toxicInfectiousDivision, dotDropdown);
          this.form.removeControl('dot_hazard_compatibility');
          this.form.removeControl('dot_hazard_packing_group');
          break;

        case '7': ;
          this.form.removeControl('dot_hazard_division');
          this.form.removeControl('dot_hazard_compatibility');
          this.form.removeControl('dot_hazard_packing_group');
          break;

        case 'Could Not Find':
          this.form.removeControl('dot_hazard_division');
          this.form.removeControl('dot_hazard_compatibility');
          this.form.removeControl('dot_hazard_packing_group');
          break;

        case '3':
        case '8':
        case '9':
        case 'Not Restricted':
          this.form.removeControl('dot_hazard_division');
          this.form.removeControl('dot_hazard_compatibility');
          this.form.addControl('dot_hazard_packing_group', new FormControl('', Validators.required));
          break;
      }
    } else if (option[0] === this.formNames.iata_hazard_class) {
      const iataDropdown = 'iata';
      this.iataChangeEvent.emit(true);
      this.iataDivisionChangeEvent.emit(true);
      this.iataCompatChangeEvent.emit(true);
      if (this.form.controls[this.formNames.iata_hazard_division]) {
        this.form.controls[this.formNames.iata_hazard_division].reset('');
      }
      if (this.form.controls[this.formNames.iata_hazard_compatibility]) {
        this.form.controls[this.formNames.iata_hazard_compatibility].reset('');
      }
      if (this.form.controls[this.formNames.iata_hazard_packing_group]) {
        this.form.controls[this.formNames.iata_hazard_packing_group].reset('');
      }
      switch (option[1]) {
        case '1':
          this.form.addControl('iata_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('iata_hazard_compatibility', new FormControl('', Validators.required));
          this.form.addControl('iata_hazard_packing_group', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.explosivesDivision, iataDropdown);
          this.iataDropDownConfigCompatibility = {
            label: DropdownLabelsDeprecated.iataLabelCompatibility,
            dropdownOption: this.hazard.compatibility,
            placeHolder: this.hazard.placeHolder
          };
          break;

        case '2':
          this.form.addControl('iata_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.gassesDivision, iataDropdown);
          this.form.removeControl('iata_hazard_compatibility');
          this.form.removeControl('iata_hazard_packing_group');
          break;

        case '4':
          this.form.addControl('iata_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('iata_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('iata_hazard_compatibility');
          this.addDropdownConfig(this.hazard.flammableSolidsDivision, iataDropdown);
          break;

        case '5':
          this.form.addControl('iata_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('iata_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('iata_hazard_compatibility');
          this.addDropdownConfig(this.hazard.oxidizingDivision, iataDropdown);
          break;

        case '6':
          this.form.addControl('iata_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.toxicInfectiousDivision, iataDropdown);
          this.form.removeControl('iata_hazard_compatibility');
          this.form.removeControl('iata_hazard_packing_group');
          break;

        case '7':
          this.form.removeControl('iata_hazard_division');
          this.form.removeControl('iata_hazard_compatibility');
          this.form.removeControl('iata_hazard_packing_group');
          break;

        case 'Could Not Find':
          this.form.removeControl('iata_hazard_division');
          this.form.removeControl('iata_hazard_compatibility');
          this.form.removeControl('iata_hazard_packing_group');
          break;

        case '3':
        case '8':
        case '9':
        case 'Not Restricted':
          this.form.removeControl('iata_hazard_division');
          this.form.removeControl('iata_hazard_compatibility');
          this.form.addControl('iata_hazard_packing_group', new FormControl('', Validators.required));
          break;
      }
    } else if (option[0] === this.formNames.imdg_hazard_class) {
      const imdgDropdown = 'imdg';
      this.imdgChangeEvent.emit(true);
      this.imdgDivisionChangeEvent.emit(true);
      this.imdgCompatChangeEvent.emit(true);
      if (this.form.controls[this.formNames.imdg_hazard_division]) {
        this.form.controls[this.formNames.imdg_hazard_division].reset('');
      }
      if (this.form.controls[this.formNames.imdg_hazard_compatibility]) {
        this.form.controls[this.formNames.imdg_hazard_compatibility].reset('');
      }
      if (this.form.controls[this.formNames.imdg_hazard_packing_group]) {
        this.form.controls[this.formNames.imdg_hazard_packing_group].reset('');
      }
      switch (option[1]) {
        case '1':
          this.form.addControl('imdg_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('imdg_hazard_compatibility', new FormControl('', Validators.required));
          this.form.addControl('imdg_hazard_packing_group', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.explosivesDivision, imdgDropdown);
          this.imdgDropDownConfigCompatibility = {
            label: DropdownLabelsDeprecated.imdgLabelCompatibility,
            dropdownOption: this.hazard.compatibility,
            placeHolder: this.hazard.placeHolder
          };
          break;

        case '2':
          this.form.addControl('imdg_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.gassesDivision, imdgDropdown);
          this.form.removeControl('imdg_hazard_compatibility');
          this.form.removeControl('imdg_hazard_packing_group');
          break;

        case '4':
          this.form.addControl('imdg_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('imdg_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('imdg_hazard_compatibility');
          this.addDropdownConfig(this.hazard.flammableSolidsDivision, imdgDropdown);
          break;

        case '5':
          this.form.addControl('imdg_hazard_division', new FormControl('', Validators.required));
          this.form.addControl('imdg_hazard_packing_group', new FormControl('', Validators.required));
          this.form.removeControl('imdg_hazard_compatibility');
          this.addDropdownConfig(this.hazard.oxidizingDivision, imdgDropdown);
          break;

        case '6':
          this.form.addControl('imdg_hazard_division', new FormControl('', Validators.required));
          this.addDropdownConfig(this.hazard.toxicInfectiousDivision, imdgDropdown);
          this.form.removeControl('imdg_hazard_compatibility');
          this.form.removeControl('imdg_hazard_packing_group');
          break;

        case '7':
          this.form.removeControl('imdg_hazard_division');
          this.form.removeControl('imdg_hazard_compatibility');
          this.form.removeControl('imdg_hazard_packing_group');
          break;

        case 'Could Not Find':
          this.form.removeControl('imdg_hazard_division');
          this.form.removeControl('imdg_hazard_compatibility');
          this.form.removeControl('imdg_hazard_packing_group');
          break;

        case '3':
        case '8':
        case '9':
        case 'Not Restricted':
          this.form.removeControl('imdg_hazard_division');
          this.form.removeControl('imdg_hazard_compatibility');
          this.form.addControl('imdg_hazard_packing_group', new FormControl('', Validators.required));
          break;
      }
    }
  }

  receiveDivisionOption(option) {
    if (option[0] === this.formNames.dot_hazard_division) {
      if (this.form.controls[this.formNames.dot_hazard_packing_group]) {
        this.form.controls[this.formNames.dot_hazard_packing_group].reset('');
      }
      this.dotDivisionChangeEvent.emit(true);
      this.dotCompatChangeEvent.emit(true);
      if (option[1] === this.hazard.toxicInfectiousDivision[1].key) {
        this.form.removeControl('dot_hazard_packing_group');
      } else if (option[1] === this.hazard.toxicInfectiousDivision[0].key) {
        this.form.addControl('dot_hazard_packing_group', new FormControl('', Validators.required));
      }
    } else if (option[0] === this.formNames.iata_hazard_division) {
      if (this.form.controls[this.formNames.iata_hazard_packing_group]) {
        this.form.controls[this.formNames.iata_hazard_packing_group].reset('');
      }
      this.iataDivisionChangeEvent.emit(true);
      this.iataCompatChangeEvent.emit(true);
      if (option[1] === this.hazard.toxicInfectiousDivision[1].key) {
        this.form.removeControl('iata_hazard_packing_group');
      } else if (option[1] === this.hazard.toxicInfectiousDivision[0].key) {
        this.form.addControl('iata_hazard_packing_group', new FormControl('', Validators.required));
      }
    } else if (option[0] === this.formNames.imdg_hazard_division) {
      if (this.form.controls[this.formNames.imdg_hazard_packing_group]) {
        this.form.controls[this.formNames.imdg_hazard_packing_group].reset('');
      }
      this.imdgDivisionChangeEvent.emit(true);
      this.imdgCompatChangeEvent.emit(true);
      if (option[1] === this.hazard.toxicInfectiousDivision[1].key) {
        this.form.removeControl('imdg_hazard_packing_group');
      } else if (option[1] === this.hazard.toxicInfectiousDivision[0].key) {
        this.form.addControl('imdg_hazard_packing_group', new FormControl('', Validators.required));
      }
    }
  }

  receiveCompatibilityOption(option) {
    if (option[0] === this.formNames.dot_hazard_compatibility) {
      if (this.form.controls[this.formNames.dot_hazard_packing_group]) {
        this.form.controls[this.formNames.dot_hazard_packing_group].reset('');
      }
      this.dotCompatChangeEvent.emit(true);
    } else if (option[0] === this.formNames.iata_hazard_compatibility) {
      if (this.form.controls[this.formNames.iata_hazard_packing_group]) {
        this.form.controls[this.formNames.iata_hazard_packing_group].reset('');
      }
      this.iataCompatChangeEvent.emit(true);
    } else if (option[0] === this.formNames.imdg_hazard_compatibility) {
      if (this.form.controls[this.formNames.imdg_hazard_packing_group]) {
        this.form.controls[this.formNames.imdg_hazard_packing_group].reset('');
      }
      this.imdgCompatChangeEvent.emit(true);
    }
  }

  receiveSubHazardOption(option) {
    if (option[0] === this.formNames.dot_subhazard_class) {
      const dotDropdown = 'dot';
      this.dotSubHazardChangeEvent.emit(true);
      if (this.form.controls[this.formNames.dot_subhazard_division]) {
        this.form.controls[this.formNames.dot_subhazard_division].reset('');
      }
      switch (option[1]) {
        case '1':
          this.form.removeControl('dot_subhazard_division');
          break;

        case '2':
          this.form.addControl('dot_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.gassesDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], dotDropdown);
          break;

        case '4':
          this.form.addControl('dot_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.flammableSolidsDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], dotDropdown);
          break;

        case '5':
          this.form.addControl('dot_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.oxidizingDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], dotDropdown);
          break;

        case '6':
          this.form.addControl('dot_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.toxicInfectiousDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], dotDropdown);
          break;

        case '7':
          this.form.removeControl('dot_subhazard_division');
          break;

        case '3':
        case '8':
        case 'Could Not Find':
        case 'Not Restricted':
        case 'None':
          this.form.removeControl('dot_subhazard_division');;
          break;
      }
    } else if (option[0] === this.formNames.iata_subhazard_class) {
      const iataDropdown = 'iata';
      this.iataSubHazardChangeEvent.emit(true);
      if (this.form.controls[this.formNames.iata_subhazard_division]) {
        this.form.controls[this.formNames.iata_subhazard_division].reset('');
      }
      switch (option[1]) {
        case '1':
          this.form.removeControl('iata_subhazard_division');
          break;

        case '2':
          this.form.addControl('iata_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.gassesDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], iataDropdown);
          break;

        case '4':
          this.form.addControl('iata_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.flammableSolidsDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], iataDropdown);
          break;

        case '5':
          this.form.addControl('iata_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.oxidizingDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], iataDropdown);
          break;

        case '6':
          this.form.addControl('iata_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.toxicInfectiousDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], iataDropdown);
          break;

        case '7':
          this.form.removeControl('iata_subhazard_division');
          break;

        case '3':
        case '8':
        case 'Could Not Find':
        case 'Not Restricted':
        case 'None':
          this.form.removeControl('iata_subhazard_division');
          break;
      }
    } else if (option[0] === this.formNames.imdg_subhazard_class) {
      const imdgDropdown = 'imdg';
      this.imdgSubHazardChangeEvent.emit(true);
      if (this.form.controls[this.formNames.imdg_subhazard_division]) {
        this.form.controls[this.formNames.imdg_subhazard_division].reset('');
      }
      switch (option[1]) {
        case '1':
          this.form.removeControl('imdg_subhazard_division');
          break;

        case '2':
          this.form.addControl('imdg_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.gassesDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], imdgDropdown);
          break;

        case '4':
          this.form.addControl('imdg_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.flammableSolidsDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], imdgDropdown);
          break;

        case '5':
          this.form.addControl('imdg_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.oxidizingDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], imdgDropdown);
          break;

        case '6':
          this.form.addControl('imdg_subhazard_division', new FormControl('', Validators.required));
          this.addSubhazardDropdownValues([this.hazard.toxicInfectiousDivision[0], this.hazard.couldNotFind[0], this.hazard.none[0]], imdgDropdown);
          break;

        case '7':
          this.form.removeControl('imdg_subhazard_division');
          break;

        case '3':
        case '8':
        case 'Could Not Find':
        case 'Not Restricted':
        case 'None':
          this.form.removeControl('imdg_subhazard_division');
          break;
      }
    }
  }

  private addDropdownConfig(options, dropdownType) {
    if (dropdownType === 'dot') {
      this.dotDropdownConfigDivision = {
        label: DropdownLabelsDeprecated.dotLabelDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    } else if (dropdownType === 'iata') {
      this.iataDropdownConfigDivision = {
        label: DropdownLabelsDeprecated.iataLabelDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    } else if (dropdownType === 'imdg') {
      this.imdgDropdownConfigDivision = {
        label: DropdownLabelsDeprecated.imdgLabelDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    }
  }

  private addSubhazardDropdownValues(options, dropdownType) {
    if (dropdownType === 'dot') {
      this.dotDropdownConfigSubDivision = {
        label: DropdownLabelsDeprecated.dotLabelSubDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    } else if (dropdownType === 'iata') {
      this.iataDropdownConfigSubDivision = {
        label: DropdownLabelsDeprecated.iataLabelSubDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    } else if (dropdownType === 'imdg') {
      this.imdgDropdownConfigSubDivision = {
        label: DropdownLabelsDeprecated.imdgLabelSubDivision,
        dropdownOption: options,
        placeHolder: this.hazard.placeHolder
      };
    }
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createHazard(this.form);
      this.post(request);
    }
  }
}
