import { Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { HazardService } from 'src/app/shared/services/hazard/hazard.service';
import { DropdownLabels } from '../../../shared/enum';
import { Subscription } from 'rxjs';
import { CodesService } from 'src/app/shared/services/codes/codes.service'

@Component({
  selector: 'app-iship',
  templateUrl: './iship.component.html',
  styleUrls: ['./iship.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class IshipComponent extends BaseComponent implements OnInit {
  public form: FormGroup;
  public dotSubscription: Subscription;
  public iataSubscription: Subscription;

  public dotDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };
  public imdgDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };
  public iataDropDownConfigs = {
    class: {},
    division: {},
    compatibility: {},
    packingGroup: {},
    subClass: {},
    subDivision: {}
  };

  public dotFormControlNames = {
    hazardClass: 'dot_hazard_class',
    hazardDivision: 'dot_hazard_division',
    hazardCompatibility: 'dot_hazard_compatibility',
    packingGroup: 'dot_hazard_packing_group',
    subHazardClass: 'dot_subhazard_class',
    subHazardDivision: 'dot_subhazard_division',
  };
  public iataFormControlNames = {
    hazardClass: 'iata_hazard_class',
    hazardDivision: 'iata_hazard_division',
    hazardCompatibility: 'iata_hazard_compatibility',
    packingGroup: 'iata_hazard_packing_group',
    subHazardClass: 'iata_subhazard_class',
    subHazardDivision: 'iata_subhazard_division',
  };
  public imdgFormControlNames = {
    hazardClass: 'imdg_hazard_class',
    hazardDivision: 'imdg_hazard_division',
    hazardCompatibility: 'imdg_hazard_compatibility',
    packingGroup: 'imdg_hazard_packing_group',
    subHazardClass: 'imdg_subhazard_class',
    subHazardDivision: 'imdg_subhazard_division',
  };

  private unCodes = [
    {id: "Not Regulated", name: "Not Regulated"},
    {id: "Could Not Find", name: "Could Not Find"}
  ];

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    public hazard: HazardService,
    private codesService: CodesService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.retrieveUnCodes();
    this.initForm();
    this.setDropDownConfigs();
  }

  retrieveUnCodes() {
    this.codesService.getTransportationCodes(codes => {
      this.unCodes = this.unCodes.concat(codes["shared"]["un_number"]);
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      un_dot_grp: this.formBuilder.group({
        un_dot: ['', [Validators.required]],
        dot_proper_shipping_name: ['', [Validators.required, this.noWhitespaceValidator]]
      }),
      un_iata_grp: this.formBuilder.group({
        un_iata: ['', [Validators.required]],
        iata_proper_shipping_name: ['', [Validators.required, this.noWhitespaceValidator]]
      }),
      un_imdg_grp: this.formBuilder.group({
        un_imdg: ['', [Validators.required]],
        imdg_proper_shipping_name: ['', [Validators.required, this.noWhitespaceValidator]]
      }),
      dot_grp: this.formBuilder.group({
        dot_hazard_class: ['', Validators.required],
        dot_subhazard_class: ['', Validators.required],
      }),
      iata_grp: this.formBuilder.group({
        iata_hazard_class: ['', Validators.required],
        iata_subhazard_class: ['', Validators.required],
      }),
      imdg_grp: this.formBuilder.group({
        imdg_hazard_class: ['', Validators.required],
        imdg_subhazard_class: ['', Validators.required],
      })
    });
  }

  setDropDownConfigs() {
    this.setConfig(this.dotDropDownConfigs, 'DOT');
    this.setConfig(this.imdgDropDownConfigs, 'IMDG');
    this.setConfig(this.iataDropDownConfigs, 'IATA');
  }

  setConfig(configObj, dropDownFor: string) {
    configObj.class = this.getClassConfig(dropDownFor);
    configObj.subClass = this.getSubClassConfig(dropDownFor);
    configObj.packingGroup = this.getPackingGroupConfig(dropDownFor);
  }

  public getClassConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelClass,
      dropdownOption: this.hazard.classes,
      placeHolder: this.hazard.placeHolder
    };
  }

  public getSubClassConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelSubClass,
      dropdownOption: this.hazard.subIshipClasses,
      placeHolder: this.hazard.placeHolder
    };
  }

  public getPackingGroupConfig(dropDownFor: string): any {
    return {
      label: dropDownFor + ' ' + DropdownLabels.labelPackingGroup,
      dropdownOption: this.hazard.packingGroup,
      placeHolder: this.hazard.placeHolder
    };
  }

  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  trimTrailingSpaces(name: string) {
    return (name && name.trim()) || null;
  }

  submit() {
    if (!this.form.valid || !this.confirmSubmit()) return;

    this.submitted = true;
    this.form.value.un_dot_grp.dot_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_dot_grp.dot_proper_shipping_name);
    this.form.value.un_iata_grp.iata_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_iata_grp.iata_proper_shipping_name);
    this.form.value.un_imdg_grp.imdg_proper_shipping_name = this.trimTrailingSpaces(this.form.value.un_imdg_grp.imdg_proper_shipping_name);
    const request = this.requestService.createIship(this.form);
    this.post(request);
  }

  ngOnDestroy() {
    if (this.dotSubscription) {
      this.dotSubscription.unsubscribe();
    }
    if (this.iataSubscription) {
      this.iataSubscription.unsubscribe();
    }
  }
}
