import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
​
@Injectable({
  providedIn: 'root'
})
export class RequestInterceptor implements HttpInterceptor {
  @BlockUI() blockUI: NgBlockUI;
  constructor(public toastr: ToastrService, private router: Router) {
    this.toastr.toastrConfig.positionClass = 'toast-top-full-width';
    this.toastr.toastrConfig.preventDuplicates = true;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.blockUI.start();
    return next.handle(request)
      .pipe(map ((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.blockUI.stop();
          return event;
        }
      }),
      catchError(err => {
        this.blockUI.stop();
        if (err.status === 401 || err.status === 403) {
          this.router.navigate(['login']);
          const parserErr = {
            message: !err.error || !err.error.errors || err.error.errors.length === 0 ?
            'Error occurred. Please try again...' : err.error.errors[0]
          };
          return throwError(parserErr);
        }
        if (err.status === 500) {
          return throwError(err);
        } else {
          const parserErr = {
            message: !err.error || !err.error.message ? 'Error occurred. Please try again...' : err.error.message
          };
          return throwError(parserErr);
        }
      }));
  }
}



