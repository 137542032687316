import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-universal',
  templateUrl: './universal.component.html',
  styleUrls: ['./universal.component.less']
})
export class UniversalComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  codes: {};

  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      universal: ['', Validators.required],
    });

    this.codeSubscription = this.expertService.getCodes('waste', 'universal').subscribe((value) => {
      this.codes = value || [];
    })

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('waste', 'universal').subscribe((value) => {
      this.expertService.disableReadOnlyForm(this.form);
      this.f.universal.setValue(value || null)
    })
  }

  onChange() {
    this.expertService.setCode(this.f.universal.value, 'waste', 'universal')
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
