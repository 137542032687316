import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { None, Indeterminable } from 'src/app/store/models/product.model'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-rcra',
  templateUrl: './rcra.component.html',
  styleUrls: ['./rcra.component.less']
})
export class RcraComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  codes: {};

  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      d_characteristic: ['', Validators.required],
      d_characteristic_toxics: ['', Validators.required],
      p: [null, Validators.required],
      u: [null, Validators.required],
      rcra_exceptions: [null, Validators.required]
    });

    this.codeSubscription = this.expertService.getCodes('waste', 'rcra').subscribe((value) => {
      this.codes = value || {};
    })

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('waste', 'rcra').subscribe((rcraValues) => {
      this.expertService.disableReadOnlyForm(this.form);
      rcraValues = rcraValues || {};
      Reflect.ownKeys(rcraValues).forEach(value => {
        this.f[value.toString()].setValue(rcraValues[value] || null);
      })
    })
  }

  onChange(type: string) {
    this.expertService.setCode(this.f[type].value, 'waste', 'rcra', type);

    if (['p', 'u'].includes(type) && ![None, Indeterminable].includes(this.f[type].value)) {
      let typeToUse = (type === 'u') ? 'p' : 'u';
      this.expertService.setCode(None, 'waste', 'rcra', typeToUse);
    }
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
