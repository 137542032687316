import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HazardService {
  constructor() { }

  // DropDown options for classes
  public classes = [
    { key: '1', value: 'Class 1 - Explosives' },
    { key: '2', value: 'Class 2 - Gasses' },
    { key: '3', value: 'Class 3 - Flammable Liquids (and Combustible liquids [U.S.])' },
    {
      key: '4',
      value:
        'Class 4 - Flammable Solids; Substances liable to spontaneous combustion; \
        Substances which, on contact with water, emit flammable gases'
    },
    { key: '5', value: 'Class 5 - Oxidizing substances and Organic peroxides' },
    { key: '6', value: 'Class 6 - Toxic substances and Infectious substances' },
    { key: '7', value: 'Class 7 - Radioactive materials' },
    { key: '8', value: 'Class 8 - Corrosive substances' },
    { key: '9', value: 'Class 9 - Miscellaneous dangerous goods/hazardous materials and articles' },
    { key: 'Not Restricted', value: 'Not Restricted' },
    { key: 'Could Not Find', value: 'Could not find' }
  ];

  public subClasses = [
    { key: '1', value: 'Class 1 - Explosives' },
    { key: '2', value: 'Class 2 - Gasses' },
    { key: '3', value: 'Class 3 - Flammable Liquids (and Combustible liquids [U.S.])' },
    {
      key: '4',
      value:
        'Class 4 - Flammable Solids; Substances liable to spontaneous combustion; \
        Substances which, on contact with water, emit flammable gases'
    },
    { key: '5', value: 'Class 5 - Oxidizing substances and Organic peroxides' },
    { key: '6', value: 'Class 6 - Toxic substances and Infectious substances' },
    { key: '7', value: 'Class 7 - Radioactive materials' },
    { key: '8', value: 'Class 8 - Corrosive substances' },
    { key: 'Not Restricted', value: 'Not Restricted' },
    { key: 'None', value: 'None' }
  ];

  public subIshipClasses = [
    { key: '2', value: 'Class 2 - Gasses' },
    { key: '3', value: 'Class 3 - Flammable Liquids (and Combustible liquids [U.S.])' },
    {
      key: '4',
      value:
        'Class 4 - Flammable Solids; Substances liable to spontaneous combustion; \
        Substances which, on contact with water, emit flammable gases'
    },
    { key: '5', value: 'Class 5 - Oxidizing substances and Organic peroxides' },
    { key: '6', value: 'Class 6 - Toxic substances and Infectious substances' },
    { key: '7', value: 'Class 7 - Radioactive materials' },
    { key: '8', value: 'Class 8 - Corrosive substances' },
    { key: 'Not Restricted', value: 'Not Restricted' },
  ];
  // Dropdown Options for divisions
  public explosivesDivision = [
    { key: '1.1', value: 'Division 1.1 - Explosives which have a mass explosion hazard' },
    {
      key: '1.2',
      value: 'Division 1.2 - Explosives which have a projection hazard but not a mass explosion hazard'
    },
    {
      key: '1.3',
      value:
        'Division 1.3 - Explosives which have a fire hazard and either a minor blast hazard or a minor projection hazard or both, \
        but not a mass explosion hazard'
    },
    { key: '1.4', value: 'Division 1.4 - Explosives which present no significant blast hazard' },
    { key: '1.5', value: 'Division 1.5 - Very insensitive explosives with a mass explosion hazard' },
    { key: '1.6', value: 'Division 1.6 - Extremely insensitive articles which do not have a mass explosion hazard' }
  ];

  public gassesDivision = [
    { key: '2.1', value: 'Division 2.1 - Flammable gases' },
    { key: '2.2', value: 'Division 2.2 - Non-flammable , non-toxic* gases' },
    { key: '2.3', value: 'Division 2.3 - Toxic* gases' }
  ];

  public couldNotFind = [
    { key: 'Could Not Find', value: 'Could not find' }
  ];

  public none = [
    { key: 'None', value: 'None' }
  ];
  public notRestricted = [
    { key: 'Not Restricted', value: 'Not Restricted' }
  ];

  public flammableSolidsDivision = [
    {
      key: '4.1',
      value: 'Division 4.1 - Flammable solids, self-reactive substances and solid desensitized explosives'
    },
    { key: '4.2', value: 'Division 4.2 - Substances liable to spontaneous combustion' },
    { key: '4.3', value: 'Division 4.3 - Substances which in contact with water emit flammable gases' }
  ];

  public oxidizingDivision = [
    { key: '5.1', value: 'Division 5.1 - Oxidizing substances' },
    { key: '5.2', value: 'Division 5.2 - Organic peroxides' }
  ];

  public toxicInfectiousDivision = [
    { key: '6.1', value: 'Division 6.1 - Toxic substances' },
    { key: '6.2', value: 'Division 6.2 - Infectious substances' }
  ];

  public compatibility = [
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'N', value: 'N' },
    { key: 'S', value: 'S' }
  ];

  public packingGroup = [
    { key: 'I', value: 'I' },
    { key: 'II', value: 'II' },
    { key: 'III', value: 'III' },
    { key: 'Could Not Find', value: 'Could not find' }
  ];
  // Place holder for select
  public placeHolder = 'Select Value';
  public couldNotFindText = 'Could Not Find';
  public notRestrictedText = 'Not Restricted';
  public notRegulatedText = 'Not Regulated';

}
