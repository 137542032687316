import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  templateUrl: './base.component.html'
})
export class BaseComponent {
  productUrl: any;
  id: number;
  task: any;
  form: FormGroup;
  submitted = false;
  window = null;
  successful: any;
  expertClassificationMode = false;
  @Input() themeVar: string;

  private confirmSubmitMessage = "Are you sure you want to submit?";

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    public activeRoute: ActivatedRoute
  ) {
    this.activeRoute.params.subscribe(routeParams => {
      if(!this.route.snapshot['_routerState'].url.match(/expert/))
        this.initialize();
      else
        this.expertClassificationMode = true;
    });
  }

  initialize() {
    let facet = this.route.snapshot.url[1].path;
    this.productUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    this.id = this.route.snapshot.params.id;

    this.taskService.get(this.id).subscribe(
      res => {
        this.task = res;
        if (!this.task.request.facets.includes(facet)) {
          this.toastr.error('Task facet incorrect with the view');
          this.router.navigate(['/private/task/list']);
        }
        if (this.task.request.input_data.sds_url) {
          this.productUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.task.request.input_data.sds_url);
          this.open();
        } else {
          this.close();
        }
      },
      err => {
        this.router.navigate(['/private/task/list']);
      }
    );
  }

  post(request) {
    this.close();
    request.completion_time = moment().format(); // for the case of timer
    this.taskService.post(this.id, request).subscribe(
      res => {
        this.toastr.success('Result saved');
        this.router.navigate(['/private/task/list']);
        this.successful = 'true';
      },
      err => {
        this.toastr.error(err.message);
        this.router.navigate(['/private/task/list']);
      }
    );
  }

  delete(request?, id?) {
    this.close();
    this.taskService.delete(id !== undefined ? id : this.id, request).subscribe(
      res => {
        this.toastr.success('Task Cancelled');
        this.router.navigate(['/private/task/list']);
      },
      err => {
        this.toastr.error(err.message);
        this.router.navigate(['/private/task/list']);
      }
    );
  }

  open() {
    const leftPos = screen.width;
    this.window = window.open(
      this.task.request.input_data.sds_url,
      'myWindow',
      `width=800,height=600, left=${leftPos}`
    );
  }

  close() {
    const leftPos = screen.width;
    this.window = window.open('/nocontent', 'myWindow', `width=800,height=600, left=${leftPos}`);
  }

  confirmSubmit() {
    return confirm(this.confirmSubmitMessage);
  }

  get f() {
    return this.form.controls;
  }
}
