import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import { PsnService } from 'src/app/shared/services/psn/psn.service'
import { CodeObject } from 'src/app/store/models/product.model'

@Component({
  selector: 'app-un-drop-down',
  templateUrl: './un-drop-down.component.html',
  styleUrls: ['./un-drop-down.component.less']
})
export class UnDropDownComponent implements Validators, OnChanges {
  @Input() label: String;
  @Input() codes: Array<CodeObject>;
  @Input() formGrp: FormGroup;

  constructor(private psnService: PsnService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["codes"]) return;

    // NOTE: filter creates a new copy of the un code array for each dropdown (about 130kb * 3 dropdowns).
    this.codes = this.codes.filter(code => {
      return this.isUnAllowed(code.id);
    });
  }

  isUnAllowed(unNumber: string) {
    // NA codes are only relevant to DOT
    const filterNaCodes = this.label !== "DOT";
    const naNumberRegex = /^NA\d{4}$/;
    if (filterNaCodes && naNumberRegex.test(unNumber))
        return false;

    const filteredCodes = ["not_regulated"];
    return !filteredCodes.includes(unNumber);
  }

  unNumberChanged(value: object) {
    if (value) {
      this.getUnControl().setValue(value);
      this.resetPsn(value);
    }
  }

  resetPsn(unCode: object) {
    const psn = this.psnService.getPsnFromUnCode(unCode);
    this.getPsnControl().setValue(psn);
  }

  getUnControl() {
    return this.formGrp.controls[this.getFormControlsArray()[0]];
  }

  getPsnControl() {
    return this.formGrp.controls[this.getFormControlsArray()[1]];
  }

  getFormControlsArray(): string[] {
    return Object.keys(this.formGrp.controls);
  }
}
