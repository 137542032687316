import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public retrieveAgentData(startDate: string, endDate: string, timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'agent_results_by_date', {
      params: {
        start_date: startDate,
        end_date: endDate,
        zone: timeZone
      }
    });
  }

  public retrieveAgentAccuracyData(startDate: string, endDate: string, timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'agent_accuracy_results_by_date', {
      params: {
        start_date: startDate,
        end_date: endDate,
        zone: timeZone
      }
    });
  }

  public retrieveAverageResultData(timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'average_results_summary', {
      params: {
        zone: timeZone
      }
    });
  }

  public retrieveAvgCompletionData(timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'average_completion_time_summary', {
      params: {
        zone: timeZone
      }
    });
  }

  public retrieveAccuracyData(timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'accuracy_results_summary', {
      params: {
        zone: timeZone
      }
    });
  }

  public getByDateAll(startDate: string, endDate: string, timeZone: string, facet = null) {
    const params = {
      start_date: startDate,
      end_date: endDate,
      zone: timeZone
    };
    if (facet) {
      Object.assign(params, { faceted: true });
    }
    return this.http.get<any>(this.baseUrl + 'supervisor_total_agent_report', { params });
  }

  public getByDateAccuracy(startDate: string, endDate: string, timeZone: string, facet = null) {
    const params = {
      start_date: startDate,
      end_date: endDate,
      zone: timeZone
    };
    if (facet) {
      Object.assign(params, { faceted: true });
    }
    return this.http.get<any>(this.baseUrl + 'supervisor_total_accuracy_agent_report', { params });
  }

  public getOverPeriodAll(startDate: string, endDate: string, timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'reports_over_period', {
      params: {
        start_date: startDate,
        end_date: endDate,
        zone: timeZone
      }
    });
  }

  public getFacetResult(startDate: string, endDate: string, facet: string, timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'agent_results_by_date', {
      params: {
        start_date: startDate,
        end_date: endDate,
        zone: timeZone,
        faceted: facet
      }
    });
  }

  public getFacetAccuracyResult(startDate: string, endDate: string, facet: string, timeZone: string) {
    return this.http.get<any>(this.baseUrl + 'agent_accuracy_results_by_date', {
      params: {
        start_date: startDate,
        end_date: endDate,
        zone: timeZone,
        faceted: facet
      }
    });
  }
}
