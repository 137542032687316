import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-revision-date',
  templateUrl: './revision-date.component.html',
  styleUrls: ['./revision-date.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RevisionDateComponent),
      multi: true
    }
  ]
})
export class RevisionDateComponent implements OnInit, ControlValueAccessor {
  @Input() submitted = false;
  @Input() revisionDateRequired = false;
  value = '';
  internalValue = '';
  isDisabled: boolean;
  check = false;
  onChange = (_: any) => {};
  onTouch = () => {};

  constructor() {}

  ngOnInit() {}

  onInput(value: string) {
    this.internalValue = moment(value).format('MM/DD/YYYY');
    this.onTouch();
    this.onChange(this.internalValue);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
      this.check = false;
      this.isDisabled = false;
      this.internalValue = '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  changeCheck() {
    this.value = '';
    if (this.check) {
      this.internalValue = 'Not Found';
      this.isDisabled = true;
      this.onChange(this.internalValue);
    } else {
      this.internalValue = '';
      this.isDisabled = false;
      this.onChange(this.internalValue);
    }
  }
}
