import { Component, OnInit , EventEmitter, Input, Output } from '@angular/core';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.less']
})

export class TreeComponent implements OnInit {
  selectedItemId = '';
  toChild = [];
  isDisabled = false;
  subscription: Subscription;

  windowPositions = {};
  windowWidth = 800;
  windowHeight = 600;

  constructor(private expertService: ExpertService) {
    this.windowPositions = {
      "sds_url": screen.width/2 - this.windowWidth,
      "un_38_3_url": screen.width/2
    };

    this.subscription = this.expertService.getTreeData$.subscribe(treeData => {
      if (treeData) {
        this.toChild = [];
        this.toChild.push(treeData);
        if (!this.selectedItemId && !this.expertService.getSelectedTreeItem) {
          this.openUrl(treeData, "sds_url");
          this.openUrl(treeData, "un_38_3_url");
          this.selectedItemId = treeData.id;
        } else {
          this.selectedItemId = this.expertService.getSelectedTreeItem;
        }
      }
    })

    this.subscription = this.expertService.disableInteraction$.subscribe(state => this.isDisabled = state);
  }

  ngOnInit() { }

  openUrl(treeItem: object, url_type: string) {
    const url = treeItem[url_type];
    const position = this.windowPositions[url_type] || 0;
    const options = `width=${this.windowWidth}, height=${this.windowHeight}, left=${position}`;
    window.open(url || '/nocontent', url_type, options);
  }

  handleItemSelection(treeItem) {
    this.expertService.changeProduct(treeItem.id);
    this.expertService.setSelectedTreeItem = treeItem.id;
    this.selectedItemId = this.expertService.getSelectedTreeItem;
    this.openUrl(treeItem, "sds_url");
    this.openUrl(treeItem, "un_38_3_url");
    this.expertService.sendSelectedTreeItem(treeItem);
  }

  ngOnDestroy() {
    // this.expertService.lastId = 0;
    this.subscription.unsubscribe();
  }
}
