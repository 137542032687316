// Service used by classification editor tasks to track changes made against the original result data
// NOTE: info on how deep-diff works here https://www.npmjs.com/package/deep-diff
import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import _ from "lodash";
import deep_diff from 'deep-diff';

@Injectable({ providedIn: "root" })
export class ClassificationEditorService {
  private static trackedFields = [
    "waste",
    "transportation",
    "other"
  ]

  static calculateOriginalDataDiffs(product: Product) {
    if (!product.is_classification_editor_task) return;

    product.original_data_diffs = {};

    const diffs = deep_diff(product, product.original_data, (path, key) => {
      if (!path.length && !this.trackedFields.includes(key)) return true;
    });

    if (!diffs) return;

    diffs.forEach(diff => {
      this.registerDiff(product, diff["path"]);
    });
  }

  static getTrackedFields() {
    return this.trackedFields;
  }

  private static registerDiff(product, path, markParentDiff = true) {
    // since deep-diff will only mark array / object children as diffs we need to manually mark the parents as diffs too
    if (markParentDiff && path.length > 1) {
      this.registerDiff(product, path.slice(0, path.length - 1), false);
      if (typeof(path[path.length-1]) === "number") return;
    }

    // join path into a string for flat data & quick lookups from expert.service
    const joinedPath = path.join(".");
    product.original_data_diffs[joinedPath] =  product.original_data_diffs[joinedPath]
                                               || { originalValue: _.get(product.original_data, path) };
  }
}
