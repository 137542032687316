import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ph',
  templateUrl: './ph.component.html',
  styleUrls: ['./ph.component.less'],
})
export class PhComponent extends BaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  showSingle = false;
  showRange = false;
  symbols = [];
  symbols_min = [];
  symbols_max = [];
  treeData = [];
  private valueChangesSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute,
    private expertService: ExpertService
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
    this.form = this.formBuilder.group({
      format: ['', Validators.required],
      symbol: [null],
      ph_value: [null],
      ph_min_value: [null],
      symbol_min: [null],
      ph_max_value: [null],
      symbol_max: [null],
    });

    if (this.expertClassificationMode) {
      this.expertService.disableReadOnlyForm(this.form, true);
      this.expertService.getExpertData$.subscribe((data: any) => {
        if (data) {
          this.treeData.push(data);
          this.loadInfo(data.pH);
        }
      });
    }
  }

  ngOnInit() {
    this.symbols = this.requestService.symbols;
    this.symbols_min = this.requestService.symbols_min;
    this.symbols_max = this.requestService.symbols_max;
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  changeFormat(resetData: boolean = true) {
    this.showSingle = false;
    this.showRange = false;
    this.resetValidatorsOrValue('setValidators');
    this.toastr.clear();
    if (resetData) {
      this.resetValidatorsOrValue('setValue');
    }
    switch (this.f.format.value) {
      case 'specific_value':
        this.showSingle = true;
        this.f.ph_value.setValidators([...this.phValueValidators]);
        this.f.symbol.setValidators(Validators.required);
        break;
      case 'specific_range':
        this.showRange = true;
        this.f.ph_min_value.setValidators([...this.phValueValidators, this.minValidate()]);
        this.f.symbol_min.setValidators(Validators.required);
        this.f.symbol_max.setValidators(Validators.required);
        this.f.ph_max_value.setValidators([...this.phValueValidators, this.minValidate()]);
        this.showToastOnError();
        break;
    }
    this.callUpdateValueAndValidity();
  }

  loadInfo(data) {
    this.f.format.setValue(data.format_input);
    this.changeFormat(false);
    switch (this.f.format.value) {
      case 'specific_value':
        this.showSingle = true;
        this.f.ph_value.setValue(data.ph_value);
        this.f.symbol.setValue(data.symbol);
        break;

      case 'specific_range':
        this.showRange = true;
        this.f.ph_min_value.setValue(data.ph_min_value);
        this.f.ph_max_value.setValue(data.ph_max_value);
        this.f.symbol_min.setValue(data.symbol_min);
        this.f.symbol_max.setValue(data.symbol_max);
        break;
    }
  }

  submit() {
    this.submitted = true;
    if (this.form.valid && this.confirmSubmit()) {
      const request = this.requestService.createPh(this.form);
      this.post(request);
    }
  }

  back() {
    this.expertService.exitComponent();
    this.router.navigate(['../transportation'], { relativeTo: this.route });
  }

  minValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const min_value = this.f.ph_min_value.value;
      const max_value = this.f.ph_max_value.value;

      if (typeof min_value !== 'number' || typeof max_value !== 'number') {
        return { minValidate: true };
      }

      if (min_value >= max_value) {
        return { minValidate: true };
      }
      this.toastr.clear();
      this.f.ph_min_value.setErrors(null);
      this.f.ph_max_value.setErrors(null);
      return null;
    };
  }

  get f() {
    return this.form.controls;
  }

  get phValueValidators() {
    return [Validators.required, Validators.min(0), Validators.max(14)];
  }

  private showToastOnError(): void {
    this.valueChangesSubscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (
        this.f.ph_max_value.value &&
        this.f.ph_min_value.value &&
        (this.f.ph_min_value.hasError('minValidate') || this.f.ph_max_value.hasError('minValidate'))
      ) {
        this.toastr.error('Min value must be less than max value');
      }
    });
  }

  /**
   * Calls callsUpdateValueAndValidity for each form field.
   */
  private callUpdateValueAndValidity(): void {
    this.f.ph_value.updateValueAndValidity();
    this.f.ph_min_value.updateValueAndValidity();
    this.f.ph_max_value.updateValueAndValidity();
    this.f.symbol_max.updateValueAndValidity();
    this.f.symbol_min.updateValueAndValidity();
    this.f.symbol.updateValueAndValidity();
  }

  /**
   * Sets form value or validator of form fields to null depending on input.
   *
   * @param valueOrValidator setValue or setValidator
   */
  private resetValidatorsOrValue(valueOrValidator: string) {
    this.f.ph_min_value[valueOrValidator](null);
    this.f.ph_max_value[valueOrValidator](null);
    this.f.ph_value[valueOrValidator](null);
    this.f.symbol_min[valueOrValidator](null);
    this.f.symbol_max[valueOrValidator](null);
    this.f.symbol[valueOrValidator](null);
  }
}
