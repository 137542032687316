import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  transportModes = ["core", "ground", "air", "sea"];
  errors = {};

  constructor(public toastr: ToastrService) {
    this.toastr.toastrConfig.autoDismiss = false;
    this.toastr.toastrConfig.disableTimeOut = true;
  }

  validate(product): boolean {
    this.errors = {};
    this.validateProduct(product);
    if (Object.keys(this.errors).length >= 1) {
      this.toastr.error(this.formatErrors(this.errors), `Please review Transportation & Waste required fields`,
                        { enableHtml: true });
      return false;
    }
    return true;
  }

  validateProduct(product) {
    // Don't validate read-only products
    if (!product.skip_expert_classification) {
      this.validateWaste(product.product_name, product.waste);
      this.validateTransportation(product.product_name, product.transportation);
    }
    (product.children || []).forEach(child => {
      this.validateProduct(child);
    });
  }

  validateWaste(productName, waste) {
    for (const key in waste) {
      if (!this.isValid(waste[key])) {
        this.addErrorWaste(productName, key);
      }
    }
  }

  validateTransportation(productName, transportation) {
    this.transportModes.forEach(transportMode => {
      for (const key in transportation[transportMode]) {
        if (!this.isValid(transportation[transportMode][key])) {
          this.addErrorTransportation(productName, transportMode, key);
        }
      }
    });
  }

  isValid(value) {
    if (value && value.constructor === Object) {
      for (const subKey in value) {
        if (!this.isValid(value[subKey])) {
          return false;
        }
      }
    }

    return typeof(value) === "number" || (value && Object.keys(value).length > 0);
  }

  addErrorTransportation(productName: string, transportMode: string, key: string) {
    this.errors[productName] = this.errors[productName] || {};
    this.errors[productName]["transportation"] = this.errors[productName]["transportation"] || {};
    this.errors[productName]["transportation"][transportMode] = this.errors[productName]["transportation"][transportMode] || [];
    this.errors[productName]["transportation"][transportMode].push(key);
  }

  addErrorWaste(productName: string, key: string) {
    this.errors[productName] = this.errors[productName] || {};
    this.errors[productName]["waste"] = this.errors[productName]["waste"] || [];
    this.errors[productName]["waste"].push(key);
  }

  formatErrors(errors) {
    let msg = "";

    for (const productName in errors) {
      msg += `<br><br>- ${productName} has invalid fields`;
      for (const errorType in errors[productName]) {
        const fieldErrors = errors[productName][errorType];
        if (errorType === "transportation") {
          for (const key in fieldErrors) {
            msg += `<br>${this.formatFieldErrors(`transportation (${key})`, fieldErrors[key])}`;
          }
        } else {
          msg += `<br>${this.formatFieldErrors(errorType, fieldErrors)}`;
        }
      }
    }

    return msg
  }

  formatFieldErrors(errorType: string, fieldErrors: string[]) {
    let errorLine = `Please review the following fields in ${errorType} - `;

    fieldErrors.forEach((key, index) => {
      if (index > 0) {
        errorLine += ",";
      }
      errorLine += ` ${this.formatKey(key)}`;
    });

    return errorLine;
  }

  // Remove underscores & capitalize words
  formatKey(key: string) {
    const words = key.split('_');
    for (let i=0; i<words.length; i++) {
      if (words[i].length === 2 || words[i].toLowerCase() === 'rcra') {
        words[i] = words[i].toUpperCase();
      } else {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
      }
    }

    return words.join(' ');
  }
}
