import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { TaskService } from 'src/app/shared/services/task/task.service';

@Component({
  selector: 'app-validate-sds-pairing',
  templateUrl: './validate-sds-pairing.component.html',
  styleUrls: ['./validate-sds-pairing.component.less']
})
export class ValidateSdsPairingComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  showPdf = false;
  showWhatIsneeded = false;
  showRevision = false;
  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      is_pairing_valid: ['', Validators.required],
      pdf_is: ['', Validators.required],
      quality: '',
      invalid_pairing_reason: '',
      comment: '',
      what_is_needed: '',
      revision_date: ['']
    });
  }

  change() {
    this.hideAll();
    if (this.f.is_pairing_valid.valid) {
      this.showWhatIsneeded = true;
      this.f.what_is_needed.setValidators(Validators.required);
      this.f.what_is_needed.updateValueAndValidity();
    }
    if (this.f.is_pairing_valid.value === 'no') {
      this.f.invalid_pairing_reason.setValidators(Validators.required);
      this.f.invalid_pairing_reason.updateValueAndValidity();
      if (this.f.invalid_pairing_reason.value === 'other') {
        this.f.comment.setValidators(Validators.required);
        this.f.comment.updateValueAndValidity();
      }
    } else if (this.f.is_pairing_valid.value === 'yes') {
      this.f.invalid_pairing_reason.setValue('');
      this.f.comment.setValidators(null);
      this.f.comment.updateValueAndValidity();
      this.f.quality.setValidators(Validators.required);
      this.f.quality.updateValueAndValidity();
    }
    if (this.f.pdf_is.value === 'SDS' || this.f.pdf_is.value === 'Technical Data Sheet') {
      this.showRevision = true;
      this.f.revision_date.setValidators(Validators.required);
      this.f.revision_date.updateValueAndValidity();
    }
  }

  hideAll() {
    this.showRevision = false;
    this.showWhatIsneeded = false;
    this.f.quality.setValidators(null);
    this.f.quality.updateValueAndValidity();
    this.f.invalid_pairing_reason.setValidators(null);
    this.f.invalid_pairing_reason.updateValueAndValidity();
    this.f.comment.setValidators(null);
    this.f.comment.updateValueAndValidity();
    this.f.what_is_needed.setValidators(null);
    this.f.what_is_needed.updateValueAndValidity();
    this.f.revision_date.setValue('');
    this.f.revision_date.setValidators(null);
    this.f.revision_date.updateValueAndValidity();
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.form.valid) {
      const request = this.requestService.createValidateSdsPairing(this.form);
      this.post(request);
    }
  }

  get f() {
    return this.form.controls;
  }
}
