import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TaskService } from 'src/app/shared/services/task/task.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { RequestService } from 'src/app/shared/services/request/request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tx',
  templateUrl: './tx.component.html',
  styleUrls: ['./tx.component.less']
})
export class TxComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  codes: {};

  private codeSubscription: Subscription;
  private selectedCodeSubscription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public taskService: TaskService,
    public router: Router,
    public sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private formBuilder: FormBuilder,
    private expertService: ExpertService,
    public requestService: RequestService,
    public activeRoute: ActivatedRoute
  ) {
    super(route, taskService, router, sanitizer, toastr, activeRoute);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      waste: ['waste', [Validators.required]],
      class: ['class', [Validators.required]]
    });

    this.codeSubscription = this.expertService.getCodes('waste', 'tx').subscribe((value) => {
      this.codes = value || {};
    })

    this.selectedCodeSubscription = this.expertService.getSelectedCodes('waste', 'tx').subscribe((txValues) => {
      this.expertService.disableReadOnlyForm(this.form);
      txValues = txValues || {};
      Reflect.ownKeys(txValues).forEach(value => {
        this.f[value.toString()].setValue(txValues[value] || null);
      })
    })
  }

  submit() {
    if (!this.confirmSubmit()) return;

    this.submitted = true;
    if (this.form.valid) {
      const request = {
        txformcode: this.f['waste'].value,
        class_code: this.f['class'].value,
      };
      this.post(request);
    }
  }

  onChange(type: string) {
    this.expertService.setCode(this.f[type].value, 'waste', 'tx', type);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.codeSubscription.unsubscribe();
    this.selectedCodeSubscription.unsubscribe();
  }
}
