import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn } from '@angular/forms';
import { ExpertService } from 'src/app/shared/services/expert/expert.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.less']
})
export class OtherComponent implements OnInit {
  form: FormGroup;

  codes: {};

  codeSubscriptions: { [field: string]: Subscription };
  selectedCodeSubscriptions: { [field: string]: Subscription };

  fields = ['fifra_25b', 'epa_registration_number', 'npk_ratio'];
  textEntryFields = ['epa_registration_number', 'npk_ratio'];

  constructor(
    private formBuilder: FormBuilder,
    private expertService: ExpertService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      fifra_25b: '',
      epa_registration_number: ['', this.regexValidator(/^\d{10}-\d{10}$/i)],
      npk_ratio: ['', this.regexValidator(/^\d{2}-\d{2}-\d{2}$/i)]
    });

    this.codes = {};
    this.codeSubscriptions = {};
    this.selectedCodeSubscriptions = {};

    this.fields.forEach(field => {
      this.codeSubscriptions[field] = this.expertService.getCodes('other', field).subscribe((value) => {
        this.codes[field] = value || [];
      });

      this.selectedCodeSubscriptions[field] = this.expertService.getSelectedCodes('other', field).subscribe((value) => {
        this.expertService.disableReadOnlyForm(this.form);

        if (value && this.textEntryFields.includes(field))
          value = value.id;

        if (typeof(value) !== "number" && (!value || !Object.keys(value).length))
          value = null;

        this.f[field].setValue(value);
      });
    });
  }

  regexValidator(allowedRegex: RegExp): ValidatorFn {
    return (control): {[key: string]: any} | null => {
      const allowed = !control.value || allowedRegex.test(control.value);
      return allowed ? null : { forbiddenName: { value: control.value }};
    };
  }

  onChange(field: string) {
    const value = this.f[field].value;
    this.expertService.setCode(value, "other", field);
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.fields.forEach(field => {
      this.codeSubscriptions[field].unsubscribe();
      this.selectedCodeSubscriptions[field].unsubscribe();
    });
  }
}
