import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { LoadCodes, LoadProduct, SetCode, ChangeProduct, ReplicateTransportationCore, SetAllWasteToNone } from './expert.actions';
import { ExpertStateModel } from './models/expert.model';
import { Product, DefaultProduct } from './models/product.model';
import { ExpertStateService } from './services/expert-state.service';
import { ClassificationEditorService } from './services/classification-editor.service';
import _ from "lodash";

@State<ExpertStateModel>({
  name: "expert",
  defaults: {
    task_id: null,
    codes: {
      transportation: {technical_name_whitelist: [], shared: {}, ground: {}, air: {}, sea: {}},
      waste: {},
      other: {}
    },
    current_product_id: "",
    product: DefaultProduct()
  }
})
@Injectable()
export class ExpertState {

  // ACTIONS

  // Store codes received from the backend as a format that dropdowns can use
  @Action(LoadCodes)
  @ImmutableContext()
  loadCodes(ctx: StateContext<ExpertStateModel>, { waste, transportation, other }: LoadCodes) {
    ctx.setState((state: ExpertStateModel) => {
      state.codes["waste"] = waste || state.codes["waste"];
      state.codes["transportation"] = transportation || state.codes["transportation"];
      state.codes["other"] = other || state.codes["other"];
      return state;
    })
  }

  @Action(LoadProduct)
  @ImmutableContext()
  loadProduct(ctx: StateContext<ExpertStateModel>, { task_id, product }: LoadProduct) {
    ctx.setState((state: ExpertStateModel) => {
      product = _.cloneDeep(product);
      ExpertStateService.normalizeIncomingProduct(state.codes, product);
      state.task_id = task_id;
      state.current_product_id = product.id;
      state.product = product;
      return state;
    })
  }

  @Action(SetCode)
  @ImmutableContext()
  setCode(ctx: StateContext<ExpertStateModel>, { value, path }: SetCode) {
    ctx.setState((state: ExpertStateModel) => {
      const product = ExpertStateService.findProductById(state.product, state.current_product_id);
      if (product) {
        _.set(product, path, ExpertStateService.getIdFromCodeObject(value));

        // NOTE: If selected code shares an ID with a code above it in the dropdown, then we store the code object in the
        // product's 'duplicate_code_objects' property (used for lookup in getSelectedCodes). It was simpler to
        // do this than to change the result format to keep track of 'name' alongside 'id' (we would need to
        // change each value to a hash, which we may want to do in the future).
        ExpertStateService.storeDuplicateCodeObject(state.codes, product, path, value);

        if (path.includes("transportation")) {
          const transportMode = ExpertStateService.getTransportModeFromPath(path);
          ExpertStateService.updateTransportation(state.codes, product, transportMode, path, value);
        }

        ClassificationEditorService.calculateOriginalDataDiffs(product);
      }
      return state;
    })
  }

  @Action(ChangeProduct)
  @ImmutableContext()
  changeProduct(ctx: StateContext<ExpertStateModel>, { id }: ChangeProduct) {
    ctx.setState((state: ExpertStateModel) => {
      state.current_product_id = id;
      return state;
    })
  }

  @Action(ReplicateTransportationCore)
  @ImmutableContext()
  replicateTransportationCore(ctx: StateContext<ExpertStateModel>) {
    ctx.setState((state: ExpertStateModel) => {
      const product = ExpertStateService.findProductById(state.product, state.current_product_id);
      if (product) {
        ExpertStateService.replicateTransportationCore(product.transportation);
        ClassificationEditorService.calculateOriginalDataDiffs(product);
      }
      return state;
    });
  }

  @Action(SetAllWasteToNone)
  @ImmutableContext()
  setAllWasteToNone(ctx: StateContext<ExpertStateModel>) {
    ctx.setState((state: ExpertStateModel) => {
      const product = ExpertStateService.findProductById(state.product, state.current_product_id);
      if (product) {
        ExpertStateService.setAllToNone(product.waste);
        ClassificationEditorService.calculateOriginalDataDiffs(product);
      }
      return state;
    })
  }

  // SELECTORS

  // Retrieve the currently selected product recursively based on current_product_id
  @Selector()
  static getSelectedProduct(state: ExpertStateModel) {
    return ExpertStateService.findProductById(state.product, state.current_product_id);
  }

  // Retrieve codes that were loaded from the backend with loadCodes
  @Selector()
  static getCodes(state: ExpertStateModel) {
    return (path: string[]) => {
      if (state.codes) {
        return _.get(state.codes, path);
      }
    };
  }

  // Retrieve the selected code(s) for a control (and convert to code object by retrieving display name / description)
  @Selector([ExpertState.getSelectedProduct])
  static getSelectedCodes(state: ExpertStateModel, product: Product) {
    return (path: string[]) => {
      if (!product) { return; }

      let selectedCodes = _.get(product, path);
      const isTextEntry = ExpertStateService.isTextEntry(path[path.length - 1]);

      if (selectedCodes && state.codes && !isTextEntry) {
        // Allow for unspecified code objects (ie: NotApplicable) to return successfully even if not selectable
        const selectedCode = ExpertStateService.getCodeObjectFromId(state.codes, path, selectedCodes)
                             || ExpertStateService.getNonCodeFromId(selectedCodes);
        return ExpertStateService.getDuplicateCodeObject(product, selectedCode, path);

      // Skip code lookups for text entries since they aren't defined in the backend
      } else if (isTextEntry) {
        if (Array.isArray(selectedCodes)) {
          let newCodes = [];
          selectedCodes.forEach(code => {
            if (code) { newCodes.push({id: code, name: code}); }
          });
          return newCodes;
        } else if (selectedCodes) {
          return {id: selectedCodes, name: selectedCodes};
        }
      }

      return selectedCodes;
    }
  }
}
