import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  list() {
    return this.httpClient.get(`${environment.apiUrl}users`);
  }

  changePassword(data, headersValues: Array<any>) {
    const headers = new HttpHeaders();
    headersValues.forEach((value, key) => {
      headers.set(key.toString(), value);
    });
    return this.httpClient.put(`${environment.apiUrl}auth/password`, data);
  }
}
